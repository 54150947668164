import React from 'react';
import List, {ListHeader} from "components/lists/List";
import {connect} from "react-redux";

import {uiChangedAction} from "actions/uiStateActions";

import {loadEvent} from "actions/eventsActions";
import {Link, Redirect} from "react-router-dom";
import EventDetail from "components/events/EventDetail";
import customHistory from "customHistory";
import Event from "entities/Event";
import Helmet from "react-helmet";
import eventsImage from "images/events.jpg";
import config from 'config';

class EventsDetailPage extends List {

    pageConfig = {
        title: 'Akce',
        color: config.colors.color4,
        bgImage: eventsImage
    };

    componentDidMount() {
        this.props.loadEvent(this.props.match.params.id);
        this.props.uiChangedAction(this.pageConfig);
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.event && this.props.history.location.search === '?vytisknout') {
            customHistory.replace(this.props.location.pathname);
            window.print();
        }
    }

    renderHelmet(event) {
        return (<Helmet>
            <meta name="description"
                  content={`${this.props.event.name} pro účastníky ve věku ${event.renderAge()}, termíny: ${event.renderTimeAsStrings().join(", ")}, ${event.renderBuildingsAsStrings().join(", ")}`} />
            <meta property='og:title' content={this.props.event.name} />
            <meta property='og:image' content={this.props.event.smallImageUrl} />
            <link rel='canonical' href={`${process.env.REACT_APP_BASE_URL}${event.getPermalink()}`} />
        </Helmet>);
    }

    render() {
        if (!this.props.event) {
            return null;
        }

        const event = new Event(this.props.event);
        const permalink = event.getPermalink();
        if (customHistory.location.pathname !== permalink) {
            return (<Redirect to={permalink} />);
        }

        return (
            <React.Fragment>
                {this.renderHelmet(event)}
                <section className="list">
                    <ListHeader withoutFilter />
                    <div className="list-body" id="list-body">
                        {this.props.event != null &&
                        <EventDetail event={this.props.event} sticky />
                        }
                    </div>
                    <footer className="list-footer">
                        <nav className="list-actions">
                            <ul>
                                <li>
                                    <Link to='/akce' className='button'>Zobrazit všechny akce</Link>
                                </li>
                            </ul>
                        </nav>
                    </footer>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({eventsStore}) => {
    return {
        event: eventsStore.event,
    }
};

export default connect(mapStateToProps, {uiChangedAction, loadEvent})(EventsDetailPage);