import React from 'react';
import {Link} from 'react-router-dom';
import list_placeholder from 'images/list-placeholder.png';
import Event from "entities/Event";
import ListItem from "components/lists/ListItem";

class EventItem extends ListItem {

    static  defaultProps = {
        pathPrefix: 'akce'
    };

    constructor(props, context) {
        super(props, context);

        this.state = {smallImageUrl: list_placeholder};

        //preload image
        this.preloadImage.src = this.props.event.smallImageUrl;
        this.preloadImage.addEventListener('load', this.setLoadedImage);
    }

    render() {
        const {
            name, eventId, price, open, ageFrom, ageTo
        } = this.props.event;
        const event = new Event(this.props.event);

        return (
            <article className={`list-item ${this.props.selected ? 'detail-opened':''}`} id={`item-${eventId}`}>
                <Link to={event.getPermalink(this.props.pathPrefix)} title={name} className="link" onClick={(e) => {
                    e.preventDefault();
                    this.onClickHandler(eventId, this.props.event)
                }}>
                    <div className="content bg-color">
                        <header>
                            <span className="supheadline">{event.renderTime()}</span>
                            <h3 className="headline">{name}</h3>
                        </header>
                        <div className="description">
                            {ageFrom > 0 && ageTo > 0 &&
                                <span title="Věková skupina" className="tooltip">{event.renderAge()}<br /></span>
                            }
                            {price.length > 0 &&
                                <span title="Cena" className="tooltip">{price}<br /></span>
                            }
                            <span>{open ? 'bez přihlášení' : 'pro přihlášené'}<br /></span>
                            <span title="Místo konání" className="tooltip">{event.renderBuildings()}</span>
                        </div>
                    </div>
                    <div className="image-wrap bg-color">
                        <img src={this.state.smallImageUrl} className="image" alt={name} />
                    </div>
                </Link>
            </article>
        );
    }
}

export default EventItem;