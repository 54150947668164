import React from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import {compose} from "redux";
import * as validations from 'helpers/forms/validations';
import {signup} from "actions/accountActions";
import {renderAuthField, renderCheckbox, renderHoneypot} from "helpers/forms/renderFormFields";
import accountAPI from "apis/accountAPI";
import {SetPasswordFields} from "helpers/forms/AccountFileds";
import {fetchInfoPage} from "actions/pagesActions";

class SignUpForm extends React.PureComponent {

    onSubmit = async (formProps) => {
        if (formProps.emailAgain) {
            //honeypot filled
            return;
        }
        const validateEmailResponse = await accountAPI.validateEmail(formProps.email);
        if (!validateEmailResponse.data) {
            throw new SubmissionError({
                email: 'Tento e-mail již obsazený. Použijte prosím jiný e-mail.',
                _error: 'Zadaný e-mail nejde použít.'
            });
        }
        this.props.signup(formProps);
        this.props.reset();
    };

    renderSuccessSubmit() {
        return (
            <React.Fragment>
                <h2>Hotovo!</h2>
                <p>
                    Děkujeme. Vaše registrace v proběhla v pořádku. Nyní je třeba účet aktivovat
                </p>
                <p>
                    <strong>Před přihlášením do účtu je třeba aktivovat účet kliknutím na odkaz zaslaný na Váš
                            e-mail.</strong>
                </p>
            </React.Fragment>
        );
    }


    render() {
        if (this.props.signupResult) {
            return this.renderSuccessSubmit();
        }

        const {handleSubmit, submitting, error} = this.props;
        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                    <fieldset className="col-sm-6 col-md-3">
                        <Field name="email" label="E-mail" type="email" autoComplete="none"
                               customInput={{maxLength: 80}}
                               component={renderAuthField}
                               validate={[validations.validateRequiredEmail, validations.validateEmail]}
                        />
                        <Field name="emailAgain" label="E-mail" type="email" autoComplete="none"
                               component={renderHoneypot}
                        />
                        <SetPasswordFields />
                        <Field name="newsletter" component={renderCheckbox} type="checkbox">
                            Přeji si dostávat měsíční nabídku DDM JM e-mailem<br />
                        </Field>
                        <p>
                            <Field name="agreement" component={renderCheckbox} type="checkbox"
                                   validate={validations.validateRequired}>
                                Souhlasím se zpracováním osobních údajů.<br />
                                <button className="link-decorated" type="button"
                                        onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_SIGN_UP_AGREEMENT)}>
                                    Více informací o způsobu a účelu zpracování
                                </button>
                            </Field>
                        </p>
                        <button type="submit" className="button" disabled={submitting || error}>Registrovat</button>
                        {error &&
                        <p className="error complement-color-0">{error}</p>
                        }
                    </fieldset>
                </div>
            </form>
        );
    }
}

const mapStateToProps = ({account}) => {
    return {
        errorMessage: account.authError,
        signupResult: account.signupResult
    }
};

export default compose(
    connect(mapStateToProps, {signup, fetchInfoPage}),
    reduxForm({form: 'signup'})
)(SignUpForm);
