import React from 'react';
import ReactDOM from 'react-dom';

//todo lze použít React.lazy?
export default (props) => {
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className="modal fade show" role="dialog" onClick={props.onBackdropClick}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="gallery-video"
                                 dangerouslySetInnerHTML={{__html: props.description.replace(/<\/?p>/gi, '')}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" />
        </React.Fragment>
        ,
        document.querySelector("#modal")
    );
}