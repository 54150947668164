import React from 'react';

class DatePicker extends React.PureComponent {


    constructor(props) {
        super(props);
        this.state = {
            day: '',
            month: '',
            year: ''

        }
    }

    renderDays() {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            days.push(<option value={i} key={`day-${i}`}>{i}</option>);
        }
        return (
            <select name="day" onChange={event => this.setState({day: event.target.value})} value={this.state.day}>
                <option value="">den</option>
                {days}
            </select>
        )
    }

    renderMonths() {
        let moths = [];
        for (let i = 1; i <= 12; i++) {
            moths.push(<option value={i} key={`month-${i}`}>{i}</option>);
        }
        return (
            <select name="month" onChange={event => this.setState({month: event.target.value})}
                    value={this.state.month}>
                <option value="">měsíc</option>
                {moths}
            </select>
        )
    }

    renderYears() {
        let years = [];
        for (let i = new Date().getFullYear(); i > 1900; i--) {    
	    years.push(<option value={i} key={`year-${i}`}>{i}</option>);
        }
        return (
            <select name="year" onChange={event => this.setState({year: event.target.value})} value={this.state.year}>
                <option value="">rok</option>
                {years}
            </select>
        )
    }

    //todo restriction
    componentDidUpdate() {
        if (!this.state.day) {
            const [day, month, year] = this.props.value.split("/");
            if (day && month && year) {
                this.setState({day, month, year});
            }
        } else {
            const {day, month, year} = this.state;
            if (day != null && month != null && year != null) {
                const value = `${day}/${month}/${year}`;
                this.props.onChange(value);
            }
        }
    }

    render() {
        return (
            <div className="datePicker">
                {this.renderDays()}
                {this.renderMonths()}
                {this.renderYears()}
            </div>
        );
    }
}

export default DatePicker;
