import React from 'react';

class ListItem extends React.PureComponent {

    preloadImage = new Image();

    //when image is loaded, replace placeholder with it
    //preloading starts in costructor
    setLoadedImage = () => {
        this.setState({smallImageUrl: this.preloadImage.src});
    };

    componentWillUnmount() {
        //clean up
        this.preloadImage.removeEventListener('load', this.setLoadedImage);
    }

    onClickHandler = (itemId, item) => {
        //notify parent (list): this item was selected
        this.props.onItemSelect(item, this.props.itemIndex,itemId);
    };

    render() {
        return null;
    }
}

export default ListItem;