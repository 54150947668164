import {compose} from "redux";
import {connect} from "react-redux";
import {accountUpdate} from "actions/accountActions";
import {Field, reduxForm} from "redux-form";
import {SetPasswordFields} from "helpers/forms/AccountFileds";
import React from "react";
import {renderCheckbox} from "helpers/forms/renderFormFields";

const mapStateToProps = ({account}) => {
    return {
        user: account.user,
        accountEditResult: account.accountEditResult,
        initialValues: {...account.user}
    }
};

const AccountForm = compose(connect(mapStateToProps, {accountUpdate}), reduxForm({form: 'account'}))((props) => {
    const {handleSubmit, submitting, user, error, accountUpdate, accountEditResult} = props;
    const onSubmit = (formProps) => {
        accountUpdate(formProps);
    };

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <label>Email</label>
                <span className="input">{user.email}<br />
                    <small>E-mailová adresa slouží jako přihlašovací jméno a nelze změnit.</small>
                </span>
                <SetPasswordFields />
                <Field name="newsletter" component={renderCheckbox} type="checkbox">
                    Přeji si dostávat měsíční nabídku DDM JM e-mailem<br />
                </Field>
            </fieldset>
            <button type="submit" className="button" disabled={submitting || error}>Uložit</button>
            {accountEditResult &&
            <p className="complement-color-0">Uloženo</p>
            }
        </form>
    );
});

export default AccountForm;