import React from 'react';
import {Link} from 'react-router-dom';
import list_placeholder from 'images/list-placeholder.png';
import ListItem from "components/lists/ListItem";
import Program from 'entities/Program';

class ProgramItem extends ListItem {


    constructor(props, context) {
        super(props, context);

        this.state = {smallImageUrl: list_placeholder};

        //preload image
        this.preloadImage.src = this.props.program.smallImageUrl;
        this.preloadImage.addEventListener('load', this.setLoadedImage);
    }

    render() {
        const {
            name, programId, grade, specialization
        } = this.props.program;
        const program = new Program(this.props.program);

        return (
            <article className={`list-item ${this.props.selected ? 'detail-opened':''}`} id={`item-${programId}`}>
                <Link to={program.getPermalink()} title={name} className="link" onClick={(e) => {
                    e.preventDefault();
                    this.onClickHandler(programId, this.props.program)
                }}>
                    <div className="content bg-color">
                        <header>
                            {grade &&
                                <span className="supheadline">{`${grade}. třída`}</span>
                            }
                            <h3 className="headline">{name}</h3>
                        </header>
                        <div className="description">
                            {specialization}
                        </div>
                    </div>
                    <div className="image-wrap bg-color">
                        <img src={this.state.smallImageUrl} className="image" alt={name} />
                    </div>
                </Link>
            </article>
        );
    }
}

export default ProgramItem;