import React from 'react';
import {Link} from "react-router-dom";
import list_placeholder from "images/list-placeholder.png";
import ListItem from "components/lists/ListItem";

class PostItem extends ListItem {

    preloadImage = new Image();

    constructor(props, context) {
        super(props, context);

        this.state = {smallImageUrl: list_placeholder};

        //preload image
        const featuredImage = this.props.post.featuredImage;
        if(featuredImage) {
            this.preloadImage.src = featuredImage.thumbnail;
            this.preloadImage.addEventListener('load', this.setLoadedImage);
        }
    }

    render() {
        const {id, title, excerpt, supheadline, color } = this.props.post;
        const className = ['list-item'];

        if(this.props.selected) className.push('detail-opened');

        let bgColor = 'bg-color';
        if(color.className && color.className.length > 0) {
            bgColor = `bg-${color.className}`;
            className.push(bgColor);
        }

        return (
            <article className={className.join(' ')} id={`item-${id}`}>
                <WrappingLink post={this.props.post} onClickHandler={this.onClickHandler}>
                    <div className={`content ${bgColor}`}>
                        <header>
                            {supheadline &&
                            <span className="supheadline">{supheadline}</span>
                            }
                            <h3 className="headline">{title}</h3>
                        </header>
                        <div className="description">{excerpt}</div>
                    </div>
                    <div className={`image-wrap ${bgColor}`}>
                        <img src={this.state.smallImageUrl} className="image" alt={title} />
                    </div>
                </WrappingLink>
            </article>
        );
    }
}

const WrappingLink = (props) => {
    const {post, children, onClickHandler} = props;
    const {id, externalLink, content, path} = post;
    const internalLink = content.length > 0 ? path : null;

    if (externalLink) {
        return <a href={externalLink} target='_blank' rel='noreferrer noopener' className="link">
            {children}</a>
    }

    if (internalLink) {
        return <Link to={internalLink} className="link" onClick={(e) => {
            e.preventDefault();
            onClickHandler(id, props.post)
        }}>
            {children}</Link>
    }

    return <React.Fragment>{children}</React.Fragment>


};

export default PostItem;
