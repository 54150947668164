import React from "react";
import {uniqBy} from "lodash";
import ServiceForKids from "entities/ServiceForKids";
import config from 'config';

class Event extends ServiceForKids {

    event = {performances: []};

    constructor(event) {
        super(event,event.eventId);
        this.event = event;
    }

    renderTime() {
        return this.event.performances.map(({performanceId, dateFrom, dateTo, timeFrom, timeTo}) => {
                const from = new Date(dateFrom);
                const to = new Date(dateTo);
                return (
                    <React.Fragment key={performanceId}>
                        <span>
                            {dateFrom === dateTo &&
                            `${config.l10n.dayNames[from.getDay()]} ${from.toLocaleDateString()}`
                            }
                            {dateFrom !== dateTo &&
                            `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
                            }
                            &nbsp;
                        </span>
                            {timeFrom.length > 0 ? `${timeFrom}${timeTo.length > 0 ? ` - ${timeTo}` : ''} hodin` : ''}<br />
                    </React.Fragment>
                )
            }
        );
    }

    renderTimeAsStrings(){
        return this.event.performances.map(({dateFrom, dateTo, timeFrom, timeTo}) => {
                const from = new Date(dateFrom);
                const to = new Date(dateTo);
                const time = timeFrom.length > 0 ? `v čase ${timeFrom}${timeTo.length > 0 ? ` - ${timeTo}` : ''} hodin` : '';

                if(dateFrom === dateTo){
                    return `${config.l10n.dayNames[from.getDay()]} ${from.toLocaleDateString()} ${time}`;
                }
                else if(dateFrom !== dateTo){
                    return `${from.toLocaleDateString()} - ${to.toLocaleDateString()} ${time}`;
                }
                else return null
            }
        );
    }

    renderBuildings() {
        const all = this.event.performances.map(({buildingName, otherPlace}) => buildingName ? buildingName : otherPlace);

        return uniqBy(all).map((buildingName) => {
            return (
                <React.Fragment key={buildingName}>
                    {buildingName}<br />
                </React.Fragment>
            );
        });
    }

    renderBuildingsAsStrings() {
        const all = this.event.performances.map(({buildingName, otherPlace}) => buildingName ? buildingName : otherPlace);
        return uniqBy(all);
    }

    getPermalink(prefix = 'akce') {
        return super.getPermalink(prefix);
    }
}

export default Event;
