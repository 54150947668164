import React from 'react';
import {connect} from 'react-redux';

import {uiChangedAction} from "actions/uiStateActions";
import Carousel from 'components/Carousel';
import config from "config";
import {fetchActivities, fetchBlog, fetchNews} from 'actions/postsActions';
import PostItem from "components/posts/PostItem";
import customHistory from 'customHistory';

class Homepage extends React.Component {

    componentDidMount() {
        this.props.uiChangedAction({title: config.defaultTitle, color: config.defaultColor});
        this.props.fetchNews(config.pagination.homepage);
        this.props.fetchBlog(config.pagination.homepage);
        this.props.fetchActivities(config.pagination.homepage);
    }

    onItemSelect = (item, path) => {
        customHistory.push(`/${path}/${item.slug}`);
    };

    renderCarouselItem = (post,index,path) =>{
        post.path = `/${path}/${post.slug}`;
        return <PostItem key={post.id} post={post} itemIndex={index} onItemSelect={(item) => this.onItemSelect(item, path)} />;
    };

    render() {
        return (
            <React.Fragment>
                <Carousel headline="Aktuálně">
                    {this.props.news.map(this.renderCarouselItem)}
                </Carousel>
                {this.props.activities.length > 0 &&
                <Carousel headline="Z nabídky" link='/nabidka'>
                    {this.props.activities.map((post, index) => this.renderCarouselItem(post,index, 'nabidka'))}
                </Carousel>
                }
                {this.props.blog.length > 0 &&
                    <Carousel headline="Ze života DDM JM" link='/ze-zivota-ddm'>
                        {this.props.blog.map((post,index) => this.renderCarouselItem(post,index, 'ze-zivota-ddm'))}
                    </Carousel>
                }
            </React.Fragment>
        );
    }
};

const mapStateToProps = ({homepage: {news,blog, activities}}) => {
  return {
      news: news.slice(0,config.pagination.homepage),
      blog: blog.slice(0,config.pagination.homepage),
      activities: activities.slice(0,config.pagination.homepage)
  }
};

export default connect(mapStateToProps,{uiChangedAction, fetchNews, fetchBlog, fetchActivities})(Homepage);
