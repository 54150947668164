import axios from 'axios';

const wpapi = axios.create({
    baseURL: process.env.REACT_APP_WP_API_URL
});

export default {

    fetchPosts(args) {
        return wpapi.get('/posts', {params: args}).catch((response) => console.error(response, "WP API ERROR: /posts"))
    },

    fetchPages(args) {
        return wpapi.get('/pages', {
            params: {
                orderby: 'menu_order',
                order: 'asc',
                ...args
            }
        })
            .catch((response) => console.error(response, "WP API ERROR: /pages"))
    },

    fetchPage(id,args) {
        return wpapi.get(`/pages/${id}`, {params: args}).catch((response) => console.error(response, "WP API ERROR: /page/:id"))
    },

}