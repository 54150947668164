import React from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

import {uiChangedAction} from "actions/uiStateActions";
import config from 'config';
import notFoundImage from 'images/404.jpg';

export default connect(null, {uiChangedAction})((props) => {

    props.uiChangedAction({
        ...config.notFound,
        bgImage: notFoundImage
    });

    return (
        <section className="not-found">
            <div className="list-body" id="list-body">
                <div className="" id="list-detail">
                    <article className={`bg-color7 list-detail text-center`}>
                        <h2 className="title">{config.notFound.title}</h2>
                        <div>
                            <p><strong>Je nám to líto, ale stránka kterou hledáte neexistuje.</strong></p>

                            <h3>Vypadá to, že:</h3>
                            <ul>
                                <li>jste špatně napsali adresu</li>
                                <li>snažíte se přistoupit na již smazanou stránku</li>
                            </ul>
                            <p>
                                <Link to='/' className='button'>Pokračujte prosím na naší úvodní
                                                                stránku</Link>
                            </p>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
})