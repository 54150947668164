import React from 'react';
import ReactDOM from 'react-dom';
import {closeAlert} from 'actions/uiStateActions';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

const mapStateToProps = ({UIState: {sessionExpired}}) => {
    return {sessionExpired}
};

export default connect(mapStateToProps, {closeAlert})(({sessionExpired,closeAlert}) => {
    if (!sessionExpired) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="notification">
            <p>
            Vaše přihlášení vypršelo.<br />
                <Link to='/ucet/prihlaseni' onClick={closeAlert}>Znovu přihlásit</Link>
            </p>
            <button title="Zavřít" className="float-right link" onClick={closeAlert}>
                <i className="fas fa-times" />
            </button>
        </div>,
        document.querySelector('#alert')
    );
});