import React from 'react';
import {connect} from 'react-redux';

export default ChildComponent => {

    const ComposeComponent = (props) => {
        const {forwardedRef, ...rest} = props;
        return (
            <ChildComponent ref={forwardedRef} {...rest} />
        );
    };

    const ForwardedComponent =  React.forwardRef((props, ref) => {
        return <ComposeComponent {...props} forwardedRef={ref} />;
    });

    const mapStateToProps = ({account}) => {
        return {
            user: account.user,
            authenticated: account.authenticated
        }
    };
    return connect(mapStateToProps, null, null, {forwardRef: true})(ForwardedComponent);
}