import config from 'config';
import ddmbase, {convertFilterKeyNames,initAges} from "apis/ddmbase";

export default{

    initAges() {
        return initAges(1);
    },

    initMonths() {
        const months = [];
        let month;
        for (let i = 0; i <= 11; i++) {
            month = (i + 8) % 12;
            months.push({id: month, value: config.l10n.monthsNames[month]});
        }
        return months;
    },

    initOpen(){
        return [
            {id: 1, value: 'pro volně příchozí'},
            {id: 0, value: 'pro přihlášené'},
        ];
    },

    getEvents(params,filter = null){
        const filledFilter = convertFilterKeyNames(filter);
        return ddmbase.get('/events', {
            params: {...params, ...filledFilter}
        }).catch((response) => console.error(response, "API ERROR: /events"))
    },

    getEvent(eventId) {
        return ddmbase.get(`/events/${eventId}`).catch((response) => console.error(response, "API ERROR: /events/:id"))
    }
}