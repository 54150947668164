import React from 'react';
import List, {ListHeader, ListFooter, EmptyList} from "components/lists/List";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import {uiChangedAction} from "actions/uiStateActions";
import {loadCourses, loadMore, filterClear} from "actions/coursesActions";

import config from 'config';
import CourseDetail from "components/courses/CourseDetail";
import CourseItem from "components/courses/CourseItem";

import coursesImage from 'images/courses.jpg';
import {fetchInfoPage} from "actions/pagesActions";

class CoursesList extends List {

    pageConfig = {
        title: 'Kroužky',
        color: config.colors.color0,
        bgImage: coursesImage
    };

    state = {
        selectedItem: null,
        selectedIndex: null,
        modalOpen: false
    };

    componentDidMount() {
        this.props.loadCourses();
        this.props.uiChangedAction(this.pageConfig);
    }

    componentDidUpdate(prevProps){
        if(this.state.modalOpen) return;
        super.componentDidUpdate(prevProps);
    }

    renderCourses() {
        return this.props.courses.map((course,index) => {
            return <CourseItem key={course.courseId} course={course} itemIndex={index}
                                selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemSelect} />;
        })
    }

    toggleModalOpen = (modalOpen) => {
        this.setState({modalOpen});
    };

    render() {
        return (
            <React.Fragment>
                <section className="list">
                    <ListHeader filterActive={this.props.filterActive}>
                        <li>
                            <button className="button"
                                    onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_COURSES_CONDITIONS)}>
                                Všeobecné podmínky
                            </button>
                        </li>
                        <li>
                            <button className="button"
                                    onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_COURSES_HOW_TO)}>
                                Jak se přihlásit?
                            </button>
                        </li>
                    </ListHeader>
                    <div className="list-body" id="list-body">
                        {this.props.courses.length > 0 && this.renderCourses()}
                        {this.state.selectedItem != null &&
                            <CourseDetail course={this.state.selectedItem} changePosition={this.positionDetail}
                                          toggleModalOpen={this.toggleModalOpen}
                                        onItemClosed={this.onClosed} />
                        }
                        {/*no results*/}
                        {this.props.courses.length === 0 && this.props.filterActive &&
                            <EmptyList filterClear={this.props.filterClear}>
                                Je nám líto, ale tyto požadavky nesplňuje žádný kroužek
                            </EmptyList>
                        }
                    </div>
                    {this.props.courses.length > 0 &&
                    <ListFooter canLoadMore={this.props.canLoadMore} onClickHandler={this.props.loadMore}
                                itemsPerPage={config.pagination.courses} />
                    }
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {coursesStore} = state;
    return {
        courses: coursesStore.courses,
        //already loaded courses are multiplication of config.pagination.courses unless last request returned less items
        canLoadMore: coursesStore.courses.length % config.pagination.courses === 0 && !coursesStore.noMoreResults,
        filterActive: Object.values(coursesStore.selectedValues).some((item) => !isEmpty(item)),
        isFetching: state.isFetching !== 0

    }
};

export default connect(mapStateToProps,{uiChangedAction, loadCourses, loadMore, filterClear, fetchInfoPage})(CoursesList);