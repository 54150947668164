import React from 'react';
import {connect} from "react-redux";

import List from "components/lists/List";

import {uiChangedAction} from "actions/uiStateActions";
import {loadPrograms} from "actions/programsActions";

import ProgramItem from "components/programs/ProgramItem";
import ProgramDetail from "components/programs/ProgramDetail";
import config from 'config';
import competitionsImage from 'images/competitions.jpg';

class ProgramsList extends List {

    pageConfig = {
        title: 'Programy',
        color: config.colors.color5,
        bgImage: competitionsImage
    };

    state = {
        selectedItem: null,
        selectedIndex: null,
    };

    componentDidMount() {
        this.props.loadPrograms();
        this.props.uiChangedAction(this.pageConfig);
    }

    renderPrograms() {
        return this.props.programs.map((program,index) => {
            return <ProgramItem key={program.programId} program={program} itemIndex={index}
                                selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemSelect} />;
        })
    }

    render() {
        const listLength = this.props.programs.length;
        return (
            <React.Fragment>
                <section className="list">
                    <div className="list-body" id="list-body">
                        {listLength > 0 && this.renderPrograms()}
                        {this.state.selectedItem != null &&
                            <ProgramDetail program={this.state.selectedItem} changePosition={this.positionDetail}
                                        onItemClosed={this.onClosed} />
                        }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({programsStore}) => {
    return {
        programs: programsStore.programs,
    }
};

export default connect(mapStateToProps,{uiChangedAction, loadPrograms})(ProgramsList);