import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {resetPassword} from 'actions/accountActions';
import {compose} from 'redux';
import {renderAuthField} from "helpers/forms/renderFormFields.js";
import * as validations from "helpers/forms/validations";

class PasswordResetForm extends React.PureComponent {
    onSubmit = (formProps) => {
        this.props.resetPassword(formProps);
    };

    render() {
        const {handleSubmit, submitting, passwordReseted} = this.props;
        return (
            <React.Fragment>
                <div className="form">
                    {!passwordReseted &&
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <fieldset>
                            <p className="">
                                Zadejte e-mail, pod kterým jste se registrovali.<br />
                                Na tento e-mail Vám pošleme odkaz pro nastavení nového hesla.
                            </p>
                            <Field name="username" label="E-mail" type="email" autoComplete="none"
                                   component={renderAuthField}
                                   validate={[validations.validateRequiredEmail, validations.validateEmail]}
                            />
                            <button type="submit" disabled={submitting} className="button">Obnovit heslo</button>
                        </fieldset>
                    </form>
                    }
                    {passwordReseted &&
                    <React.Fragment>
                        <h3 className="bold-uppercased">Odesláno</h3>
                        <div className="success">
                            <p>E-mail dorazí během několika minut.</p>
                            <p><strong>Nezapomeňte se podívat do složky nevyžádané pošty / spamu.</strong></p>
                            <p>Pokud žádný e-mail neobdržíte, zřejmě jste zadali adresu pod kterou neevidujeme žádný
                               účet.</p>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
};

const mapsStateToProps = ({account}) => {
    return {
        passwordReseted: account.passwordReseted
    }
};

export default compose(
    connect(mapsStateToProps, {resetPassword}),
    reduxForm({form: 'resetpsw'})
)(PasswordResetForm);