import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {compose} from "redux";
import {clearAuthErrors, signin} from 'actions/accountActions';
import * as validations from 'helpers/forms/validations';
import {renderAuthField} from 'helpers/forms/renderFormFields.js';
import {Link} from "react-router-dom";

class SignInForm extends React.PureComponent {

    componentDidMount() {
        this.props.clearAuthErrors();
    }

    onSubmit = (formProps) => {
        this.props.signin(formProps);
    };

    renderErrorMessage() {
        if (!this.props.errorMessage) {
            return null;
        }

        let message = "";
        switch (this.props.errorMessage) {
            case 'account-locked':
                message = "Váš účet byl uzamčen. Obraťte se na nás e-mailem nebo telefonicky, pokud si přejete účet odemknout";
                break;
            case 'account-disabled':
                message = "Účet nebyl aktivován. " +
                    "Aktivaci účtu je třeba provést kliknutím na odkaz zaslaný na Váš e-mail během registrace. " +
                    "Pokud jste žádný e-mail neobdrželi nebo už neplatí, nechte si zaslat nový.";
                break;
            case 'bad-credentials':
                message = "Špatné uživatelské jméno nebo heslo";
                break;
            default:
                message = "Nepodařilo se přihlásit. Zkuste to prosím později.";
                break;
        }
        return (
            <React.Fragment>
                <p className="error complement-color-0">{message}</p>
                {this.props.errorMessage === 'account-disabled' &&
                <button type="button" className="button" onClick={() => this.props.openForm('resendlink')}>
                    Znovu aktivovat</button>
                }
            </React.Fragment>
        );
    }

    buttonHandler = (event, openFormKey) => {
        const {openForm} = this.props;
        if (openForm) {
            event.preventDefault();
            openForm(openFormKey)
        }
    };

    render() {
        const {handleSubmit, submitting} = this.props;
        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <fieldset>
                    <Field name="username" label="E-mail" type="email" autoComplete="none"
                           component={renderAuthField}
                           validate={[validations.validateRequiredEmail, validations.validateEmail]}
                    />
                    <Field name="password" label="Heslo" type="password" autoComplete="none"
                           component={renderAuthField}
                           validate={[validations.validateRequiredPassword, validations.validatePasswordLength]}
                    />
                    {this.renderErrorMessage()}
                    <button type="submit" className="button" disabled={submitting}>Přihlásit se</button>
                </fieldset>
                <Link to='/ucet/obnova-hesla' onClick={(e) => this.buttonHandler(e,'resetpsw')}>Obnovit heslo</Link>
                <br />
                <Link to='/ucet/registrace' onClick={() => {
                    if(this.props.closeMenu) {
                        this.props.closeMenu();
                    }
                }}>Vytvořit účet</Link>
            </form>
        );
    }
}

const mapStateToProps = ({account}) => {
    return {
        errorMessage: account.authError
    }
};

export default compose(
    connect(mapStateToProps, {signin, clearAuthErrors}),
    reduxForm({form: 'signin'})
)(SignInForm);
