//Action creators for events

import config from "config";
import eventsAPI from "apis/eventsAPI";
import customHistory from "customHistory";


export const loadEvents = () => async (dispatch, getState) => {
    dispatch({type: 'LOAD_EVENTS_REQUEST'});

    const offset = 0;
    const limit = config.pagination.camps;
    const response = await eventsAPI.getEvents({offset, limit}, getState().eventsStore.selectedValues);
    dispatch({
        type: 'EVENTS_LOAD',
        payload: response.data
    });
};

export const loadMore = () => async (dispatch, getState) => {
    dispatch({type: 'LOAD_MORE_EVENT_REQUEST'});

    const limit = config.pagination.camps;
    const offset = Math.max(getState().eventsStore.loadOffset, limit);
    const response = await eventsAPI.getEvents({offset, limit}, getState().eventsStore.selectedValues);
    dispatch({
        type: 'EVENTS_LOAD_MORE',
        payload: response.data
    });
};

export const loadEvent = (eventId) => async dispatch => {
    dispatch({type: 'LOAD_EVENT_REQUEST'});
    const response = await eventsAPI.getEvent(eventId);
    if (response.data.length === 0) {
        customHistory.replace("/404");
    }
    dispatch({
        type: 'UI_TITLE_CHANGED',
        payload: response.data.name
    });
    dispatch({
        type: 'EVENT_LOAD',
        payload: response.data
    });
};

//todo tohle je asi špatně. Nebudu moci garantovat, že se akce vykonají ve správném pořadí
export const filterSelectItem = (key, value) => dispatch => {
    dispatch({
        type: 'EVENTS_FILTER_SELECT',
        payload: {key, value}
    });
    dispatch(loadEvents());
};

export const filterUnselectItem = (key, value) => dispatch => {
    dispatch({
        type: 'EVENTS_FILTER_UNSELECT',
        payload: {key, value}
    });
    dispatch(loadEvents());
};

export const filterClear = () => dispatch => {
    dispatch({
        type: 'EVENTS_FILTER_CLEAR',
    });

    dispatch(loadEvents());
};

export const initAges = () => {
    return {
        type: 'EVENTS_INIT_AGES',
        payload: eventsAPI.initAges()
    }
};

export const initMonths = () => {
    return {
        type: 'EVENTS_INIT_MONTHS',
        payload: eventsAPI.initMonths()
    }
};

export const initOpen = () => {
    return {
        type: 'EVENTS_INIT_OPEN',
        payload: eventsAPI.initOpen()
    }
};