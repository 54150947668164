import React from 'react';

class Filter extends React.Component {

    closeFilter = e => {
        e.preventDefault();
        this.props.filterOpenAction(false);
    };

    renderItems(key, items = []) {
        return items.map(item => {
            const selected = this.props.selectedValues[key] && this.props.selectedValues[key].some(id => item.id === id);
            return (
                <li key={`${key}-${item.id}`}
                    className={`filter-value-item ${selected ? 'selected' : ''}`}
                    onClick={() => {
                        if (selected) {
                            this.props.filterUnselectItem(key, item.id);
                        } else {
                            this.props.filterSelectItem(key, item.id);
                        }
                    }}>
                    {item.value}
                </li>
            );
        });
    }

    renderBody() {
        if (this.props.allValues) {
            return this.config.map(item => {
                return <FilterSection {...item} key={item.keyName} items={this.props.allValues[item.keyName]}
                                      filter={this} />
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <nav id="filter" className={`menu ${this.props.opened ? 'menu-opened' : ''}`}>
                    <div className="menu-navbars_top">
                        <div className="menu-navbar">
                            <button title="Vymazat filtr" onClick={this.props.filterClear}>
                                <i className="fas fa-eraser" /></button>
                            <button title="Zavřít" className="dismiss-filter" onClick={this.closeFilter}>
                                <i className="fas fa-times" /></button>
                        </div>
                    </div>
                    <div className="menu-panels">
                        <div className="menu-panel">
                            {this.renderBody()}
                        </div>
                    </div>
                </nav>
                <div className={`menu-overlay clear ${this.props.opened ? 'visible' : ''}`} onClick={this.closeFilter} />
            </React.Fragment>
        );
    }
}

const FilterSection = ({keyName, title, items, filter, className}) => {
    return (
        <div className="filter-section">
            <h3 className="title">{title}</h3>
            <ul className={`values ${className}`}>
                {filter.renderItems(keyName, items)}
            </ul>
        </div>
    );
};

export {FilterSection};
export default Filter;
