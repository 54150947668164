import Filter from 'components/lists/Filter';
import {connect} from "react-redux";

import {filterOpenAction} from "actions/uiStateActions";
import {initAges, initHours, initDays,initBuildings,initCategories, filterSelectItem, filterUnselectItem, filterClear} from "actions/coursesActions";
import filterByCategory from 'helpers/filterByCategory';

class CoursesFilter extends Filter {

    config = [
        {keyName: 'ages', title: 'Věk'},
        {keyName: 'days', title: 'Den'},
        {keyName: 'hours', title: 'Hodina'},
        {keyName: 'categories', title: 'Zaměření', className: 'block'},
        {keyName: 'buildings', title: 'Místo', className: 'block'},
    ];

    //todo neslo by to nejak extrahovat do predka?
    constructor(props) {
        super(props);
        //todo pokud by tohle mohlo být jinde, můžu se zbavit classBase componenty
        this.props.initAges();
        this.props.initHours();
        this.props.initDays();
        this.props.initBuildings();
        this.props.initCategories();
    }

    componentDidUpdate() {
        filterByCategory('krouzky', 'categories',this.props.allValues, this.props.selectedValues,this.props.filterSelectItem);
    }
}

const mapStateToProps = ({coursesStore}) => {
    return {
        allValues: coursesStore.allValues,
        selectedValues: coursesStore.selectedValues
    }
};

export default connect(mapStateToProps,
    {filterOpenAction, initAges,initHours,initDays,initBuildings,initCategories, filterSelectItem, filterUnselectItem,
        filterClear})(CoursesFilter);