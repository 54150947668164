import wpapi from 'apis/wordressAPI';
import {simplifyRESTPosts} from 'helpers/simplifyRESTPost';

export const fetchNews = (posts_per_page) => async dispatch => {
    dispatch({type: 'LOAD_POSTS_REQUEST'});

    const response = await wpapi.fetchPosts({
        per_page: posts_per_page,
        categories: [process.env.REACT_APP_WP_NEWS_CATEGORIES],
        _embed: true
    });
    dispatch({
        type: 'NEWS_LOAD',
        payload: simplifyRESTPosts(response.data)
    });
};


export const fetchBlog = (posts_per_page) => async dispatch => {
    dispatch({type: 'LOAD_POSTS_REQUEST'});

    const response = await wpapi.fetchPosts({
        per_page: posts_per_page,
        categories: [process.env.REACT_APP_WP_BLOG_CATEGORIES],
        _embed: true
    });
    dispatch({
        type: 'BLOG_LOAD',
        payload: simplifyRESTPosts(response.data)
    });
};

export const fetchActivities = (posts_per_page) => async dispatch => {
    dispatch({type: 'LOAD_POSTS_REQUEST'});

    const response = await wpapi.fetchPosts({
        per_page: posts_per_page,
        categories: [process.env.REACT_APP_WP_ACTIVITIES_CATEGORIES],
        _embed: true
    });
    dispatch({
        type: 'ACTIVITIES_LOAD',
        payload: simplifyRESTPosts(response.data)
    });
};
