import ddmbase from 'apis/ddmbase';
import qs from 'querystring';

ddmbase.defaults.withCredentials = true;

export default {
    isAuthenticated(){
        return ddmbase.get('/account/user/authenticated');
    },

    signin(credentials) {
        return ddmbase.post(`/login`, qs.stringify(credentials), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .catch((error) => {
                return error.response;
            })
    },

    signout() {
        return ddmbase.get(`/logout`)
            .catch((response) => console.error(response, "API ERROR: /logout"))
    },

    signup(formData) {
        return ddmbase.post(`/account/signup`, qs.stringify(formData), {withCredentials: false})
            .catch((response) => console.error(response, "API ERROR: /account/signup"))
    },

    accountUpdate(formData) {
        return ddmbase.post('/account/edit', qs.stringify(formData))
            .catch((response) => console.error(response, "API ERROR: /account/edit"))
    },

    participantUpdate(id, formData) {
        return ddmbase.post(`account/participant/${id}`, qs.stringify(formData))
            .catch((response) => console.error(response, "API ERROR: /account/participant/:id"))
    },

    participantCreate(formData) {
        return ddmbase.post(`account/participant/add`, qs.stringify(formData))
            .catch((response) => console.error(response, "API ERROR: /account/participant/add"))
    },

    participantRemove(id) {
        return ddmbase.get(`account/participant/${id}/remove`)
            .catch((response) => console.error(response, "API ERROR: /account/participant/:id/remove"))
    },

    parentUpdate(id, formData) {
        return ddmbase.post(`account/parent/${id}`, qs.stringify(formData))
            .catch((response) => console.error(response, "API ERROR: /account/parent/:id"))
    },

    parentCreate(formData) {
        return ddmbase.post(`account/parent/add`, qs.stringify(formData))
            .catch((response) => console.error(response, "API ERROR: /account/parent/add"))
    },

    parentRemove(id) {
        return ddmbase.get(`account/parent/${id}/remove`)
            .catch((response) => console.error(response, "API ERROR: /account/parent/:id/remove"))
    },

    getCurrentUser() {
        return ddmbase.get(`/account/user`).catch((error) => {
            if (error.response.status !== 401) {
                console.error(error, "API ERROR: /account/user");
            }
            return error.response;
        })
    },

    setPassword(formData) {
        return ddmbase.post('/account/set-password', qs.stringify(formData), {
            //when user request for new password, is not signed in
            withCredentials: false
        })
            .catch((error) => console.error(error, "API ERROR: /account/set-password"));
    },

    confirmAccount(hash) {
        return ddmbase.post('/account/confirm', qs.stringify({hash}), {
            //when user confirms account, is not signed in
            withCredentials: false
        })
            .catch((error) => console.error(error, "API ERROR: /account/confirm"));
    },

    resetPassword({username}) {
        return ddmbase.post('/account/reset-password', qs.stringify({email: username}), {
            //when user request for new password, is not signed in
            withCredentials: false
        })
            .catch((error) => console.error(error, "API ERROR: /account/reset-password"));
    },

    resendActivationLink({username}) {
        return ddmbase.post('/account/resend-confirmation', qs.stringify({email: username}), {
            //when user request for account confirmation, is not signed in
            withCredentials: false
        })
            .catch((error) => console.error(error, "API ERROR: /account/resend-confirmation"));
    },

    validateEmail(email) {
        return ddmbase.post('/account/validate-email', qs.stringify({email}), {
            withCredentials: false
        })
            .catch((error) => console.error(error, "API ERROR: /account/validate-email"));
    }
}
