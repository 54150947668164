import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Obfuscate from "react-obfuscate";

class ListDetail extends React.PureComponent {

    detailElement = React.createRef();

    componentDidMount() {
        this.changePosition();
        if(this.props.sticky){
            this.detailElement.current.style['display'] = 'block';
        }
    }

    componentDidUpdate() {
        this.changePosition();
    }

    changePosition() {
        if(this.props.changePosition) {
            this.props.changePosition(this.detailElement.current);
        }
    };

    onClosed = (itemId) => {
        this.props.onItemClosed(itemId);
    };

    render() {
        return null;
    }

    renderResizeHandler() {
        return <ReactResizeDetector skipOnMount={true} refreshMode="throttle" refreshRate={250}
                                    handleWidth onResize={this.changePosition.bind(this)} />
    }

}

export default ListDetail;

export const PersonContact = ({fullName, contactEmail, contactPhone, emailSubject}) => {
    return (
        <div>
            {fullName}
            <div>
            <Obfuscate
                email={contactEmail}
                headers={{
                    subject: emailSubject,
                }}
            />
            </div>
            {contactPhone &&
            <div><Obfuscate tel={contactPhone.split(" ").join("")}>{contactPhone}</Obfuscate></div>
            }
        </div>
    )
};

export const GalleryItem = ({url, title, date}) => {
    const dateAsString = new Date(date);
    return (
        <li>
            <a href={url} rel='noopener noreferrer' target="_blank">
                {dateAsString.toLocaleDateString()} {title}</a>
        </li>
    );
};