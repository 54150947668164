import React from 'react';
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import List, {ListHeader, ListFooter, EmptyList} from "components/lists/List";

import {uiChangedAction} from "actions/uiStateActions";
import {loadEvents, loadMore, filterClear} from "actions/eventsActions";

import config from 'config';
import EventsDetail from "components/events/EventDetail";
import EventItem from "components/events/EventItem";

import eventsImage from 'images/events.jpg';
import {fetchInfoPage} from "actions/pagesActions";


class EventsList extends List {

    pageConfig = {
        title: 'Akce',
        color: config.colors.color4,
        bgImage: eventsImage
    };

    state = {
        selectedItem: null,
        selectedIndex: null,
    };

    componentDidMount() {
        this.props.loadEvents();
        this.props.uiChangedAction(this.pageConfig);
    }

    renderEvents() {
        return this.props.events.map((event,index) => {
            return <EventItem key={event.eventId} event={event} itemIndex={index}
                                selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemSelect} />;
        })
    }

    render() {
        const listLength = this.props.events.length;
        return (
            <React.Fragment>
                <section className="list">
                    <ListHeader filterActive={this.props.filterActive}>
                        <li>
                            <button className="button"
                                    onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_EVENTS_CONDITIONS)}>
                                Všeobecné podmínky
                            </button>
                        </li>
                        <li>
                            <button className="button"
                                    onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_EVENTS_HOW_TO)}>
                                Jak se přihlásit?
                            </button>
                        </li>
                    </ListHeader>
                    <div className="list-body" id="list-body">
                        {listLength > 0 && this.renderEvents()}
                        {this.state.selectedItem != null &&
                            <EventsDetail event={this.state.selectedItem} changePosition={this.positionDetail}
                                        onItemClosed={this.onClosed} />
                        }
                        {/*no results*/}
                        {listLength === 0 && this.props.filterActive &&
                            <EmptyList filterClear={this.props.filterClear}>
                                Je nám líto, ale tyto požadavky nesplňuje žádný kroužek
                            </EmptyList>
                        }
                    </div>
                    {listLength > 0 &&
                    <ListFooter canLoadMore={this.props.canLoadMore} onClickHandler={this.props.loadMore}
                                itemsPerPage={config.pagination.events} />
                    }
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {eventsStore} = state;
    return {
        events: eventsStore.events,
        //already loaded events are multiplication of config.pagination.events unless last request returned less items
        canLoadMore: eventsStore.events.length % config.pagination.events === 0 && !eventsStore.noMoreResults,
        filterActive: Object.values(eventsStore.selectedValues).some((item) => !isEmpty(item)),
        isFetching: state.isFetching !== 0 //todo is it necessary???
    }
};

export default connect(mapStateToProps,{uiChangedAction, loadEvents, loadMore, filterClear, fetchInfoPage})(EventsList);