//Action creators for competition

import competitionsAPI from "apis/competitionsAPI";
import customHistory from "customHistory";


export const loadCompetitions = () => async (dispatch) => {
    dispatch({type: 'LOAD_COMPETITIONS_REQUEST'});
    const response = await competitionsAPI.getCompetitions();
    dispatch({
        type: 'COMPETITIONS_LOAD',
        payload: response.data
    });
};


export const loadCompetition = (competitionId) => async (dispatch) => {
    dispatch({type: 'LOAD_COMPETITION_REQUEST'});
    const response = await competitionsAPI.getCompetition(competitionId);
    if (response.data.length === 0) {
        customHistory.replace("/404");
    }
    dispatch({
        type: 'UI_TITLE_CHANGED',
        payload: response.data.name
    });
    dispatch({
        type: 'COMPETITION_LOAD',
        payload: response.data
    });
};
