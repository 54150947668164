import React from 'react';
import ListItem from "components/lists/ListItem";
import image from 'images/list-placeholder.png';
import ListDetail from "components/lists/ListDetail";
import {AccountDetailWrapper} from "components/lists/DetailWrapper";
import Camp from "entities/Camp";
import Payment from "components/account/Payment";


export class CampsAccountItem extends ListItem {
    render() {
        return (
            <article className={`list-item link bg-color1 ${this.props.selected ? 'detail-opened' : ''}`}
                     id={`account-item-${this.props.itemIndex}`}
                     onClick={(e) => {
                         e.preventDefault();
                         this.onClickHandler(null, this.props.itemIndex)
                     }}>
                <div className="content">
                    <header>
                        <h3 className="headline">Tábory</h3>
                    </header>
                    <div className="description">
                        Správa přihlášení na tábory
                    </div>
                </div>
                <div className="image-wrap bg-color1">
                    <img src={image} className="image" alt="Tábory" />
                </div>
            </article>
        );
    }
}

export class CampsAccountDetail extends ListDetail {
    onDetailClosed = () => {
        this.onClosed(this.props.itemIndex);
    };

    renderCampParticipations(participant) {
        return participant.campParticipations.map(participation => {
            const campHelper = new Camp(participation.camp);
            const {camp} = participation;
            return (
                <tr key={participation.campParticipationId}>
                    <th>{camp.name}</th>
                    <td>{camp.schoolYear}</td>
                    <td>{participation.variableNumber}</td>
                    <td>{campHelper.renderDate()}</td>
                    <td>
                        <Payment amount={participation.payment} date={participation.paymentDate} />
                    </td>
                    <td>
                        {participation.applicationFormURL &&
                        <a href={participation.applicationFormURL} target="_blank"
                           rel="noopener noreferrer">stáhnout</a>
                        }
                        {!participation.applicationFormURL &&
                        <button>nahrát</button>
                        }
                    </td>
                    <td>
                        {!participation.payment &&
                        <button>Odhlásit</button>
                        }
                    </td>
                </tr>
            )
        });
    }

    renderCamps() {
        const {participants} = this.props.user;
        return participants.map(participant => {
            return (
                <React.Fragment key={participant.participantId}>
                    <h2>{participant.name} {participant.surname}</h2>
                    {participant.campParticipations.length > 0 &&
                    <div className="table-wrapper">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Kroužek</th>
                            <th>Školní rok</th>
                            <th>Variabilní symbol</th>
                            <th>Termín</th>
                            <th>Záloha</th>
                            <th>Doplatek</th>
                        </tr>
                        </thead>
                        <tbody>
                            {this.renderCampParticipations(participant)}
                        </tbody>
                    </table>
                    </div>
                    }
                    {participant.campParticipations.length === 0 &&
                    <p>Účastník není přihlášen na žádný tábor</p>
                    }
                </React.Fragment>
            );
        });
    }

    render() {
        const wrapperInfo = {
            ref: this.detailElement,
            onCloseHandler: (this.onDetailClosed),
            bgColor: 'color1'
        };
        const {participants} = this.props.user;

        return (
            <AccountDetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className='col'>
                    <h2 className="title">Tábory
                        <button title="Zavřít" className="float-right link d-md-none"
                                onClick={this.onDetailClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                    </h2>
                    <div>
                        {participants.length > 0 &&
                        this.renderCamps()
                        }
                        {participants.length === 0 &&
                        <p className="alert">
                            Váš účet nemá žádné účastníky, kteří by mohli být přihlášeni na tábor. Nejprve nějakého
                            účastníka vytvořte.
                        </p>
                        }
                    </div>
                </div>
            </AccountDetailWrapper>
        );
    }
}