import React from 'react';
import ReactDOM from 'react-dom';
import {Field, reduxForm} from "redux-form";
import {renderFile} from "helpers/forms/renderFormFields";
import * as validations from "helpers/forms/validations";
import {compose} from "redux";
import {connect} from "react-redux";
import {uploadApplicationForm} from "actions/coursesActions";


class UploadFileModal extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {submitted: null};
    }

    closeModal = () => {
        this.props.onModalClose();
    };

    onSubmit = (formData) => {
        this.props.uploadApplicationForm(this.props.participation, formData)
            .then(submitted => this.setState({submitted}))
    };

    renderForm() {
        if (this.state.submitted === null) {
            const {handleSubmit, submitting, error} = this.props;
            return (
                <div className="form-wrapper">
                    <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                        <Field name="file" type="file"
                               component={renderFile}
                               validate={[validations.validateRequired, validations.validateFileSize]}
                        />
                        <button type="submit" className="button" disabled={submitting || error}>
                            <i className="fas fa-upload" /> Nahrát
                        </button>
                    </form>
                </div>
            );
        }
    }


    renderResult() {
        const {submitted} = this.state;
        if (submitted === null) {
            return null;
        }

        return (
            <div className="alert">
                {submitted === true && <p>Přihláška byla úspěšně uložena</p>}
                {submitted === false &&
                <p>
                    <strong>Přihlášku se nepodařilo uložit.</strong><br />
                    Zkuste to prosím později nebo mám prosím přihlášku doručte v papírové formě.
                </p>
                }
                <button className="button transparent" onClick={this.closeModal}>Zavřít</button>
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(
            <React.Fragment>
                <div className="modal fade show" role="dialog" onClick={this.closeModal}>
                    <div className="modal-dialog modal-dialog-centered modal-xl info-modal">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Nahrajte prosím fotokopii přihlášky</h2>
                                <button title="Zavřít" className="float-right link" onClick={this.closeModal}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <h3>Náležitosti fotokopie přihlášky:</h3>
                                <ul>
                                    <li>Přihláška musí být ve formátu PDF. Jiné formáty, včetně JPG nahrát nelze.</li>
                                    <li>Přihláška nezletilého účastníka musí být podepsaná zákonným zástupcem. V
                                        ostatních
                                        případech přihlášku podepisuje účastník.
                                    </li>
                                    <li>Přihláška musí obsahovat obě strany!</li>
                                    <li>Jedna strana přihlášky musí pokrývat celý formát A4, nesmí mít oproti textu
                                        tmavý
                                        podklad a musí být jasně čitelná po celé ploše dokumentu.
                                    </li>
                                </ul>
                                <p>V případě, že z technických důvodů nemůžete těmto náležitostem vyhovět, prosíme,
                                   odevzdejte přihlášku v tištěné podobě. Děkujeme.</p>
                                {this.renderForm()}
                                {this.renderResult()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" />
            </React.Fragment>,
            document.querySelector('#modal')
        );
    }
}

export default compose(
    connect(null, {uploadApplicationForm}),
    reduxForm({form: 'upload'}))(UploadFileModal);