import customHistory from "customHistory";
import slugify from "slugify";

export default (slug, type, allFilterValues, selectedFilterValues, filterSelectMethod) => {
    const {pathname} = customHistory.location;
    const regex = new RegExp(`\\/${slug}\\/([a-z\\-]+)\\/?`);
    const matches = regex.exec(pathname);

    if (matches !== null) {
        if (allFilterValues[type]) {
            const [, categorySlug] = matches;

            //special case; event detail URLs from former web were in format /akce/detail/:id -> we want to ignore those
            if('detail' === categorySlug){
                return;
            }

            const category = allFilterValues[type].find(({value}) => slugify(value.toLowerCase()) === categorySlug);
            if (category && (!selectedFilterValues[type] || selectedFilterValues[type].length === 0 || !selectedFilterValues[type].some(id => id === category.id))) {
                filterSelectMethod(type, category.id);
            }
            customHistory.push(`/${slug}`);
        }
    }
}