
export default () => {
    const start = new Date();
    start.setDate(1);
    start.setHours(8);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMonth(8); //September (=9, months are 0-indexed)
    // school year should start on 1st of September. If that day is a weekend or Friday, then it start the following Monday
    if([1,2,3,4].includes(start.getDay())){
        return start;
    }
    //move to the closes monday
    while (start.getDay() !== 1) {
        start.setDate(start.getDate() + 1);
    }
    return start;
}

