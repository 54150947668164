import slugify from "slugify";

class ServiceForKids{
    service = null;
    id = null;

    constructor(service,id) {
        this.service = service;
        this.id = id;
    }

    renderAge() {
        const {ageFrom, ageTo} = this.service;
        if (ageFrom && ageTo) {
            return `${ageFrom} - ${ageTo} let`;
        } else if (ageFrom && !ageTo) {
            return `od ${ageFrom} let`;
        } else if (!ageFrom && ageTo) {
            return `do ${ageTo} let`;
        }
    }

    getPermalink(prefix){
        return `/${prefix}/${this.id}-${slugify(this.service.name.toLowerCase())}`;
    }
}

export default ServiceForKids;