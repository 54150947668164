

export default (competitionsStore = {competitions: [], competition: null}, {type, payload}) => {
    switch(type){
        case 'COMPETITIONS_LOAD':
            return {...competitionsStore, competitions: payload};

        case 'COMPETITION_LOAD':
            return {...competitionsStore, competition: payload};

        default:
            return competitionsStore;
    }
}