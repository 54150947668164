import React from 'react';
import {Helmet} from "react-helmet";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from 'react-redux';

import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import Homepage from "components/layout/Homepage";
import homepageImage from 'images/homepage.jpg';
import CoursesList from "components/courses/CoursesList";
import CampsList from "components/camps/CampsList";
import Menu from 'components/layout/Menu';
import CoursesFilter from "components/courses/CoursesFilter";
import CampsFilter from "components/camps/CampsFilter";
import config from 'config';
import EventsList from "components/events/EventsList";
import EventFilter from "components/events/EventFilter";
import ProgramsList from "components/programs/ProgramsList";
import CompetitionsList from "components/competitions/CompetitionsList";
import CampsDetailPage from "components/camps/CampsDetailPage";
import CoursesDetailPage from "components/courses/CoursesDetailPage";
import EventsDetailPage from "components/events/EventsDetailPage";
import CompetitionsDetailPage from "components/competitions/CompetitionsDetailPage";
import ProgramsDetailPage from "components/programs/ProgramsDetailPage";
import Spinner from "components/Spinner";
import PagesList from "components/lists/PagesList";
import PageNotFound from 'components/404';
import GalleryFilter from "components/galleries/GalleryFilter";
import PhotoGalleryList from "components/galleries/PhotoGalleryList";
import VideoGalleryList from "components/galleries/VideoGalleryList";
import InfoPageModal from "components/posts/InfoPageModal";
import ReactGA from 'react-ga';
import customHistory from 'customHistory';
import BlogList from "components/lists/BlogList";
import {RedirectFormerURLs} from 'routes';
import AccountList from "components/account/AccountList";

import {checkAuthentication, loginFromStorage} from 'actions/accountActions';
import {loadSettings} from 'actions/commonActions';
import ActivationConfirmed from "components/account/ActivationConfirmed";
import PasswordReset from "components/account/PasswordReset";
import SignUp from "components/account/SignUp";
import SetPassword from "components/account/SetPassword";
import AlertNotification from "components/layout/AlertNotification";
import SignIn from "components/account/SignIn";
import ActivitiesList from "components/lists/ActivitiesList";

class App extends React.Component {



    constructor(props, context) {
        super(props, context);

        ReactGA.initialize('UA-43576644-1');
    }

    componentDidMount() {
        this.props.loginFromStorage();
        this.props.loadSettings();

        if(this.props.history){
            this.props.history.listen( () => this.props.checkAuthentication());
        }
        this.interval = setInterval(() => this.props.checkAuthentication(), 1000*60*1);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet
                    titleTemplate={"%s : DDM JM"}
                    defaultTitle={config.defaultTitle}>
                    <title>{this.props.title}</title>
                    <style type="text/css">{`
                        :root{
                        --mainColor: ${this.props.color};
                        }
                        .header-background {
                        background-image: url(${this.props.bgImage});
                        }
                    `}</style>
                    <meta name="version" content={process.env.REACT_APP_VERSION} />
                    <meta property='og:title' content={config.meta.title} />
                    <meta property='og:site_name' content={config.meta.title} />
                    <meta property='og:url' content={`${process.env.REACT_APP_BASE_URL}${customHistory.location.pathname}`} />
                    <meta property='og:image' content={`${process.env.REACT_APP_BASE_URL}${this.props.bgImage}`} />
		    <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NMQ9WGF');`}
                    </script>
                </Helmet>

                <Header title={this.props.title} />
                <main className="main">
                    <div className="container-fluid">
                        <Switch>
                            <Route exact path='/' component={Homepage} />
                            <Route path={['/krouzky/:id-:name','/krouzky/:foo(\\d+)/detail/:id(\\d+)']} exact component={CoursesDetailPage} />
                            <Route path={['/krouzky','/krouzky/:category']} exact component={CoursesList} />
                            <Route path={['/tabory/:id-:name','/tabory/:foo(\\d+)/detail/:id(\\d+)']} component={CampsDetailPage} />
                            <Route path={['/tabory','/tabory/:category']} exact component={CampsList} />
                            <Route path={['/akce/:id(\\d+)-:name','/akce/detail/:id(\\d+)']} exact component={EventsDetailPage} />
                            <Route path={['/akce','/akce/:month(\\w+)']} component={EventsList} />
                            <Route path='/skoly/programy' exact component={ProgramsList} />
                            <Route path={['/skoly/programy/:id-:name','/skoly/program/:id']} exact component={ProgramsDetailPage} />
                            <Route path='/skoly/souteze' exact component={CompetitionsList} />
                            <Route path={['/skoly/souteze/:id-:name','/skoly/souteze/detail/:id(\\d+)']} exact component={CompetitionsDetailPage} />
                            <Route path='/foto' exact component={PhotoGalleryList} />
                            <Route path='/video' exact component={VideoGalleryList} />
                            <Route path='/404' component={PageNotFound} />
                            <Route path='/ucet/potvrzeni' component={ActivationConfirmed} />
                            <Route path='/ucet/prihlaseni' component={SignIn} />
                            <Route path='/ucet/obnova-hesla' component={PasswordReset} />
                            <Route path='/ucet/nastaveni-hesla' component={SetPassword} />
                            <Route path='/ucet/registrace' component={SignUp} />
                            <Route path='/ucet' component={AccountList} />
                            {/* Former URLs redirecting */}
                            <Route path={['/stranka/:id(\\d+)','/stranka/:idParent(\\d+)/detail/:id(\\d+)']} exact
                                   render={({match}) => {
                                    return (<Redirect to={RedirectFormerURLs[match.params.id]} />)
                                   }} />
                            <Route path={['/ze-zivota-ddm/:slug','/ze-zivota-ddm']} component={BlogList} />
                            <Route path={['/nabidka/:slug','/nabidka']} component={ActivitiesList} />
                            <Route path={['/:root/:slug/:subpage','/:slug/:subpage', '/:slug']} exact component={PagesList} />
                        </Switch>
                    </div>
                </main>
                <Spinner />
                <div className="header-background" />
                <Footer />
                <Menu opened={this.props.menuOpen} />
                <Switch>
                    <Route path='/krouzky' render={() => (
                        <CoursesFilter opened={this.props.filterOpen} />
                    )}
                    />
                    <Route path='/tabory' render={() => (
                        <CampsFilter opened={this.props.filterOpen} />
                    )} />
                    <Route path='/akce' render={() => (
                        <EventFilter opened={this.props.filterOpen} />
                    )} />
                    <Route path='/foto' component={() => (
                        <GalleryFilter galleryType='photos' opened={this.props.filterOpen} />
                    )} />
                    <Route path='/video' component={() => (
                        <GalleryFilter galleryType='videos' opened={this.props.filterOpen} />
                    )} />
                </Switch>
                <InfoPageModal />
                <AlertNotification />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({UIState}) => {
    return {
        menuOpen: UIState.menuOpen,
        filterOpen: UIState.filterOpen,
        title: UIState.title,
        color: UIState.color ? UIState.color : config.defaultColor,
        bgImage: UIState.bgImage ? UIState.bgImage : homepageImage
    }
};

export default connect(mapStateToProps, {loginFromStorage, loadSettings, checkAuthentication})(App);
