import accountAPI from 'apis/accountAPI';
import customHistory from 'customHistory';
import {menuOpenAction} from 'actions/uiStateActions';

export const signin = (formData) => async dispatch => {
    dispatch({type: 'SIGNIN_REQUEST'});
    const signInRequest = await accountAPI.signin(formData);
    if (signInRequest.status === 200) {
        const userRequest = await accountAPI.getCurrentUser();
        if (userRequest.status === 200) {
            dispatch({
                type: 'AUTH_SIGNIN',
                payload: userRequest.data
            });
            localStorage.setItem("user", JSON.stringify(userRequest.data));

            //redirect to account page;
            customHistory.replace("/ucet");
            dispatch(menuOpenAction(false));
        } else {
            dispatch({
                type: 'AUTH_ERROR',
                payload: {status: userRequest.status, message: userRequest.statusText}
            });
        }
    } else {
        let status = signInRequest.status;
        if(signInRequest.headers['account-locked']){
            status = 'account-locked';
        }
        else if(signInRequest.headers['account-disabled']){
            status = 'account-disabled';
        } else if (signInRequest.headers['bad-credentials']) {
            status = 'bad-credentials';
        }
        dispatch({
            type: 'AUTH_ERROR',
            payload: status
        });
    }
    dispatch({type: 'SIGNIN_LOADED'});
};

export const clearAuthErrors = () => {return {type: 'CLEAR_AUTH_ERRORS'}};

export const signup = (formData) => async dispatch => {
    dispatch({type: 'SIGNUP_REQUEST'});
    const response = await accountAPI.signup(formData);
    dispatch({
        type: 'AUTH_SIGNUP',
        payload: response.data
    });
    dispatch({type: 'SIGNUP_LOADED'});
};

export const accountUpdate = (formData) => async dispatch => {
    dispatch({type: 'ACCOUNT_EDIT_REQUEST'});
    const response = await accountAPI.accountUpdate(formData);
    dispatch({
        type: 'AUTH_ACCOUNT_EDIT',
        payload: response.data
    });
    dispatch({type: 'ACCOUNT_EDIT_LOADED'});
};

export const participantUpdate = () => async (dispatch,getState) => {
    dispatch({type: 'PARTICIPANT_EDIT_REQUEST'});
    const {form: {participant: {values}}} = getState();
    const id = values.participantId;
    delete values.participantId;
    delete values.courseParticipations;
    delete values.eventsParticipations;
    delete values.campsParticipations;
    const response = await accountAPI.participantUpdate(id,values);
    dispatch({
        type: 'AUTH_PARTICIPANT_EDIT',
        payload: response.data
    });
    dispatch({type: 'PARTICIPANT_EDIT_LOADED'});
};

export const participantCreate = (formData) => async (dispatch) => {
    dispatch({type: 'PARTICIPANT_CREATE_REQUEST'});
    const response = await accountAPI.participantCreate(formData);
    dispatch({
        type: 'AUTH_PARTICIPANT_EDIT',
        payload: response.data
    });
    dispatch({type: 'PARTICIPANT_CREATE_LOADED'});
};

//todo přejmenovat AUTH_PARTICIPANT_EDIT na něco víc odpovídajícího (používá se to vícero místech)
export const participantRemove = (id) => async (dispatch) => {
    dispatch({type: 'PARTICIPANT_REMOVE_REQUEST'});
    const response = await accountAPI.participantRemove(id);
    dispatch({
        type: 'AUTH_PARTICIPANT_EDIT',
        payload: response.data
    });
    dispatch({type: 'PARTICIPANT_REMOVE_LOADED'});
};

export const parentUpdate = () => async (dispatch, getState) => {
    dispatch({type: 'PARENT_EDIT_REQUEST'});
    const {form: {parent: {values}}} = getState();
    const id = values.parentId;
    delete values.parentId;
    delete values.participants;
    const response = await accountAPI.parentUpdate(id, values);
    dispatch({
        type: 'AUTH_PARENT_EDIT',
        payload: response.data
    });
    dispatch({type: 'PARENT_EDIT_LOADED'});
};

export const parentCreate = (formData) => async (dispatch) => {
    dispatch({type: 'PARENT_CREATE_REQUEST'});
    const response = await accountAPI.parentCreate(formData);
    dispatch({
        type: 'AUTH_PARENT_EDIT',
        payload: response.data
    });
    dispatch({type: 'PARENT_CREATE_LOADED'});
};

//todo přejmenovat AUTH_PARTICIPANT_EDIT na něco víc odpovídajícího (používá se to vícero místech)
export const parentRemove = (id) => async (dispatch) => {
    dispatch({type: 'PARENT_REMOVE_REQUEST'});
    const response = await accountAPI.parentRemove(id);
    dispatch({
        type: 'AUTH_PARENT_EDIT',
        payload: response.data
    });
    dispatch({type: 'PARENT_REMOVE_LOADED'});
};

export const setPassword = (formData) => async dispatch => {
    formData.hash = customHistory.location.search.substr(1);
    const response = await accountAPI.setPassword(formData);
    dispatch({
        type: 'AUTH_PASSWORD_SET',
        payload: response.data
    });
};

export const confirmAccount = () => async dispatch => {
    dispatch({type: 'CONFIRM_ACCOUNT_REQUEST'});
    const hash = customHistory.location.search.substr(1);
    const response = await accountAPI.confirmAccount(hash);
    dispatch({
        type: 'AUTH_CONFIRMED',
        payload: response.data
    });
    dispatch({type: 'CONFIRM_ACCOUNT_LOADED'});
};

export const signout = () => dispatch => {
    dispatch({type: 'SIGNOUT_REQUEST'});
    accountAPI.signout();
    localStorage.removeItem('user');
    redirectFromAccoutPage();
    dispatch(menuOpenAction(false));
    dispatch({
        type: 'AUTH_SIGNOUT'
    });
    dispatch({type: 'SIGNOUT_LOADED'});
};

export const resetPassword = (formData) => async dispatch => {
    dispatch({type: 'RESET_PASSWORD_REQUEST'});
    const request = await accountAPI.resetPassword(formData);
    if (request.status === 200) {
        dispatch({
            type: 'AUTH_PASSWORD_RESET'
        });
    };
    dispatch({type: 'RESET_PASSWORD_LOADED'});
};

export const resendActivationLink = (formData) => async dispatch => {
    dispatch({type: 'RESEND_ACTIVATION_LINK_REQUEST'});
    const request = await accountAPI.resendActivationLink(formData);
    if (request.status === 200) {
        dispatch({
            type: 'AUTH_RESEND_ACTIVATION'
        });
    }
    dispatch({type: 'RESEND_ACTIVATION_LINK_LOADED'});
};

export const checkAuthentication = () => (dispatch,getState) => {
    const {account: {user}} = getState();
    //only check if there is a user in store
    if(user) {
        const promise = accountAPI.isAuthenticated();
        promise.catch(() => {
            redirectFromAccoutPage();
            signOutUser(dispatch);
            dispatch({
                type: 'AUTH_NOTIFY_LOGOUT',
                payload: true
            });
        });
    }
};

export const loginFromStorage = () => async dispatch => {
    const user = localStorage.getItem("user");
    if(user){
        const userRequest = await accountAPI.getCurrentUser();
        if (userRequest.status === 200) {
            dispatch({
                type: 'AUTH_SIGNIN',
                payload: userRequest.data
            });
        }
        else{
            signOutUser(dispatch);
        }
    } else{
        //do nothing here -> checkAuthentication solves it
    }
};

const redirectFromAccoutPage = () => {
  const location = customHistory.location.pathname;
  if('/ucet' === location){
      customHistory.replace("/");
  }
};

const signOutUser = dispatch =>{
    localStorage.removeItem('user');
    dispatch({
        type: 'AUTH_SIGNOUT'
    });
};