import config from "config";
import galleriesAPI from "apis/galleriesAPI";


export const loadGalleries = (type) => async (dispatch, getState) => {
    dispatch({type: 'LOAD_GALLERY_REQUEST'});

    const state = getState().galleries[type];
    const offset = 0;
    const limit = config.pagination.galleries;
    const response = await galleriesAPI.getGallery(type,{offset, limit}, state.selectedValues);
    dispatch({
        type: `${type.toUpperCase()}_GALLERY_LOAD`,
        payload: response.data
    });
};

export const loadMore = (type) => async (dispatch, getState) => {
    dispatch({type: 'LOAD_MORE_GALLERIES_REQUEST'});

    const state = getState().galleries[type];
    const limit = config.pagination.galleries;
    const offset = Math.max(state.loadOffset, limit);
    const response = await galleriesAPI.getGallery(type,{offset, limit}, state.selectedValues);
    dispatch({
        type: `${type.toUpperCase()}_GALLERY_LOAD_MORE`,
        payload: response.data
    });
};

export const filterSelectItem = (type,key, value) => dispatch => {
    dispatch({
        type: `${type.toUpperCase()}_GALLERY_FILTER_SELECT`,
        payload: {key, value}
    });
    dispatch(loadGalleries(type));
};

export const filterUnselectItem = (type,key, value) => dispatch => {
    dispatch({
        type: `${type.toUpperCase()}_GALLERY_FILTER_UNSELECT`,
        payload: {key, value}
    });
    dispatch(loadGalleries(type));
};

export const filterClear = (type) => dispatch => {
    dispatch({
        type: `${type.toUpperCase()}_GALLERY_FILTER_CLEAR`,
    });

    dispatch(loadGalleries(type));
};

export const initMonths = (type) => {
    return {
        type: `${type.toUpperCase()}_GALLERY_INIT_MONTHS`,
        payload: galleriesAPI.initMonths()
    }
};

export const initYears = (type) => {
    return {
        type: `${type.toUpperCase()}_GALLERY_INIT_YEARS`,
        payload: galleriesAPI.initYears()
    }
};