import Filter from 'components/lists/Filter';
import {connect} from "react-redux";

import {filterOpenAction} from "actions/uiStateActions";
import {initAges, initMonths, initCategories, filterClear, filterSelectItem, filterUnselectItem,} from 'actions/campsActions'
import filterByCategory from "helpers/filterByCategory";

class CampsFilter extends Filter{

    config = [
        {keyName: 'ages', title: 'Věk'},
        {keyName: 'months', title: 'Měsíc'},
        {keyName: 'categories', title: 'Druh', className: 'block'},
    ];

    constructor(props){
        super(props);
        this.props.initAges();
        this.props.initMonths();
        this.props.initCategories();
    }

    componentDidUpdate() {
        filterByCategory('tabory', 'categories', this.props.allValues, this.props.selectedValues, this.props.filterSelectItem);
    }
}

const mapStateToProps = ({campsStore}) => {
    return {
        allValues: campsStore.allValues,
        selectedValues: campsStore.selectedValues
    }
};

export default connect(mapStateToProps,{filterOpenAction, initAges, initMonths, initCategories,
    filterSelectItem, filterUnselectItem, filterClear})(CampsFilter);