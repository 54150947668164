import ddmbaseAPI, {convertFilterKeyNames} from "./ddmbase";
import config from "config";

export default {

    getGallery(path, params, filter = null) {
        const filledFilter = convertFilterKeyNames(filter);
        return ddmbaseAPI.get(`/${path}`, {
            params: {...params, ...filledFilter}
        }).catch((response) => console.error(response, `API ERROR: ${path}`));
    },

    initMonths() {
        const months = [];
        for (let i = 0; i <= 11; i++) {
            months.push({id: i, value: config.l10n.monthsNames[i]});
        }
        return months;
    },

    initYears() {
        const years = [];
        for (let i = 2012; i <= new Date().getFullYear(); i++) {
            years.push({id: i, value: i});
        }
        return years;
    },


}