const INITIAL_STATE = {
    events: [],
    event: null,
    selectedValues: {},
    noMoreResults: false,
    allValues: {},
    loadOffset: 0
};

export default (eventsStore = INITIAL_STATE, {type, payload}) => {
    let {selectedValues} = eventsStore;

    switch(type){
        case 'EVENTS_LOAD':
            return {...eventsStore, events: payload};

        case 'EVENT_LOAD':
            return {...eventsStore, event: payload};

        case 'EVENTS_LOAD_MORE':
            return {
                ...eventsStore,
                loadOffset: eventsStore.events.length + payload.length,
                noMoreResults: payload.length === 0,
                events: [...eventsStore.events, ...payload]
            };

        case 'EVENTS_FILTER_SELECT':
            selectedValues[payload.key] = [...eventsStore.selectedValues[payload.key] || [], payload.value];
            return {
                ...eventsStore,
                loadOffset: 0,
                selectedValues: {...eventsStore.selectedValues, ...selectedValues}
            };

        case 'EVENTS_FILTER_UNSELECT':
            const index = eventsStore.selectedValues[payload.key].indexOf(payload.value);
            selectedValues[payload.key] = [
                ...eventsStore.selectedValues[payload.key].slice(0, index),
                ...eventsStore.selectedValues[payload.key].slice(index + 1)
            ];
            return {
                ...eventsStore,
                loadOffset: 0,
                selectedValues: {...eventsStore.selectedValues, ...selectedValues}
            };

        case 'EVENTS_FILTER_CLEAR':
            return {
                ...eventsStore,
                loadOffset: 0,
                selectedValues: {}
            };

        case 'EVENTS_INIT_AGES':
            return {
                ...eventsStore,
                allValues: {...eventsStore.allValues, ages: payload},
            };

        case 'EVENTS_INIT_MONTHS':
            return {
                ...eventsStore,
                allValues: {...eventsStore.allValues, months: payload},
            };

        case 'EVENTS_INIT_OPEN':
            return {
                ...eventsStore,
                allValues: {...eventsStore.allValues, open: payload},
            };

        default:
            return eventsStore;
    }
}