import {connect} from 'react-redux';

import {isEmpty} from "lodash";
import GalleryList from "components/galleries/GalleryList";
import {uiChangedAction} from "actions/uiStateActions";
import {loadGalleries, loadMore} from "actions/galleriesActions";
import config from "config";

const mapStateToProps = ({galleries}, {galleryType}) => {
    const type = galleries[galleryType];
    return {
        galleries: type.galleries,
        canLoadMore: type.galleries.length % config.pagination.galleries === 0,
        filterActive: Object.values(type.selectedValues).some((item) => !isEmpty(item)),
    }
};

const WrappedGalleryList = connect(mapStateToProps,{uiChangedAction, loadGalleries, loadMore})(GalleryList);
WrappedGalleryList.defaultProps = {
    galleryType: 'photos',
    title: 'Fotogalerie',
    color: config.colors.color2,
};

export default WrappedGalleryList;