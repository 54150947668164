import 'react-app-polyfill/ie11';
import 'core-js/es/object'
import "string.prototype.startswith";
import 'helpers/ie-polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from "react-router-dom";
import {Provider} from 'react-redux'
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';

import reducers from 'reducers';
import ScrollToTop from "helpers/ScrollToTop";
import App from 'components/App.js';
import history from 'customHistory';
import "./scss/main.scss";

const middlewares = [thunk];
const composeEnhancers = typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
    && process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ScrollToTop>
                <App history={history} />
            </ScrollToTop>
        </Router>
    </Provider>,
    document.querySelector('#root'));
