import React from 'react';
import {Field} from "redux-form";
import {renderAuthField, renderDateTimePicker, renderField, renderTextarea} from "helpers/forms/renderFormFields";
import * as validations from "helpers/forms/validations";

export const SetPasswordFields = () => {
    return (
        <React.Fragment>
            <Field name="password" label="Heslo" type="password" autoComplete="none"
                   component={renderAuthField}
                   validate={[validations.validatePasswordStrength]}
                   warn={validations.warnPasswordStrength}
            />
            <Field name="passwordConfirm" label="Ověření hesla" type="password" autoComplete="none"
                   component={renderAuthField}
                   validate={[validations.validateSamePassword]}
            />
        </React.Fragment>
    )
};

export const ParentAccountFields = () =>{
    return (
        <div className="row">
            <fieldset className="col-sm-6 col-md-3">
            <label>Právní status</label>
            <Field name="parentType" label="Právní status" component="select">
                <option value="other">Zákonný zástupce</option>
                <option value="mother">Matka</option>
                <option value="father">Otec</option>
            </Field>
            <Field name="name" label="Jméno a příjmení" type="text"
                   customInput={{maxLength: 100}}
                   component={renderField}
                   validate={[validations.validateRequired]}
            />
            <Field name="email" label="Email" type="email"
                   customInput={{maxLength: 80}}
                   component={renderField}
                   validate={[validations.validateEmail]}
            />
            <Field name="phone" label="Telefon" type="text"
                   customInput={{maxLength: 20}}
                   component={renderField}
                   validate={[validations.validateRequired]}
            />
        </fieldset>
        </div>
    )
};


//todo e-mail má být povinný pro účastníky nad 18 let
export const ParticipantAccountFields = () => {
    return (
        <div className="row">
            <fieldset className="col-sm-6 col-md-3">
                <legend>Osobní údaje</legend>
                <Field name="name" label="Jméno" type="text"
                       customInput={{maxLength:50}}
                       component={renderField}
                       validate={[validations.validateRequired]}
                />
                <Field name="surname" label="Příjmení" type="text"
                       customInput={{maxLength: 50}}
                       component={renderField}
                       validate={[validations.validateRequired]}
                />
                <Field name="dateOfBirth" label="Datum narození"
                       validate={[validations.validateRequired]}
                       component={renderDateTimePicker}
                />
                <Field name="nationality" label="Státní příslušnost"
                       customInput={{maxLength: 40}}
                       validate={[validations.validateRequired]}
                       component={renderField}
                />
            </fieldset>
            <fieldset className="col-sm-6 col-md-3">
                <legend>Kontakt</legend>
                <Field name="gsm" label="Telefon" type="text"
                       customInput={{maxLength: 20, placeholder: 'Povinné pro starší 18 let'}}
                       component={renderField}
                       validate={[validations.validateRequiredIfAdult]}
                />
                <Field name="email" label="E-mail" type="email"
                       customInput={{maxLength: 50, placeholder: 'Povinné pro starší 18 let'}}
                       component={renderField}
                       validate={[validations.validateRequiredIfAdult, validations.validateEmail]}
                />
            </fieldset>
            <fieldset className="col-sm-6 col-md-3">
            <legend>Bydliště</legend>
                <Field name="street" label="Ulice" type="text"
                       customInput={{maxLength: 50}}
                       component={renderField}
                       validate={[validations.validateRequired]}
                />
                <Field name="streetNumber" label="Číslo popisné" type="text"
                       customInput={{maxLength: 10}}
                       component={renderField}
                       validate={[validations.validateRequired]}
                />
                <Field name="city" label="Město" type="text"
                       customInput={{maxLength: 50}}
                       component={renderField}
                       validate={[validations.validateRequired]}
                />
                <Field name="zipCode" label="PSČ" type="text" placeholder="bez mezer"
                       component={renderField} validate={[validations.validateZipCode]}
                />
            </fieldset>
            <fieldset className="col-sm-6 col-md-3">
                <legend>Ostatní</legend>
                <Field name="school" label="Škola" type="text"
                       customInput={{maxLength: 70, placeholder: "Nepovinný údaj"}}
                       component={renderField}
                />
                <Field name="publicNotice" label="Důležitá sdělení vedoucím"
                       customInput={{rows: 8, placeholder: "Zdravotní potíže, zdravotní omezení, alergie, další důležitá upozornění, ..."}}
                       component={renderTextarea}
                />
            </fieldset>
        </div>
    )
};