import React from 'react';
import {connect} from 'react-redux';
import ListItem from "components/lists/ListItem";
import image from 'images/list-placeholder.png';
import ListDetail from "components/lists/ListDetail";
import {AccountDetailWrapper} from "components/lists/DetailWrapper";
import ParticipantForm from "components/account/forms/ParticipantForm";
import {participantRemove} from 'actions/accountActions';

export class ParticipantsAccountItem extends ListItem {
    render() {
        return (
            <article className={`list-item link bg-color3 ${this.props.selected ? 'detail-opened' : ''}`}
                     id={`account-item-${this.props.itemIndex}`}
                     onClick={(e) => {
                         e.preventDefault();
                         this.onClickHandler(null, this.props.itemIndex)
                     }}>
                <div className="content">
                    <header>
                        <h3 className="headline">Účastníci</h3>
                    </header>
                    <div className="description">
                        Správa účastníků
                    </div>
                </div>
                <div className="image-wrap bg-color3">
                    <img src={image} className="image" alt="Účastníci" />
                </div>
            </article>
        );
    }
}

class ParticipantsAccountDetailNotConnected extends ListDetail {


    constructor(props) {
        super(props);

        this.state = {
            formOpened: false,
            participantToEdit: null
        }
    }

    openFormHandler = (participantToEdit) => {
        this.setState({formOpened: true, participantToEdit})
    };

    onDetailClosed = () => {
        this.onClosed(this.props.itemIndex);
    };

    onFormSent = () => {
        this.setState({formOpened: false});
        this.changePosition()
    };

    renderForm() {
        return <ParticipantForm closeForm={this.onFormSent} participant={this.state.participantToEdit} />
    }

    canParticipantBeRemoved({courseParticipations,campParticipations,eventParticipations}){
        return (!courseParticipations || courseParticipations.length === 0) &&
            (!campParticipations || campParticipations.length === 0) &&
            (!eventParticipations || eventParticipations.length === 0)
    }


    tableEditActions = (participant) => {
        return (
            <React.Fragment>
                <button onClick={() => this.openFormHandler(participant)} className="link-decorated"
                        title="Editovat účastníka">
                    <i className="fas fa-user-edit" />
                </button>
                {this.canParticipantBeRemoved(participant) &&
                <button onClick={() => this.props.participantRemove(participant.participantId)}
                        className="link-decorated"
                        title="Smazat účastníka">
                    <i className="fas fa-user-minus" />
                </button>
                }
            </React.Fragment>
        )
    };

    renderMissingParentAlert(){
        const {parents, participants} = this.props.user;
        if ((!parents || parents.length === 0) && participants.some(p => p.age < 18)) {
            return (
                <p className="alert">
                    <strong>Minimálně jeden z účastníků je nezletilý.</strong><br />
                    Pro zápis účastníka vložte alespoň jednoho zákonného
                    zástupce.
                </p>
            );
        }
        return null;
    }

    render() {
        const wrapperInfo = {
            ref: this.detailElement,
            onCloseHandler: (this.onDetailClosed),
            bgColor: 'color3'
        };


        return (
            <AccountDetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className='col'>
                    <h2 className="title">Účastníci
                        <button title="Zavřít" className="float-right link d-md-none"
                                onClick={this.onDetailClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                    </h2>
                    {this.state.formOpened &&
                    this.renderForm()
                    }
                    {!this.state.formOpened &&
                    <React.Fragment>
                        {this.renderMissingParentAlert()}
                        <ParticipantsTable participants={this.props.user.participants}
                                           Actions={this.tableEditActions}>
                            <p className="text-center">
                                <button onClick={() => this.openFormHandler(null)} className="button">
                                    Přidat účastníka
                                </button>
                            </p>
                        </ParticipantsTable>
                    </React.Fragment>
                    }
                </div>
            </AccountDetailWrapper>
        );
    }
}

export const ParticipantsAccountDetail = connect(null, {participantRemove})(ParticipantsAccountDetailNotConnected);

export const ParticipantsTable = (props) => {

    const renderParticipants = (participants) => {
        return participants.map(participant => {
            return (
                <tr key={participant.participantId}>
                    <td className="nowrap">{participant.name} {participant.surname}</td>
                    <td className="d-none d-sm-table-cell nowrap">{participant.dateOfBirth} ({participant.age})</td>
                    {!props.onlyRequiredFields &&
                    <React.Fragment>
                        <td className="d-none d-md-table-cell">{participant.email}</td>
                        <td className="d-none d-md-table-cell">{participant.gsm}</td>
                        <td className="d-none d-md-table-cell">{participant.school}</td>
                        <td className="d-none d-md-table-cell">{participant.address}</td>
                    </React.Fragment>
                    }
                    <td className="text-center">
                        {props.Actions(participant)}
                    </td>
                </tr>
            );
        });
    };

    const {participants} = props;
    return (
        <div className="table-wrapper">
            {participants.length > 0 &&
            <table className="table">
                <thead>
                <tr>
                    <th>Jméno</th>
                    <th className="d-none d-sm-table-cell">Datum narození</th>
                    {!props.onlyRequiredFields &&
                    <React.Fragment>
                        <th className="d-none d-md-table-cell">E-mail</th>
                        <th className="d-none d-md-table-cell">Telefon</th>
                        <th className="d-none d-md-table-cell">Škola</th>
                        <th className="d-none d-md-table-cell">Adresa</th>
                    </React.Fragment>
                    }
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {renderParticipants(participants)}
                </tbody>
            </table>
            }
            {participants.length === 0 &&
            <p className="alert">
                Nemáte žádné účastníky Nejprve nějakého
                účastníka vytvořte.
            </p>
            }
            {props.children}
        </div>
    )
};
