import React from 'react';

import List, {ListFooter, ListHeader} from "components/lists/List";
import GalleryItem from "components/galleries/GalleryItem";
import VideoDetail from 'components/galleries/VideoDetail';
import config from "config";
import photogalleryImage from 'images/photogalleries.jpg';
import videogalleryImage from 'images/videogalleries.jpg';

class GalleryList extends List {

    state = {
        selectedItem: null,
        selectedIndex: null,
    };

    images = {
        photos: photogalleryImage,
        videos: videogalleryImage
    };

    componentDidMount() {
        this.props.loadGalleries(this.props.galleryType);
        const {title,color} = this.props;
        this.props.uiChangedAction({
            title,color,
            bgImage: this.images[this.props.galleryType]
        });
    }

    renderGalleries() {
        return this.props.galleries.map((gallery, index) => {
            return <GalleryItem key={gallery.galleryId} gallery={gallery} itemIndex={index}
                                onClick={this.onItemClick}
                                selected={index === this.state.selectedIndex} />;
        })
    }

    onItemClick = (event, gallery) => {
        if(this.props.galleryType === 'videos'){
            event.preventDefault();
            this.setState({selectedItem: gallery});
        }
    };

    handleBackdropClick = () =>{
      this.setState({selectedItem: null});
    };


    render() {
        const listLength = this.props.galleries.length;
        return (
            <React.Fragment>
                <section className="list">
                    <ListHeader filterActive={this.props.filterActive} />
                    <div className="list-body" id="list-body">
                        {listLength > 0 && this.renderGalleries()}
                    </div>
                    {listLength > 0 &&
                    <ListFooter canLoadMore={this.props.canLoadMore} onClickHandler={() => this.props.loadMore(this.props.galleryType)}
                                itemsPerPage={config.pagination.galleries} />
                    }
                    {this.state.selectedItem &&
                        <VideoDetail {...this.state.selectedItem} onBackdropClick={this.handleBackdropClick} />
                    }
                </section>
            </React.Fragment>
        );
    }
}

export default GalleryList;