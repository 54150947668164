import React from 'react';
import List from "components/lists/List";
import {connect} from "react-redux";
import config from "config";
import campsImage from "images/camps.jpg";
import {uiChangedAction} from "actions/uiStateActions";
import Helmet from "react-helmet";
import {CoursesAccountDetailConnected, CoursesAccountItem} from "components/account/CoursesAccountPage";
import {CampsAccountDetail, CampsAccountItem} from "components/account/CampsAccountPage";
import {EventsAccountDetail, EventsAccountItem} from "components/account/EventsAccountPage";
import {ParticipantsAccountDetail, ParticipantsAccountItem} from "components/account/ParticipantsAccountPage";
import {ParentsAccountItem,ParentsAccountDetail} from "components/account/ParentsAccountPage";
import {AccountDetail, AccountItem} from "components/account/MyAccountPage";
import SignInForm from "components/account/SignInForm";

class AccountList extends List {

    pageConfig = {
        title: 'Můj uživatelský účet',
        color: config.colors.base,
        bgImage: campsImage
    };


    state = {
        selectedItem: null,
        selectedIndex: null
    };

    sections = [
        {Item: CoursesAccountItem, Detail: CoursesAccountDetailConnected},
        {Item: CampsAccountItem, Detail: CampsAccountDetail},
        {Item: EventsAccountItem, Detail: EventsAccountDetail},
        {Item: ParticipantsAccountItem, Detail: ParticipantsAccountDetail},
        {Item: ParentsAccountItem, Detail: ParentsAccountDetail},
        {Item: AccountItem, Detail: AccountDetail}
    ];

    componentDidMount() {
        this.props.uiChangedAction(this.pageConfig);
    }

    renderSections() {
        return this.sections.map((Section, index) => {
            return (
                <React.Fragment key={`account-section-${index}`}>
                    <Section.Item itemIndex={index}
                                  onItemSelect={this.onItemSelect}
                                  selected={index === this.state.selectedIndex} />
                    {this.state.selectedIndex === index &&
                    <Section.Detail changePosition={this.positionDetail} itemIndex={index}
                                    user={this.props.user}
                                    onItemClosed={this.onClosed} />
                    }
                </React.Fragment>
            )
        });
    }


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <style type="text/css">{`
                        .main {padding-top:200px}    
                    `}</style>
                </Helmet>
                {/* displays only if user is authenticated; On page load it takes some time to check this out */}
                {this.props.authenticated &&
                <section className={`list page-list ${this.state.selectedItem != null ? 'page-open' : ''}`}>
                    <div className="list-body" id="list-body">
                        {this.renderSections()}
                    </div>
                </section>
                }
                {!this.props.authenticated &&
                <section className="not-found">
                    <div className="list-body" id="list-body">
                        <div id="list-detail">
                            <article className={`bg-color3 list-detail text-center`}>
                                <h2 className="title">Nejste přihlášeni</h2>
                                <div>
                                    <p><strong>Tato stránka je pouze pro přihlášené uživatele. Pro její zobrazení se
                                               nejprve přihlašte.</strong></p>
                                    <div className="form-wrapper">
                                    <SignInForm />
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = ({account: {user, authenticated}}) => {
    return {
        user, authenticated
    }
};

export default connect(mapStateToProps, {uiChangedAction})(AccountList);