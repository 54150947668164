import React from 'react';
import List, {EmptyList, ListFooter, ListHeader} from "components/lists/List";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import {uiChangedAction} from "actions/uiStateActions";
import {loadCamps, loadMore, filterClear} from "actions/campsActions";
import {fetchInfoPage} from 'actions/pagesActions';

import config from "config";
import CampItem from "components/camps/CampItem";
import CampDetail from "components/camps/CampDetail";

import campsImage from 'images/camps.jpg';


class CampsList extends List {

    pageConfig = {
        title: 'Tábory',
        color: config.colors.color1,
        bgImage: campsImage
    };

    state = {
        selectedItem: null,
        selectedIndex: null
    };

    componentDidMount() {
        this.props.loadCamps();
        this.props.uiChangedAction(this.pageConfig);
    }

    renderCamps() {
        return this.props.camps.map((camp, index) => {
            return <CampItem key={camp.campId} camp={camp} itemIndex={index}
                             selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemSelect} />;
        })
    }

    render() {
        return (
            <React.Fragment>
                <section className="list">
                    <ListHeader filterActive={this.props.filterActive}>
                        <li>
                            <button className="button" onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_CAMPS_CONDITIONS)}>
                                Všeobecné podmínky</button>
                        </li>
                        <li>
                            <button className="button"
                                    onClick={() => this.props.fetchInfoPage(process.env.REACT_APP_WP_CAMPS_HOW_TO)}>
                                Jak se přihlásit?</button>
                        </li>
                    </ListHeader>
                    <div className="list-body" id="list-body">
                        {this.renderCamps()}
                        {this.state.selectedItem != null &&
                        <CampDetail camp={this.state.selectedItem} changePosition={this.positionDetail}
                                      onItemClosed={this.onClosed} />
                        }
                        {this.props.camps.length === 0 && this.props.filterActive &&
                        <EmptyList filterClear={this.props.filterClear}>
                            Je nám líto, ale tyto požadavky nesplňuje žádný tábor
                        </EmptyList>
                        }
                    </div>
                    {this.props.camps.length > 0 &&
                    <ListFooter canLoadMore={this.props.canLoadMore} onClickHandler={this.props.loadMore}
                                itemsPerPage={config.pagination.camps} />
                    }
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {campsStore} = state;
    return {
        camps: campsStore.camps,
        canLoadMore: campsStore.camps.length % config.pagination.camps === 0 && !campsStore.noMoreResults,
        filterActive: Object.values(campsStore.selectedValues).some((item) => !isEmpty(item)),
        isFetching: state.isFetching !== 0
    }
};

export default connect(mapStateToProps,{uiChangedAction, loadCamps, loadMore, filterClear, fetchInfoPage})(CampsList);