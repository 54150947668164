import React from 'react';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {compose} from "redux";
import {setPassword} from 'actions/accountActions';
import * as validations from 'helpers/forms/validations';
import {SetPasswordFields} from "helpers/forms/AccountFileds";

class SignInForm extends React.PureComponent {
    onSubmit = (formProps) => {
        this.props.setPassword(formProps);
    };

    render() {
        const {handleSubmit, submitting} = this.props;
        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <fieldset>
                    <SetPasswordFields/>
                    <button type="submit" className="button" disabled={submitting}>Nastavit heslo</button>
                </fieldset>
            </form>
        );
    }
}

const mapStateToProps = ({account}) => {
    return {
        errorMessage: account.authError
    }
};

export default compose(
    connect(mapStateToProps, {setPassword, validations}),
    reduxForm({form: 'setpassword'})
)(SignInForm);
