import React from 'react';
import {connect} from 'react-redux';

const mapStateToProps = ({isFetching}) => {
    return {
        isFetching: isFetching !== 0
    }
};

export default connect(mapStateToProps)(({isFetching}) => {
    if (!isFetching) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={`spinner-overlay ${isFetching ? 'visible' : ''}`} />
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </React.Fragment>
    );
});