import {compose} from "redux";
import {connect} from "react-redux";
import {participantUpdate, participantCreate} from "actions/accountActions";
import {reduxForm} from "redux-form";
import {ParticipantAccountFields} from "helpers/forms/AccountFileds";
import React from "react";

class ParticipantForm extends React.PureComponent {

    componentDidMount() {
        if(this.props.participant) {
            this.props.initialize(this.props.participant);
        }
    }

    onSubmit = (formData) => {
        if(this.props.participant) {
            this.props.participantUpdate();
        }
        else{
            this.props.participantCreate(formData);
        }
        this.props.closeForm();
    };

    render() {
        const {handleSubmit, submitting, error, participantEditResult} = this.props;
        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <ParticipantAccountFields />
                <button type="submit" className="button" disabled={submitting || error}>Uložit</button>
                {participantEditResult &&
                <p className="complement-color-0">Uloženo</p>
                }
            </form>
        );
    }
}

const mapStateToProps = ({account}) => {
    return {
        participantEditResult: account.participantEditResult,
    }
};

export default compose(
    connect(mapStateToProps, {participantUpdate, participantCreate}),
    reduxForm({
        form: 'participant',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true
    }))(ParticipantForm);