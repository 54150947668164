import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form';

import uiStateReducer from 'reducers/uiStateReducer';
import coursesReducer from 'reducers/coursesReducer';
import campsReducer from 'reducers/campsReducer';
import eventsReducer from "reducers/eventsReducer";
import programsReducer from "reducers/programsReducer";
import competitionsReducer from "reducers/competitionsReducer";
import fetchingReducer from "reducers/fetchingReducer";
import galleriesReducer from 'reducers/galleriesReducer';
import accountReducer from 'reducers/accountReducer';
import {homepageReducer, pagesReducer, infoPageReducer} from 'reducers/postsReducers'

export default combineReducers({
    UIState: uiStateReducer,
    coursesStore: coursesReducer,
    campsStore: campsReducer,
    eventsStore: eventsReducer,
    programsStore: programsReducer,
    competitionsStore: competitionsReducer,
    isFetching: fetchingReducer,
    homepage: homepageReducer,
    pages: pagesReducer,
    galleries: galleriesReducer,
    infoPage: infoPageReducer,
    form: formReducer,
    account: accountReducer
});