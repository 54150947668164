import React from "react";
import {uniqBy} from "lodash";
import ServiceForKids from "entities/ServiceForKids";

class Course extends ServiceForKids{

    course = { performances: []};

    constructor(course) {
        super(course,course.courseId);
        this.course = course;
    }

    renderTime() {
        return this.course.performances.map(({performanceId, day, timeFrom, timeTo}) => {
                return (
                    <React.Fragment key={performanceId}>
                        {day} {timeFrom} - {timeTo} hod<br />
                    </React.Fragment>
                )
            }
        );
    }

    renderTimeAsStrings() {
        return this.course.performances.map(({day, timeFrom, timeTo}) => {
                return `${day} ${timeFrom} - ${timeTo} hod`

            }
        );
    }

    renderBuildings() {
        const all = this.course.performances.map(({buildingName}) => buildingName);

        return uniqBy(all).map((buildingName) => {
            return (
                <React.Fragment key={buildingName}>
                    {buildingName}<br />
                </React.Fragment>
            );
        });
    }

    renderBuildingsAsStrings() {
        const all = this.course.performances.map(({buildingName}) => buildingName);
        return uniqBy(all);
    }

    getPermalink() {
        return super.getPermalink('krouzky');
    }

}

export default Course;