import React from 'react';
import Event from "entities/Event";
import ListDetail, {PersonContact, GalleryItem} from "components/lists/ListDetail";
import DetailWrapper from "components/lists/DetailWrapper";

class EventDetail extends ListDetail {

    static defaultProps = {
        pathPrefix: 'akce'
    };


    onEventClosed = () => {
        this.onClosed(this.props.event.eventId);
    };

    render() {
        const {
            name, price, contactPersons,
            specialization, description, suitableForHTMLEscaped, equipmentHTMLEscaped, infoNotice,
            attachments, photoGalleries, videoGalleries,
        } = this.props.event;
        const event = new Event(this.props.event);

        const wrapperInfo = {
            ref: this.detailElement,
            link: event.getPermalink(this.props.pathPrefix),
            onCloseHandler: this.onEventClosed,
            sticky: this.props.sticky,
            bgColor: 'bg-color'
        };

        return (
            <DetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className="col-md-6 col-lg-5">
                    <h2 className="title d-md-none">{name}
                        {this.props.sticky !== true &&
                        <button title="Zavřít" className="float-right link" onClick={this.onEventClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                        }
                    </h2>
                    <table className="table mb-4 mb-md-0">
                        <tbody>
                        <tr>
                            <th>Termín</th>
                            <td>{event.renderTime()}</td>
                        </tr>
                        <tr>
                            <th>Věk</th>
                            <td>{event.renderAge()}</td>
                        </tr>
                        <tr>
                            <th>Určeno&nbsp;pro</th>
                            <td>{suitableForHTMLEscaped}</td>
                        </tr>
                        {price.length > 0 &&
                        <tr>
                            <th>Cena</th>
                            <td>{price}</td>
                        </tr>
                        }
                        <tr>
                            <th>S sebou</th>
                            <td>{equipmentHTMLEscaped}</td>
                        </tr>
                        <tr>
                            <th>Místo</th>
                            <td>{event.renderBuildings()}</td>
                        </tr>
                        <tr>
                            <th>Kontakt</th>
                            <td>
                                {contactPersons.map((person) => <PersonContact key={person.personId} {...person} emailSubject={`Dotaz k akci: ${name}`} />)}
                            </td>
                        </tr>
                        {infoNotice.length > 0 &&
                        <tr>
                            <th>Poznámka</th>
                            <td dangerouslySetInnerHTML={{__html: infoNotice}} />
                        </tr>}
                        {attachments && attachments.length > 0 &&
                        <tr>
                            <th>Ke stažení</th>
                            <td>
                                <ul>
                                    {attachments.map(({attachmentId, remotePath, fileName}) => <li key={attachmentId}>
                                        <a href={remotePath} rel='noopener noreferrer' target="_blank">{fileName}</a>
                                    </li>)}
                                </ul>
                            </td>
                        </tr>}

                        {photoGalleries.length > 0 &&
                        <tr>
                            <th>Fotogalerie</th>
                            <td>
                                <ul>
                                    {photoGalleries.map((gallery) => <GalleryItem
                                        key={`photoGallery-${gallery.photoGalleryId}`} {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        {videoGalleries.length > 0 &&
                        <tr>
                            <th>Videogalerie</th>
                            <td>
                                <ul>
                                    {videoGalleries.map((gallery) => <GalleryItem
                                        key={`videoGallery-${gallery.videoGalleryId}`} {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-5 col-lg-6">
                    <h2 className="title d-none d-md-block">{name}</h2>
                    {specialization &&
                    <React.Fragment>
                        <h3>Zaměření</h3>
                        <div dangerouslySetInnerHTML={{__html: specialization}} />
                    </React.Fragment>
                    }
                    {description &&
                    <React.Fragment>
                        <h3>Program</h3>
                        <div dangerouslySetInnerHTML={{__html: description}} />
                    </React.Fragment>
                    }
                </div>
            </DetailWrapper>
        );
    }

}

export default EventDetail;