
export const homepageReducer = (state = {news: [], blog: [], activities: []}, {type,payload}) => {

        switch (type){
            case 'NEWS_LOAD':
                return {...state, news: payload};

            case 'BLOG_LOAD':
                return {...state, blog: payload};

            case 'ACTIVITIES_LOAD':
                return {...state, activities: payload};

            default:
                return state;
        }

};

export const pagesReducer = (state = {parentPage: {}, subpages: []}, {type, payload}) => {

    switch (type) {
        case 'PAGE_LOAD':
            return {...state, parentPage: payload};

        case 'SUBPAGES_LOAD':
            return {...state, subpages: payload};

        default:
            return state;
    }

};

export const infoPageReducer = (state = null, {type, payload}) => {

    switch (type) {
        case 'INFO_PAGE_LOAD':
            return {...payload};

        case 'INFO_PAGE_CLOSE':
            return null;

        default:
            return state;
    }
};
