
const INITIAL_STATE = {
    camps: [],
    camp: null,
    selectedValues: {},
    noMoreResults: false,
    allValues: {},
    loadOffset: 0
};

export default (campsStore = INITIAL_STATE, {type, payload}) => {
    let {selectedValues} = campsStore;

    switch(type){
        case 'CAMPS_LOAD':
            return {...campsStore, camps: payload};

        case 'CAMP_LOAD':
            return {...campsStore, camp: payload};

        case 'CAMPS_LOAD_MORE':
            return {
                ...campsStore,
                loadOffset: campsStore.camps.length + payload.length,
                noMoreResults: payload.length === 0,
                camps: [...campsStore.camps, ...payload]
            };

        case 'CAMPS_FILTER_SELECT':
            selectedValues[payload.key] = [...campsStore.selectedValues[payload.key] || [], payload.value];
            return {
                ...campsStore,
                loadOffset: 0,
                selectedValues: {...campsStore.selectedValues, ...selectedValues}
            };

        case 'CAMPS_FILTER_UNSELECT':
            const index = campsStore.selectedValues[payload.key].indexOf(payload.value);
            selectedValues[payload.key] = [
                ...campsStore.selectedValues[payload.key].slice(0, index),
                ...campsStore.selectedValues[payload.key].slice(index + 1)
            ];
            return {
                ...campsStore,
                loadOffset: 0,
                selectedValues: {...campsStore.selectedValues, ...selectedValues}
            };

        case 'CAMPS_FILTER_CLEAR':
            return {
                ...campsStore,
                loadOffset: 0,
                selectedValues: {}
            };

        case 'CAMPS_INIT_AGES':
            return {
                ...campsStore,
                allValues: {...campsStore.allValues, ages: payload},
            };

        case 'CAMPS_INIT_MONTHS':
            return {
                ...campsStore,
                allValues: {...campsStore.allValues, months: payload},
            };

        case 'CAMPS_INIT_CATEGORIES':
            return {
                ...campsStore,
                allValues: {...campsStore.allValues, categories: payload},
            };

        default:
            return campsStore;
    }
}