import React from 'react';
import List from "components/lists/List";
import {connect} from "react-redux";
import {isEmpty} from 'lodash';

import {titleChangedAction, uiChangedAction} from "actions/uiStateActions";
import {fetchPage} from 'actions/pagesActions';
import PostItem from "components/posts/PostItem";
import PostDetail from "components/posts/PostDetail";
import closeSubpages from "helpers/closeSubpage";
import customHistory from 'customHistory';

class PagesList extends List {

    state = {
        selectedItem: null,
        selectedIndex: null
    };

    fetchPage(){
        this.props.fetchPage(this.props.match.params.slug);
    }

    onItemClick = (selectedItem, selectedIndex, itemId) => {
        this.onItemSelect(selectedItem, selectedIndex, itemId);
        if(this.state.selectedIndex === selectedIndex){
            closeSubpages(selectedItem.path);
        }
    };

    componentDidMount() {
        this.props.titleChangedAction('...');
        this.fetchPage();
    }

    componentDidUpdate({match: prevMatch}) {
        if(this.props.location.search === '?vytisknout' && this.state.selectedItem != null){
            customHistory.replace(this.props.location.pathname);
            window.print();
        }

        if(prevMatch.params.slug !== this.props.match.params.slug || prevMatch.params.subpage !== this.props.match.params.subpage){
            this.fetchPage();
            this.setState({selectedItem:null, selectedIndex:null});
            return;
        }

        const {parentPage, selectedItem, selectedIndex} = this.props;

        if (!isEmpty(selectedItem) && this.state.selectedItem == null) {
            this.onItemSelect(selectedItem,selectedIndex);
        }

        if (!isEmpty(parentPage)) {
            this.props.uiChangedAction({
                title: parentPage.title,
                color: parentPage.color.code,
                bgImage: parentPage.featuredImage ? parentPage.featuredImage.full : null
            });
        }
    }

    renderPages() {
        return this.props.subpages.map((page, index) => {
            return <PostItem key={page.id} post={page} itemIndex={index}
                               selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemClick} />;
        })
    }

    render() {
        return (
            <React.Fragment>
                <section className={`list page-list ${this.state.selectedItem != null ? 'page-open' : ''}`}>
                    <div className="list-body" id="list-body">
                        {this.props.subpages.length > 0 && this.renderPages()}
                        {this.state.selectedItem != null &&
                        <PostDetail post={this.state.selectedItem} parentPage={this.props.parentPage} changePosition={this.positionDetail}
                                      onItemClosed={this.onClosed} />
                        }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state,ownProps) => {
    const {pages: {parentPage}, pages: {subpages}} = state;

    const subpageSlug = ownProps.match.params.subpage;
    let selectedItem, selectedIndex = null;
    if(subpageSlug){
        selectedItem = subpages.find(page => page.slug === subpageSlug);
        selectedIndex = subpages.findIndex(page => page.slug === subpageSlug);
    }

    return {
        parentPage,
        subpages,
        isFetching: state.isFetching !== 0,
        selectedItem,
        selectedIndex: selectedIndex >= 0 ? selectedIndex : null
    }
};

export default connect(mapStateToProps, {uiChangedAction, titleChangedAction,fetchPage})(PagesList);