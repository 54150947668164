import {compose} from "redux";
import {connect} from "react-redux";
import {parentCreate, parentUpdate} from "actions/accountActions";
import {reduxForm} from "redux-form";
import {ParentAccountFields} from "helpers/forms/AccountFileds";
import React from "react";

class ParentForm extends React.PureComponent {

    componentDidMount() {
        if(this.props.parent) {
            this.props.initialize(this.props.parent);
        }
    }

    onSubmit = (formData) => {
        if(this.props.parent) {
            this.props.parentUpdate();
        }
        else{
            this.props.parentCreate(formData);
        }
        this.props.closeForm();
    };

    render() {
        const {handleSubmit, submitting, error, parentEditResult} = this.props;
        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <ParentAccountFields />
                <button type="submit" className="button" disabled={submitting || error}>Uložit</button>
                {parentEditResult &&
                <p className="complement-color-0">Uloženo</p>
                }
            </form>
        );
    }
}

const mapStateToProps = ({account}) => {
    return {
        parentEditResult: account.parentEditResult,
    }
};

export default compose(
    connect(mapStateToProps, {parentUpdate, parentCreate}),
    reduxForm({
        form: 'parent',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true
    }))(ParentForm);