import React from 'react';
import Camp from 'entities/Camp';
import ListDetail, {GalleryItem, PersonContact} from "components/lists/ListDetail";
import DetailWrapper from "components/lists/DetailWrapper";

//todo test showing attachments
class CampDetail extends ListDetail {

    onCampClosed = () => {
        this.onClosed(this.props.camp.campId);
    };

    render() {
        const {
            name, time, place, identificationNumber, freeSpots, category,
            price, priceWithoutDonation, priceNote, priceWithoutDonationNote, priceContains,
            contact, responsiblePerson,
            infoNotice, attachments, photoGalleries, videoGalleries, specialization, publicDescription


        } = this.props.camp;
        const camp = new Camp(this.props.camp);

        const wrapperInfo = {
            ref: this.detailElement,
            link: camp.getPermalink(),
            onCloseHandler: this.onCampClosed,
            sticky: this.props.sticky,
            bgColor: 'bg-color'
        };

        return (
            <DetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className="col-md-6 col-lg-5">
                    <h2 className="title d-md-none">{name}
                        {this.props.sticky !== true &&
                        <button title="Zavřít" className="float-right link" onClick={this.onCampClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                        }
                    </h2>
                    <table className="table mb-4 mb-md-0">
                        <tbody>
                        <tr>
                            <th>Termín</th>
                            <td>{camp.renderDate()}</td>
                        </tr>
                        {time.length >0 &&
                        <tr>
                            <th>Čas</th>
                            <td>{time}</td>
                        </tr>
                        }
                        <tr>
                            <th>Věk</th>
                            <td>{camp.renderAge()}</td>
                        </tr>
                        <tr>
                            <th rowSpan={priceWithoutDonation ? 2 : 1}>Cena</th>
                            <td>{price}
                                {priceNote &&
                                <small>&nbsp;{priceNote}</small>}
                                <small><br />Cena pro účastníky s trvalým bydlištěm na území hl. m. Prahy</small>
                            </td>
                        </tr>
                        {priceWithoutDonation &&
                        <tr>
                            <td>{priceWithoutDonation}
                                {priceWithoutDonationNote &&
                                <small>&nbsp;{priceWithoutDonationNote}</small>}
                                <small><br />Cena pro účastníky, kteří nemají bydliště na území hl. m. Prahy</small>
                            </td>
                        </tr>
                        }

                        <tr>
                            <th>Cena zahrnuje</th>
                            <td>{priceContains}</td>
                        </tr>

                        <tr>
                            <th>Místo</th>
                            <td>{place}</td>
                        </tr>

                        {infoNotice.length > 0 &&
                        <tr>
                            <th>Poznámka</th>
                            <td dangerouslySetInnerHTML={{__html: infoNotice}} />
                        </tr>
                        }

                        <tr>
                            <th>Kontakt</th>
                            <td>
                                <PersonContact {...contact} key={contact.personId}
                                               emailSubject={`Dotaz k táboru: ${identificationNumber} ${name}`} />
                            </td>
                        </tr>

                        <tr>
                            <th>Hlavní vedoucí</th>
                            <td>
                                <PersonContact {...responsiblePerson} key={responsiblePerson.personId}
                                               emailSubject={`Dotaz k táboru: ${identificationNumber} ${name}`} />
                            </td>
                        </tr>

                        <tr>
                            <th>Volná místa</th>
                            <td>{freeSpots}</td>
                        </tr>

                        {attachments && attachments.length > 0 &&
                        <tr>
                            <th>Ke stažení</th>
                            <td>
                                <ul>
                                    {attachments.map(({attachmentId, remotePath, fileName}) => <li key={attachmentId}>
                                        <a href={remotePath} rel='noopener noreferrer' target="_blank">{fileName}</a>
                                    </li>)}
                                </ul>
                            </td>
                        </tr>}

                        <tr>
                            <th>Variabilní symbol</th>
                            <td>{identificationNumber}</td>
                        </tr>

                        <tr>
                            <th>Typ</th>
                            <td>{category}</td>
                        </tr>

                        {photoGalleries.length > 0 &&
                        <tr>
                            <th>Fotogalerie</th>
                            <td>
                                <ul>
                                    {photoGalleries.map((gallery) => <GalleryItem
                                        key={`photoGallery-${gallery.photoGalleryId}`} {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        {videoGalleries.length > 0 &&
                        <tr>
                            <th>Videogalerie</th>
                            <td>
                                <ul>
                                    {videoGalleries.map((gallery) => <GalleryItem
                                        key={`videoGallery-${gallery.videoGalleryId}`} {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-5 col-lg-6">
                    <h2 className="title d-none d-md-block">{name}</h2>
                    {specialization &&
                    <React.Fragment>
                        <h3>Zaměření</h3>
                        <div dangerouslySetInnerHTML={{__html: specialization}} />
                    </React.Fragment>
                    }
                    {publicDescription &&
                    <React.Fragment>
                        <h3>Pogram</h3>
                        <div dangerouslySetInnerHTML={{__html: publicDescription}} />
                    </React.Fragment>
                    }
                </div>
            </DetailWrapper>
        );
    }

}

export default CampDetail;