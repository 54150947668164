import axios from 'axios';

const axiosSetting = {
    baseURL: process.env.REACT_APP_API_URL
};
//todo nefunguje po nasazení a nevím proč :-(

// if (process.env.NODE_ENV === 'production') {
//     axiosSetting.auth = {
//         username: 'ddmjm',
//         password: 'nový web'
//     }
// }

// todo logging
const ddmbaseAPI = axios.create(axiosSetting);

export const convertFilterKeyNames = (filter) => {
    const filledFilter = {};
    for (const key in filter) {
        filledFilter[`filled${key.charAt(0).toUpperCase()}${key.slice(1)}`] = filter[key].join(';');
    }
    return filledFilter;
};

export const initAges = (minAge) => {
    const ages = [];
    for (let i = minAge; i <= 25; i++) {
        ages.push({id: i, value: i});
    }
    ages.push({id: 26, value: '26 a více'});
    return ages;
};

export const uploadApplicationForm = (type, participationId, file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return ddmbaseAPI.post(`/upload/${participationId}`, data, {
        params: {type},
        headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        }
    }).catch((response) => console.error(response, "API ERROR: /upload/:participationId"));
};

export default ddmbaseAPI;