import React from 'react';
import List, {ListHeader} from "components/lists/List";
import {connect} from "react-redux";

import {uiChangedAction} from "actions/uiStateActions";

import {loadProgram} from "actions/programsActions";
import {Link, Redirect} from "react-router-dom";
import ProgramDetail from "components/programs/ProgramDetail";
import customHistory from "customHistory";
import Helmet from "react-helmet";
import competitionsImage from "images/competitions.jpg";
import Program from "entities/Program";
import config from 'config';

class ProgramsDetailPage extends List {

    pageConfig = {
        title: 'Programy',
        color: config.colors.color5,
        bgImage: competitionsImage

    };

    componentDidMount() {
        this.props.loadProgram(this.props.match.params.id);
        this.props.uiChangedAction(this.pageConfig);
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.program && this.props.history.location.search === '?vytisknout') {
            customHistory.replace(this.props.location.pathname);
            window.print();
        }
    }

    renderHelmet(program) {
        return (<Helmet>
            <meta name="description"
                  content={`Program pro školy ${this.props.program.name}. Zaměření: ${this.props.program.specialization}`} />
            <meta property='og:title' content={this.props.program.name} />
            <meta property='og:image' content={this.props.program.smallImageUrl} />
            <link rel='canonical' href={`${process.env.REACT_APP_BASE_URL}${program.getPermalink()}`} />
        </Helmet>);
    }

    render() {
        if (!this.props.program) {
            return null;
        }

        const program = new Program(this.props.program);
        const permalink = program.getPermalink();
        if (customHistory.location.pathname !== permalink) {
            return (<Redirect push to={permalink} />);
        }

        return (
            <React.Fragment>
                {this.renderHelmet(program)}
                <section className="list">
                    <ListHeader withoutFilter />
                    <div className="list-body" id="list-body">
                        {this.props.program != null &&
                        <ProgramDetail program={this.props.program} sticky />
                        }
                    </div>
                    <footer className="list-footer">
                        <nav className="list-actions">
                            <ul>
                                <li>
                                    <Link to='/skoly/programy' className='button'>Zobrazit všechny programy</Link>
                                </li>
                            </ul>
                        </nav>
                    </footer>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({programsStore}) => {
    return {
        program: programsStore.program,
    }
};

export default connect(mapStateToProps, {uiChangedAction, loadProgram})(ProgramsDetailPage);