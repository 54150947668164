import ddmbase from "apis/ddmbase";
export default{

    getPrograms(){
        return ddmbase.get('/programs', {}).catch((response) => console.error(response, "API ERROR: /programs"))
    },

    getProgram(programId) {
        return ddmbase.get(`/programs/${programId}`).catch((response) => console.error(response, "API ERROR: /programs/:id"))
    }
}