

export default (programsStore = {programs: [], program: null}, {type, payload}) => {

    switch(type){
        case 'PROGRAMS_LOAD':
            return {...programsStore, programs: payload};

        case 'PROGRAM_LOAD':
            return {...programsStore, program: payload};

        default:
            return programsStore;
    }
}