import React from 'react';
import List, {ListHeader} from "components/lists/List";
import {connect} from "react-redux";
import Helmet from 'react-helmet';

import {uiChangedAction} from "actions/uiStateActions";

import {loadCourse} from "actions/coursesActions";
import {Link, Redirect} from "react-router-dom";
import CourseDetail from "components/courses/CourseDetail";
import customHistory from "customHistory";
import Course from "entities/Course";
import coursesImage from "images/courses.jpg";
import config from 'config';

class CoursesDetailPage extends List {

    pageConfig = {
        title: 'Kroužky',
        color: config.colors.color0,
        bgImage: coursesImage

    };

    componentDidMount() {
        this.props.loadCourse(this.props.match.params.id);
        this.props.uiChangedAction(this.pageConfig);
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.course && this.props.history.location.search === '?vytisknout') {
            customHistory.replace(this.props.location.pathname);
            window.print();
        }
    }

    renderHelmet(course) {
        return (<Helmet>
            <meta name="description"
                  content={`Kroužek ${this.props.course.name} pro děti ve věku ${course.renderAge()}, ${course.renderTimeAsStrings().join(", ")} v učebně ${course.renderBuildingsAsStrings().join(", ")}`} />
            <meta property='og:title' content={this.props.course.name} />
            <meta property='og:image' content={this.props.course.smallImageUrl} />
            <link rel='canonical' href={`${process.env.REACT_APP_BASE_URL}${course.getPermalink()}`} />
        </Helmet>);
    }

    //todo vyclenit paticku do vlastni komponenty
    render() {
        if (!this.props.course) {
            return null;
        }

        const course = new Course(this.props.course);
        const permalink = course.getPermalink();
        if (customHistory.location.pathname !== permalink) {
            return (<Redirect to={permalink} />);
        }

        return (
            <React.Fragment>
                {this.renderHelmet(course)}
                <section className="list">
                    <ListHeader withoutFilter />
                    <div className="list-body" id="list-body">
                        {this.props.course != null &&
                        <CourseDetail course={this.props.course} sticky />
                        }
                    </div>
                    <footer className="list-footer">
                        <nav className="list-actions">
                            <ul>
                                <li>
                                    <Link to='/krouzky' className='button'>Zobrazit všechny kroužky</Link>
                                </li>
                            </ul>
                        </nav>
                    </footer>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({coursesStore}) => {
    return {
        course: coursesStore.course,
    }
};

export default connect(mapStateToProps, {uiChangedAction, loadCourse})(CoursesDetailPage);