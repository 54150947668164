import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {menuOpenAction, filterOpenAction} from 'actions/uiStateActions'
import config from 'config';

const mapStateToProps = ({UIState}) => {
    return {
        filterOpen: UIState.filterOpen
    }
};

export default connect(mapStateToProps, {menuOpenAction, filterOpenAction})((props) => {
    return (
        <header className="header">
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className="col order-1">
                        <h1 className="title bold-uppercased">
                            {props.title}
                        </h1>
                    </div>
                    <div className="logo col-2 order-0">
                        <Link to="/" className="link" title="Úvodní stránka">
                            <span className="sr-only">Úvodní stránka</span>
                        </Link>
                        <span className="print-only">{config.defaultTitle}</span>
                        <span className="motto">Skládáme Váš volný čas</span>
                    </div>
                    <nav className="col col-2 order-2 nav-icons">
                        <Link to="/kontakt">
                            <i className="fas fa-envelope" /><span className="sr-only">Kontakty</span>
                        </Link>
                        <button onClick={e => {
                            e.preventDefault();
                            if(props.filterOpen) {
                                props.filterOpenAction(false);
                            }
                            props.menuOpenAction(true);
                        }}>
                            <i className="fas fa-bars" /><span className="sr-only">Menu</span>
                        </button>
                    </nav>
                </div>
            </div>
        </header>
    );
});