import React from 'react';
import {connect} from 'react-redux';
import ListItem from "components/lists/ListItem";
import image from 'images/list-placeholder.png';
import ListDetail from "components/lists/ListDetail";
import {AccountDetailWrapper} from "components/lists/DetailWrapper";
import ParentForm from "components/account/forms/ParentForm";
import {parentRemove} from 'actions/accountActions';

export class ParentsAccountItem extends ListItem {
    render() {
        return (
            <article className={`list-item link bg-color6 ${this.props.selected ? 'detail-opened' : ''}`}
                     id={`account-item-${this.props.itemIndex}`}
                     onClick={(e) => {
                         e.preventDefault();
                         this.onClickHandler(null, this.props.itemIndex)
                     }}>
                <div className="content">
                    <header>
                        <h3 className="headline">Zákonní zástupci</h3>
                    </header>
                    <div className="description">
                        Správa rodičů / zákonných zástupců
                    </div>
                </div>
                <div className="image-wrap bg-color6">
                    <img src={image} className="image" alt="Zákonní zástupci" />
                </div>
            </article>
        );
    }
}

class ParentsAccountDetailNotConnected extends ListDetail {


    constructor(props) {
        super(props);

        this.state = {
            formOpened: false,
            parentToEdit: null
        }
    }

    openFormHandler = (parentToEdit) => {
        this.setState({formOpened: true, parentToEdit})
    };

    onDetailClosed = () => {
        this.onClosed(this.props.itemIndex);
    };

    onFormSent = () => {
        this.setState({formOpened: false});
        this.changePosition()
    };

    renderForm() {
        return <ParentForm closeForm={this.onFormSent} parent={this.state.parentToEdit} />
    }


    tableEditActions = (parent) => {
        return (
            <React.Fragment>
                <button onClick={() => this.openFormHandler(parent)} className="link-decorated"
                        title="Editovat zákonného zástupce">
                    <i className="fas fa-user-edit" />
                </button>
                <button onClick={() => this.props.parentRemove(parent.parentId)}
                        className="link-decorated"
                        title="Smazat zákonného zástupce">
                    <i className="fas fa-user-minus" />
                </button>
            </React.Fragment>
        )
    };

    render() {
        const wrapperInfo = {
            ref: this.detailElement,
            onCloseHandler: (this.onDetailClosed),
            bgColor: 'color6'
        };


        return (
            <AccountDetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className='col'>
                    <h2 className="title">Zákonní zástupci / Rodiče
                        <button title="Zavřít" className="float-right link d-md-none"
                                onClick={this.onDetailClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                    </h2>
                    {this.state.formOpened &&
                    this.renderForm()
                    }
                    {!this.state.formOpened &&
                    <ParentsTable parents={this.props.user.parents}
                                  Actions={this.tableEditActions}>
                        <p className="text-center">
                            <button onClick={() => this.openFormHandler(null)} className="button">
                                Přidat zákonného zástupce
                            </button>
                        </p>
                    </ParentsTable>
                    }
                </div>
            </AccountDetailWrapper>
        );
    }
}

export const ParentsAccountDetail = connect(null, {parentRemove})(ParentsAccountDetailNotConnected);

export const ParentsTable = (props) => {

    const translateParentType = type => {
        const mapping = {
            mother: "Matka",
            father: "Otec",
            other: "Zákonný zástupce",
        };
        return mapping[type];
    };

    const renderParents = (parents) => {
        return parents.map(parent => {
            return (
                <tr key={parent.parentId}>
                    <td>{parent.name}<br />
                    <small>{translateParentType(parent.parentType)}</small>
                    </td>
                    <td className="d-none d-md-table-cell">{parent.email}</td>
                    <td className="d-none d-md-table-cell">{parent.phone}</td>
                    <td className="text-center">
                        {props.Actions(parent)}
                    </td>
                </tr>
            );
        });
    };

    const {parents} = props;
    return (
        <div className="table-wrapper">
            {parents.length > 0 &&
            <table className="table">
                <thead>
                <tr>
                    <th>Jméno</th>
                    <th>E-mail</th>
                    <th>Telefon</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {renderParents(parents)}
                </tbody>
            </table>
            }
            {parents.length === 0 &&
            <p className="alert">
                Nemáte žádné zákonné zástupce. Nejprve nějakého vytvořte.
            </p>
            }
            {props.children}
        </div>
    )
};
