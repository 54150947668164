import React from 'react';
import ListDetail, {PersonContact, GalleryItem} from "components/lists/ListDetail";
import DetailWrapper from "components/lists/DetailWrapper";
import Program from "entities/Program";

class ProgramDetail extends ListDetail {

    onEventClosed = () => {
        this.onClosed(this.props.program.programId);
    };

    render() {
        const {
            name, term, duration, grade,price, responsiblePerson,
            specialization, equipment, place, infoNotice,
            description, target, schoolRelated, keyAbilities, output,
            attachments, photoGalleries, videoGalleries
        } = this.props.program;
        const program = new Program(this.props.program);

        const wrapperInfo = {
            ref: this.detailElement,
            link: program.getPermalink(),
            onCloseHandler: this.onEventClosed,
            sticky: this.props.sticky,
            bgColor: 'bg-color'
        };

        return (
            <DetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className="col-md-6 col-lg-5">
                    <h2 className="title d-md-none">{name}
                        {this.props.sticky !== true &&
                        <button title="Zavřít" className="float-right link" onClick={this.onEventClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                        }
                    </h2>
                    <table className="table mb-4 mb-md-0">
                        <tbody>
                        <tr>
                            <th>Termín</th>
                            <td>{term}</td>
                        </tr>
                        <tr>
                            <th>Délka program</th>
                            <td>{duration}</td>
                        </tr>
                        {grade &&
                        <tr>
                            <th>Třída</th>
                            <td>{grade}. třída</td>
                        </tr>
                        }
                        <tr>
                            <th>Zaměření</th>
                            <td>{specialization}</td>
                        </tr>
                        <tr>
                            <th>Cena</th>
                            <td>{price}</td>
                        </tr>
                        <tr>
                            <th>S sebou</th>
                            <td>{equipment}</td>
                        </tr>
                        <tr>
                            <th>Místo</th>
                            <td>{place}</td>
                        </tr>
                        <tr>
                            <th>Kontakt</th>
                            <td>
                                <PersonContact {...responsiblePerson} key={responsiblePerson.personId}
                                               emailSubject={`Dotaz k programu: ${name}`} />
                            </td>
                        </tr>
                        {infoNotice.length > 0 &&
                        <tr>
                            <th>Poznámka</th>
                            <td dangerouslySetInnerHTML={{__html: infoNotice}} />
                        </tr>}
                        {attachments && attachments.length > 0 &&
                        <tr>
                            <th>Ke stažení</th>
                            <td>
                                <ul>
                                    {attachments.map(({attachmentId, remotePath, fileName}) => <li key={attachmentId}>
                                        <a href={remotePath} rel='noopener noreferrer' target="_blank">{fileName}</a>
                                    </li>)}
                                </ul>
                            </td>
                        </tr>}

                        {photoGalleries.length > 0 &&
                        <tr>
                            <th>Fotogalerie</th>
                            <td>
                                <ul>
                                    {photoGalleries.map((gallery) => <GalleryItem
                                        key={`photoGallery-${gallery.photoGalleryId}`} {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        {videoGalleries.length > 0 &&
                        <tr>
                            <th>Videogalerie</th>
                            <td>
                                <ul>
                                    {videoGalleries.map((gallery) => <GalleryItem
                                        key={`videoGallery-${gallery.videoGalleryId}`} {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-5 col-lg-6">
                    <h2 className="title d-none d-md-block">{name}</h2>
                    {description &&
                    <React.Fragment>
                        <h3>Popis a metody práce</h3>
                        <div dangerouslySetInnerHTML={{__html: description}} />
                    </React.Fragment>
                    }
                    {target &&
                    <React.Fragment>
                        <h3>Výchovné a vzdělávací cíle</h3>
                        <div dangerouslySetInnerHTML={{__html: target}} />
                    </React.Fragment>
                    }
                    {schoolRelated &&
                    <React.Fragment>
                        <h3>Návaznost na učivo ve škole</h3>
                        <div dangerouslySetInnerHTML={{__html: schoolRelated}} />
                    </React.Fragment>
                    }
                    {keyAbilities &&
                    <React.Fragment>
                        <h3>Klíčové kompetence</h3>
                        <div dangerouslySetInnerHTML={{__html: keyAbilities}} />
                    </React.Fragment>
                    }
                    {output &&
                    <React.Fragment>
                        <h3>Výstupy</h3>
                        <div dangerouslySetInnerHTML={{__html: output}} />
                    </React.Fragment>
                    }
                </div>
            </DetailWrapper>
        );
    }

}

export default ProgramDetail;