import React from 'react';
import CarouselBody from 'components/CarouselBody';
import {Link} from "react-router-dom";

class Carousel extends React.Component{

    render() {
        return (
            <section className="list">
                <header className="list-header">
                    <h2 className="list-title bg-color">
                        {this.props.link &&
                        <Link to={this.props.link}>{this.props.headline}</Link>
                        }
                        {!this.props.link &&
                            this.props.headline
                        }
                    </h2>
                </header>
                <CarouselBody>
                    {this.props.children}
                </CarouselBody>
            </section>
        );
    }
}

export default Carousel;