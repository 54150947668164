import React from 'react';
import Course from "entities/Course";
import ListDetail, {GalleryItem, PersonContact} from "components/lists/ListDetail";
import DetailWrapper from "components/lists/DetailWrapper";
import SubscribeModal from "components/layout/SubscribeModal";

class CourseDetail extends ListDetail {


    constructor(props) {
        super(props);
        this.state = {subscribe: false}
    }

    onCourseClosed = () => {
        this.onClosed(this.props.course.courseId);
    };

    toggleSubscribeDialog = (show) => {
        this.setState({subscribe: show});
        if(this.props.toggleModalOpen) {
            this.props.toggleModalOpen(show);
        }
    };

    renderSubscribeButton(){
        const {subscribeOnline, awaitingSpots} = this.props.course;

        if(!subscribeOnline){
            return <em>nelze se přihlásit on-line</em>
        }

        if(awaitingSpots === 0){
            return <button className="link-decorated" onClick={() => this.toggleSubscribeDialog(true)}>zapsat účastníka</button>
        }
        if(awaitingSpots > 0){
            return (
                <React.Fragment>
                    <button className="link-decorated"
                            onClick={() => this.toggleSubscribeDialog(true)}>zapsat náhradníka
                    </button>
                    <em>(náhradníků: {awaitingSpots})</em>
                </React.Fragment>
            )
        }
    }

    render() {
        const {
            name, lectureDuration, level, individuals, freeSpots, awaitingSpots, subscribeOnline, identificationNumber,
            priceFirstTerm, priceSecondTerm, price, priceFirstTermNote, priceSecondTermNote, priceNote,
            priceAdultFirstTerm, priceAdultSecondTerm,
            contactPerson, lecturer, attachments, photoGalleries, videoGalleries,
            neededKnowledge, neededEquipment, infoNotice, publicDescriptionEvaluation, publicDescriptionGoals, publicDescriptionContent
        } = this.props.course;
        const course = new Course(this.props.course);

        const wrapperInfo = {
            ref: this.detailElement,
            link: course.getPermalink(),
            onCloseHandler: this.onCourseClosed,
            sticky: this.props.sticky,
            bgColor: 'bg-color',
            canSubscribe: subscribeOnline,
            showSubscribeDialog: () => this.toggleSubscribeDialog(true)
        };

        return (
            <DetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className="col-md-6 col-lg-5">
                    <h2 className="title d-md-none">{name}
                        {this.props.sticky !== true &&
                        <button title="Zavřít" className="float-right link" onClick={this.onCourseClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                        }
                    </h2>
                    <table className="table mb-4 mb-md-0">
                        <tbody>
                        <tr>
                            <th colSpan="2">Den a hodina</th>
                            <td>{course.renderTime()}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Délka lekce</th>
                            <td>{lectureDuration}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Věk</th>
                            <td>{course.renderAge()}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Pokročilost</th>
                            <td>{level}</td>
                        </tr>
                        <tr>
                            <th rowSpan={price.length > 0 ? 3 : 2}>Cena</th>
                            <td>1.&nbsp;pololetí</td>
                            <td>{priceFirstTerm}
                                {priceFirstTermNote &&
                                <small>&nbsp;{priceFirstTermNote}</small>}
                                {priceAdultFirstTerm &&
                                <>
                                    <br />
                                    {priceAdultFirstTerm} <small>dospělí</small>
                                </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>2.&nbsp;pololetí</td>
                            <td>{priceSecondTerm}
                                {priceSecondTermNote &&
                                <small>&nbsp;{priceSecondTermNote}</small>}
                                {priceAdultSecondTerm &&
                                <>
                                    <br />
                                    {priceAdultSecondTerm} <small>dospělí</small>
                                </>
                                }
                            </td>
                        </tr>
                        {price.length > 0 &&
                        <tr>
                            <td>školní rok</td>
                            <td>{price}
                                {priceNote.length > 0 &&
                                <small>&nbsp;{priceNote}</small>}
                            </td>
                        </tr>
                        }
                        <tr>
                            <th colSpan="2">Výuka</th>
                            <td>{individuals ? 'Individuální' : 'Skupinová'}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Místo</th>
                            <td>{course.renderBuildings()}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Kontakt</th>
                            <td>
                                <PersonContact {...contactPerson} key={contactPerson.personId}
                                               emailSubject={`Dotaz ke kroužku: ${identificationNumber} ${name}`} />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="2">Lektor</th>
                            <td>
                                {lecturer.fullName}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="2">Volná místa</th>
                            <td>{awaitingSpots > 0 ? 0 : freeSpots} {this.renderSubscribeButton()}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="2">Nutné znalosti</th>
                            <td dangerouslySetInnerHTML={{__html: neededKnowledge}} />
                        </tr>
                        <tr>
                            <th colSpan="2">Nutné vybavení</th>
                            <td dangerouslySetInnerHTML={{__html: neededEquipment}} />
                        </tr>
                        {infoNotice.length > 0 &&
                        <tr>
                            <th colSpan="2">Poznámka</th>
                            <td dangerouslySetInnerHTML={{__html: infoNotice}} />
                        </tr>}
                        {attachments && attachments.length > 0 &&
                        <tr>
                            <th colSpan="2">Ke stažení</th>
                            <td>
                                <ul>
                                    {attachments.map(({attachmentId, remotePath, fileName}) => <li key={attachmentId}>
                                        <a href={remotePath} rel='noopener noreferrer' target="_blank">{fileName}</a>
                                    </li>)}
                                </ul>
                            </td>
                        </tr>}

                        <tr>
                            <th colSpan="2">Variabilní symbol</th>
                            <td>{identificationNumber}</td>
                        </tr>

                        {photoGalleries.length > 0 &&
                        <tr>
                            <th colSpan="2">Fotogalerie</th>
                            <td>
                                <ul>
                                    {photoGalleries.map((gallery) => <GalleryItem
                                        key={`photoGallery-${gallery.photoGalleryId}`}  {...gallery} />)}
                                </ul>
                            </td>
                        </tr>}
                        {videoGalleries.length > 0 &&
                        <tr>
                            <th colSpan="2">Videogalerie</th>
                            <td>
                                <ul>
                                    {videoGalleries.map((gallery) => <GalleryItem
                                        key={`videoGallery-${gallery.videoGalleryId}`} {...gallery} />)
                                    }
                                </ul>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-5 col-lg-6">
                    <h2 className="title d-none d-md-block">{name}</h2>
                    <h3>Výchovně-vzdělávací cíle</h3>
                    <div dangerouslySetInnerHTML={{__html: publicDescriptionGoals}} />
                    <h3>Obsah</h3>
                    <div dangerouslySetInnerHTML={{__html: publicDescriptionContent}} />
                    <h3>Výstup z kroužku</h3>
                    <div dangerouslySetInnerHTML={{__html: publicDescriptionEvaluation}} />
                </div>

                {this.state.subscribe &&
                <SubscribeModal closeModal={() => this.toggleSubscribeDialog(false)}
                                course={this.props.course} />
                }
            </DetailWrapper>
        );
    }

}

export default CourseDetail;
