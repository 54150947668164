import React from 'react';
import {connect} from 'react-redux';

import ListItem from "components/lists/ListItem";
import image from 'images/list-placeholder.png';
import ListDetail from "components/lists/ListDetail";
import {AccountDetailWrapper} from "components/lists/DetailWrapper";
import Course from "entities/Course";
import Payment from 'components/account/Payment';
import {unsubscribe} from "actions/coursesActions";
import {Link} from "react-router-dom";
import CourseApplicationForm from 'components/pdfs/CourseApplicationForm';
import {PDFDownloadLink} from "@react-pdf/renderer";
import slugify from "slugify";
import UploadFileModal from "components/common/UploadFileModal";

export class CoursesAccountItem extends ListItem {
    render() {
        return (
            <article className={`list-item link bg-color0 ${this.props.selected ? 'detail-opened' : ''}`}
                     id={`account-item-${this.props.itemIndex}`}
                     onClick={(e) => {
                         e.preventDefault();
                         this.onClickHandler(null, this.props.itemIndex)
                     }}>
                <div className="content">
                    <header>
                        <h3 className="headline">Kroužky</h3>
                    </header>
                    <div className="description">
                        Správa přihlášení na kroužky
                    </div>
                </div>
                <div className="image-wrap bg-color0">
                    <img src={image} className="image" alt="Kroužky" />
                </div>
            </article>
        );
    }
}

export class CoursesAccountDetail extends ListDetail {

    constructor(props) {
        super(props);
        this.state = {
            applicationForms: [],
            uploadForm: null
        }
    }

    onDetailClosed = () => {
        this.onClosed(this.props.itemIndex);
    };

    renderDownloadButton(participant, participation) {
        if (!participation.variableNumber) {
            return null;
        }

        const {courseParticipationId} = participation;
        if (!this.state.applicationForms.some( id => id === courseParticipationId)) {
            return (<button className="button transparent"
                            onClick={() => this.setState({applicationForms: [...this.state.applicationForms, courseParticipationId]})}>generovat</button>)
        }

        //else
        const {course} = participation;
        const filename = `${participation.variableNumber}-${slugify(course.name.toLowerCase())}.pdf`;
        return (
            <PDFDownloadLink className="button transparent"
                             fileName={filename}
                             document={<CourseApplicationForm parents={this.props.user.parents}
                                                              participant={participant}
                                                              participation={participation} />}>
                {
                    ({blob, url, loading, error}) => <span><i
                        className="fas fa-download" /> {loading ? 'generuji...' : 'stáhnout'}</span>
                }
            </PDFDownloadLink>
        );

    };

    renderUploadButton(participation) {
        if (!participation.variableNumber) {
            return null;
        }

        if (participation.applicationReturned) {
            const date = new Date();
            date.setTime(participation.applicationReturned);
            return (
                <React.Fragment>
                    nahráno<br />
                    <small>{date.toLocaleString('cs-CZ')}</small>
                </React.Fragment>
            );
        }
        return (<button className="button transparent" onClick={() => this.setState({uploadForm: participation})}><i
            className="fas fa-upload" /> Nahrát</button>)
    }

    renderCourseParticipations(participant) {
        return participant.courseParticipations.map(participation => {
            const courseHelper = new Course(participation.course);
            const {course} = participation;
            const {coursesSchoolYearId} = this.props.settings;
            return (
                <tr key={participation.courseParticipationId}>
                    <td>
                        {participation.course.schoolYearId === coursesSchoolYearId &&
                        <Link to={courseHelper.getPermalink()}>
                            <strong>{course.name}</strong>
                        </Link>
                        }
                        {participation.course.schoolYearId !== coursesSchoolYearId &&
                        course.name
                        }
                        <br />
                        <small>{course.schoolYear}</small>
                        <br />
                        <small>{courseHelper.renderTime()}</small>
                    </td>
                    <td>{participation.variableNumber ? participation.variableNumber : 'náhradník'}</td>
                    <td>
                        <Payment amount={participation.payment1} date={participation.payment1Date} />
                        {participation.payment2 && <br />}
                        <Payment amount={participation.payment2} date={participation.payment2Date} />
                    </td>
                    {participant.age < 15 &&
                    <td>
                        {participation.canLeaveAlone ? 'sám/sama' : 'doprovod'}
                    </td>
                    }
                    <td className="text-center">
                        {this.renderDownloadButton(participant, participation)}
                    </td>
                    <td className="text-center">
                        {this.renderUploadButton(participation)}
                    </td>
                    <td className="text-center">
                        {participation.crossedOut &&
                        <span>Odhlášen</span>
                        }
                        {!participation.crossedOut && !participation.payment1 &&
                        <button className="button transparent"
                                onClick={() => this.props.unsubscribe(participation.courseParticipationId, course.courseId)}>Odhlásit</button>
                        }
                    </td>
                </tr>
            )
        });
    }

    renderParticipants() {
        const {participants} = this.props.user;
        return participants.map(participant => {
            return (
                <React.Fragment key={participant.participantId}>
                    <h2>{participant.name} {participant.surname}</h2>
                    {participant.courseParticipations.length > 0 &&
                    <div className="table-wrapper">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Kroužek</th>
                                <th title="Variabilní symbol">VS</th>
                                <th>Platby</th>
                                {participant.age < 15 &&
                                <th>Odchod</th>
                                }
                                <th colSpan={2}>Přihláška</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderCourseParticipations(participant)}
                            </tbody>
                        </table>
                    </div>
                    }
                    {participant.courseParticipations.length === 0 &&
                    <p>Účastník není přihlášen na žádný kroužek</p>
                    }
                </React.Fragment>
            );
        });
    }

    render() {
        const wrapperInfo = {
            ref: this.detailElement,
            onCloseHandler: (this.onDetailClosed),
            bgColor: 'color0'
        };

        const {participants} = this.props.user;

        return (
            <AccountDetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className='col'>
                    <h2 className="title">Kroužky
                        <button title="Zavřít" className="float-right link d-md-none"
                                onClick={this.onDetailClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                    </h2>
                    <div>
                        {participants.length > 0 &&
                        this.renderParticipants()
                        }
                        {participants.length === 0 &&
                        <p className="alert">
                            Váš účet nemá žádné účastníky, kteří by mohli být přihlášeni na kroužek. Nejprve nějakého
                            účastníka vytvořte.
                        </p>
                        }
                    </div>
                </div>
                {this.state.uploadForm && <UploadFileModal
                    participation={this.state.uploadForm}
                    onModalClose={() => {
                        this.setState({uploadForm: null})
                    }}
                />}
            </AccountDetailWrapper>
        );
    }
}

const mapStateToProps = ({account: {settings}}) => {
    return {settings}
};

export const CoursesAccountDetailConnected = connect(mapStateToProps, {unsubscribe})(CoursesAccountDetail);