import customHistory from "customHistory";

/**
 * Kdyz otevru URL s detailem stranky (napr. /kontakt/pokladna) a pote detail zavrit kliknutim na dismiss tlacitko
 * nebo na ListItem, tak se detail nezavre (resp. se zavre a hned zase otevre kvuli hodnotam v URL).
 *
 * Tahle metoda zaridi zmenu URL z /uroven-1/uroven-2 otevirajici detail na /uroven-1
 *
 * Plati pouze pro stranky (nikoli pro krouzky/tabory/akce)
 */
export default (givenPath) => {
    const [currentPath, historyPath] = [givenPath,customHistory.location.pathname].map(
        (string) => string.endsWith('/') ? string.slice(0, -1) : string);

    if (currentPath === historyPath) {
        const pathParts = historyPath.split("/").filter(item => item.length > 0);
        if (pathParts.length > 1) {
            pathParts.pop();
            customHistory.push(`/${pathParts.join('/')}`);
        }
    }
}