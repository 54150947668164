import React from 'react';
import {connect} from "react-redux";

import {uiChangedAction} from "actions/uiStateActions";
import notFoundImage from 'images/404.jpg';
import SetPassword from "components/account/SetPasswordForm";
import SignInForm from "components/account/SignInForm";
import PasswordResetForm from "components/account/PasswordResetForm";

const mapStateToProps = ({account}) => {
    return {
        authenticated: account.authenticated,
        setPasswordResult: account.setPasswordResult
    };
};

export default connect(mapStateToProps, {uiChangedAction})((props) => {

    const pageConfig = {
        title: 'Nastavení hesla'
    };

    props.uiChangedAction({
        title: pageConfig.title,
        color: '#009EE0',
        bgImage: notFoundImage
    });

    if (props.authenticated) {
        props.history.replace('/ucet');
    }

    const renderSuccess = () => {
        return (
            <React.Fragment>
                <h2 className="title">Heslo změněno</h2>
                <p><strong>Vaše nové heslo bylo nastaveno</strong><br />Nyní se můžete přihlásit.</p>
                <SignInForm />
            </React.Fragment>
        );
    };

    //todo opakuje se ve vícero component
    const renderFailure = () => {
        return (
            <React.Fragment>
                <h2 className="title">Ověření se nepodařilo</h2>
                <p>Zkontrolujte zda jste zkopírovali do adresní řádky celý odkaz</p>
            </React.Fragment>
        );
    };

    const renderExpired = () => {
        return (
            <React.Fragment>
                <h2 className="title">Limit pro ověření vypršel</h2>
                <p>Platnost požadavku na změnu hesla vypršela. Je třeba znovu zažádat o nové heslo.</p>
                <PasswordResetForm />
            </React.Fragment>
        );
    };
const renderLocked = () => {
        return (
            <React.Fragment>
                <h2 className="title">Účet byl zablokován</h2>
                <p>Pro jeho obnovení prosím kontaktujte pracovníky DDM JM.</p>
            </React.Fragment>
        );
    };

    const renderForm = () => {
        return(
            <React.Fragment>
                <h2 className="title">{pageConfig.title}</h2>
                <div>
                    <SetPassword />
                </div>
            </React.Fragment>
        );
    };

    const {setPasswordResult} = props;
    return (
        <section className="not-found">
            <div className="list-body" id="list-body">
                <div className="" id="list-detail">
                    <article className={`bg-color3 list-detail text-center`}>
                        {'ok' === setPasswordResult && renderSuccess()}
                        {'no-account' === setPasswordResult && renderFailure()}
                        {'expired' === setPasswordResult && renderExpired()}
                        {'locked' === setPasswordResult && renderLocked()}
                        {!setPasswordResult && renderForm()}
                    </article>
                </div>
            </div>
        </section>
    );
})