import React from 'react';
import ReactDOM from "react-dom";
import {connect} from 'react-redux';
import withUser from "components/account/hoc/withUser";
import SignInForm from "components/account/SignInForm";
import {ParticipantsTable} from "components/account/ParticipantsAccountPage";
import {clearSubscriptionState, subscribe, unsubscribe} from "actions/coursesActions";
import {Link} from "react-router-dom";
import Course from "entities/Course";
import {checkCourseParticipation} from "helpers/participationsChecker";
import {PersonContact} from "components/lists/ListDetail";
import CourseApplicationForm from 'components/pdfs/CourseApplicationForm';
import {PDFDownloadLink} from "@react-pdf/renderer";
import slugify from "slugify";
import subscriptionsStart from "helpers/subscriptionsStart";

class SubscribeModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            subscribing: null,
        }
    }

    handleSubscribeClick = (participant, canLeaveAlone = null) => {
        this.setState({subscribing: participant});
        if (canLeaveAlone !== null || participant.age >= 15) {
            const substitute = this.props.course.awaitingSpots > 0;
            this.props.subscribe(participant.participantId, this.props.course.courseId, canLeaveAlone, substitute);
        }
    };

    renderSubscribeButton(participant) {
        const {ageFrom, ageTo} = this.props.course;
        const {age} = participant;
        const hasProperAge = age >= ageFrom && age <= ageTo;
        const missingParent = age < 18 && (!this.props.user.parents || this.props.user.parents.length === 0);

        const isSubscribing = ({participantId}) => this.state.subscribing && this.state.subscribing.participantId === participantId;

        return (
            <React.Fragment>
                {hasProperAge && !missingParent && !isSubscribing(participant) &&
                <button onClick={() => this.handleSubscribeClick(participant)}
                        className="button"
                        title="Zapsat účastníka">
                    <i className="fas fa-user-plus" /> ZAPSAT
                </button>
                }
                {hasProperAge && !missingParent && isSubscribing(participant) && age < 18 &&
                <React.Fragment>
                    Účastník bude po skončení zájmové činnosti odcházet:
                    <div className="d-flex justify-content-center">
                        <button className="button" onClick={() => this.handleSubscribeClick(participant, true)}>
                            samostatně
                        </button>
                        <button className="button" onClick={() => this.handleSubscribeClick(participant, false)}>
                            v doprovodu
                        </button>
                    </div>
                </React.Fragment>
                }
                {this.renderWrongAgeMessage(hasProperAge)}
                {this.renderMissingParentMessage(missingParent)}
            </React.Fragment>
        )
    }

    renderWrongAgeMessage(hasProperAge) {
        const {ageFrom, ageTo, contactPerson, name, identificationNumber} = this.props.course;

        if (hasProperAge) {
            return null;
        }

        return (
            <React.Fragment>
                <p className="info uppercase" title={`Kroužek je pro účastníky ve věku od ${ageFrom} do ${ageTo}`}>
                    nelze zapsat - nevyhovující věk
                </p>
                <div className="row">
                    <div className="col text-right">
                        <p>Pokud účastník v aktuálním školním roce dosáhne požadovaného věku, kontaktujte našeho
                           pracovníka pro případné možnosti přihlášení.</p>
                    </div>
                    <div className="col text-left">
                        <PersonContact {...contactPerson} key={contactPerson.personId}
                                       emailSubject={`Dotaz k přihlášení na kroužek: ${identificationNumber} ${name}`} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderMissingParentMessage(missingParent) {
        if (!missingParent) {
            return null;
        }

        return (
            <p>
                <strong>nelze zapsat - chybí zákonný zástupce</strong><br />
                Pro zapsání nezletilého účastníka musíte <Link to='/ucet'>přidat alespoň jednoho zákonného
                                                                          zástupce</Link>
            </p>
        );
    }

//todo lepší tooltip pro zobrazení
    tableSubscribeAction = (participant) => {
        const participation = checkCourseParticipation(participant, this.props.course);
        return (
            <React.Fragment>
                {participation &&
                <div className="row align-items-center">
                    <div className="col text-center">
                        <strong className="uppercase">zapsán</strong><br />
                        <small>{participation.variableNumber ? `variabilní symbol ${participation.variableNumber}` : 'náhradník'}</small>
                    </div>
                    <div className="col text-center">
                        {!participation.payment1 &&
                        <button className="button"
                                onClick={() => this.props.unsubscribe(participation.courseParticipationId, this.props.course.courseId)}>odhlásit</button>
                        }
                    </div>
                </div>
                }
                {!participation &&
                this.renderSubscribeButton(participant)
                }
            </React.Fragment>
        )
    };

    //todo načítat text zobrazený po přihlášení (účastníka i náhradníka) z WP přes API
    renderSuccessResponse = () => {
        const {newParticipation} = this.props;
        if (!this.props.authenticated || !newParticipation) {
            return null;
        }
        const {course} = newParticipation;

        return (
            <div>
                {newParticipation.variableNumber &&
                <React.Fragment>
                    <h2>Účastník <em>{newParticipation.participantName}</em> byl úspěšně přihlášen</h2>
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>Variabilní symbol</th>
                            <td><strong>{newParticipation.variableNumber}</strong></td>
                        </tr>
                        <tr>
                            <th>Částka za 1. pololetí</th>
                            <td>{course.priceFirstTerm} {course.priceFirstTermNote}</td>
                        </tr>
                        <tr>
                            <th>Číslo účtu</th>
                            <td>9630041 / 0100</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Děkujeme za přihlášení</h3>
                    <ul>
                        <li> 1. - 2. hodina pravidelného zájmového vzdělávání je pro účastníka v daném zájmovém útvaru
                             zdarma.
                        </li>
                        <li> Nejpozději na 2. hodině pravidelného zájmového vzdělávání musí účastník odevzdat vyplněnou
                             a
                             podepsanou přihlášku.
                        </li>
                        <li> Nejpozději před 3. hodinou pravidelného zájmového vzdělávání musí být za účastníka
                             provedena
                             úplata.
                        </li>
                        <li> V případě neodevzdání přihlášky nebo neprovedení úplaty v řádném termínu dochází k
                             automatickému odhlášení účastníka z pravidelného zájmového vzdělávání!!!
                        </li>
                        <li> Úplata probíhá ve dvou splátkách – za 1. a 2. pololetí školního roku. Celoroční platba není
                             možná.
                        </li>
                        <li> Způsob platby – bezhotovostní převod.</li>
                        <li> U bezhotovostní platby daňový doklad automaticky nevystavujeme! V případě požadavku na
                             vystavení daňového dokladu je nutné si před uskutečněním platby vyžádat vystavení faktury.
                             Zpětně, po provedení platby, již fakturu vystavit nelze!<br />
                             Kontaktní osoba: Svatava Novisedláková, 778 524 439, novisedlakova@ddmjm.cz
                        </li>
                    </ul>
                    <p className="text-center">
                        <PDFDownloadLink className="button transparent"
                                         document={<CourseApplicationForm parents={this.props.user.parents}
                                                                          participant={this.state.subscribing}
                                                                          participation={newParticipation} />}
                                         fileName={`${newParticipation.variableNumber}-${slugify(course.name.toLowerCase())}.pdf`}>
                            {({blob, url, loading, error}) => (loading ? 'Generuji...' :
                                <span><i className="fas fa-download" /> Stáhnout přihlášku</span>)}
                        </PDFDownloadLink>
                    </p>
                </React.Fragment>
                }
                {!newParticipation.variableNumber &&
                <React.Fragment>
                    <h2 className="text-center">Přihlášení do činnosti zájmového kroužku nebylo dokončeno z důvodu zaplnění kapacity kroužku!</h2>
                    <p className="text-center">
                        <strong>Účastník {newParticipation.participantName} byl zapsán jako náhradník.</strong><br />
                        V případě, že se místo v kroužku uvolní, budeme Vás kontaktovat.<br />
                        Děkujeme.
                    </p>
                </React.Fragment>
                }

            </div>
        );
    };

    renderParticipantsTable() {
        if (!this.props.authenticated || this.props.newParticipation) {
            return null;
        }

        return (
            <ParticipantsTable participants={this.props.user.participants} onlyRequiredFields={true}
                               Actions={(participant) => this.tableSubscribeAction(participant)} />
        )
    }

    handleClose = () => {
        this.props.closeModal();
        this.props.clearSubscriptionState();
    };

    renderNoFreeSpotsWarning() {
        const {awaitingSpots} = this.props.course;
        const {newParticipation} = this.props;

        if (awaitingSpots > 0 && !newParticipation) {
            return (<p className="alert"><strong>Kroužek je již obsazen, přihlašujete náhradníka. </strong><br />
                    Aktuální počet již přihlášených náhradníků je: <strong>{awaitingSpots}</strong>.
                </p>
            )
        }
    }

    allowSubscribing() {
        if (this.props.settings === null) {
            //settings not yet ready
            return false;
        }

        const {coursesSchoolYear} = this.props.settings;
        const schoolYear = coursesSchoolYear.split("/");
        const today = new Date();
        const start = subscriptionsStart();
        const currentYear = today.getFullYear();

        let result = false;
        //eg: 2019 | 2018/2019
        if (currentYear === parseInt(schoolYear[1])) result = true;
        //eg: 2019 | 2019/2020
        if (currentYear === parseInt(schoolYear[0]) && today.getTime() > start.getTime()) result = true;

        //for dev pruposes
        if (process.env.REACT_APP_ALWAYS_ALLOW_SUBSCRIPTION === 'true') {
            console.log("Na BETA verzi je prihlasovani vzdy povoleno.");
            console.log("Prihlasovani na produkci by v tomto nastaveni bylo:", result ? 'povolene' : 'zakazane');
            return true
        }

        return result;
    }


    renderPendingSubscription() {
        const start = subscriptionsStart();
        return (
            <div>
                <p className="alert">
                    Přihlašování bude k dispozici od <strong>{start.toLocaleString('cs-CZ')}</strong>.
                </p>
                <p className="text-center">
                    Pro přihlášení účastníka je potřeba se registrovat. Pokud jste již registrovaní, nezapomeňte ve svém
                    účtu
                    vytvořit účastníky, které budete chtít přihlásit. Pro účastníky mladší 18 let je třeba též vytvořit
                    alespoň
                    jednoho zákonného zástupce.
                </p>
                <div className="text-center">
                    {this.props.authenticated &&
                    <Link to='/ucet' className="button transparent">Můj účet</Link>
                    }
                    {!this.props.authenticated &&
                    <React.Fragment>
                        <div className="form-wrapper">
                            <SignInForm />
                        </div>
                    </React.Fragment>
                    }
                </div>
            </div>
        );
    }

    renderModalBody() {
        if (!this.allowSubscribing()) {
            return this.renderPendingSubscription();
        }
        return (
            <React.Fragment>
                {this.renderSuccessResponse()}
                {this.renderNoFreeSpotsWarning()}
                {this.renderParticipantsTable()}
                {!this.props.authenticated &&
                <div className="form-wrapper">
                    <SignInForm />
                </div>
                }
            </React.Fragment>
        );
    }

    //todo vypsat více informací o tom, na co se hlásím
    render() {
        const course = new Course(this.props.course);

        return (
            <React.Fragment>
                <div className="modal fade show" role="dialog" onClick={() => this.handleClose()}>
                    <div className="modal-dialog modal-dialog-centered modal-xl info-modal">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">{this.props.course.name}
                                    <small>{course.renderTime()}</small>
                                </h2>

                                <button title="Zavřít" className="float-right link"
                                        onClick={() => this.handleClose()}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.renderModalBody()}
                            </div>
                            {this.allowSubscribing() && !this.props.newParticipation && this.props.authenticated &&
                            <div className="modal-footer">
                                <Link to='/ucet' className="button">Přidat nového účastníka</Link>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" />
            </React.Fragment>
        )
    }
}

const mapsStateToProps = ({account: {newParticipation, settings}}) => {
    return {newParticipation, settings}
};

export default (props) => {
    const ConnectedModal = connect(mapsStateToProps, {
        clearSubscriptionState,
        subscribe,
        unsubscribe
    })(withUser(SubscribeModal));
    return ReactDOM.createPortal(<ConnectedModal {...props} />,
        document.querySelector('#modal'))
};
