import React from 'react';
import {Link} from 'react-router-dom';
import list_placeholder from 'images/list-placeholder.png';
import Camp from 'entities/Camp';
import ListItem from "components/lists/ListItem";

class CampItem extends ListItem {

    constructor(props, context) {
        super(props, context);

        this.state = {smallImageUrl: list_placeholder};

        //preload image
        this.preloadImage.src = this.props.camp.smallImageUrl;
        this.preloadImage.addEventListener('load', this.setLoadedImage);
    }

    render() {
        const {
            name, campId, dateFrom, dateTo, place, price, priceWithoutDonation, category
        } = this.props.camp;

        const camp = new Camp(this.props.camp);

        return (
            <article className={`list-item ${this.props.selected ? 'detail-opened' : ''}`} id={`item-${campId}`}>
                <Link to={camp.getPermalink()} title={name} className="link" onClick={(e) => {
                    e.preventDefault();
                    this.onClickHandler(campId, this.props.camp)
                }}>
                    <div className="content bg-color">
                        <header>
                                {dateFrom && dateTo &&
                                <span className="supheadline">
                                    {camp.renderDate()}
                                </span>
                                }
                            <h3 className="headline">{name}</h3>
                        </header>
                        <div className="description">
                            <span title="Místo">{place}</span><br />
                            <span title="Věková skupina" className="tooltip">{camp.renderAge()}</span><br />
                            <span title="Cena s dotací / bez dotace" className="tooltip">
                                {`${price} / ${priceWithoutDonation}`}
                            </span><br />
                            <span title="Typ" className="tooltip">{category}</span>
                        </div>
                    </div>
                    <div className="image-wrap bg-color">
                        <img src={this.state.smallImageUrl} className="image" alt={name} />
                    </div>
                </Link>
            </article>
        );
    }
}

export default CampItem;