import React from 'react';
import {Link} from "react-router-dom";

export default React.forwardRef((props,ref) => {
    const {onCloseHandler, link, children, sticky, showSubscribeDialog} = props;
    return (
        <div className="w-100" style={{display: 'none'}} id="list-detail" ref={ref}>
            <article className={`${props.bgColor} list-detail`}>
                <div className="row">
                    {children}
                </div>
                <nav className="detail-actions">
                    <ul>
                        {sticky !== true &&
                        <li className="d-none d-md-inline-block">
                            <button title="Zavřít" className="link" onClick={onCloseHandler}>
                                <i className="fas fa-times" />
                                <span className="sr-only">Zavřít</span></button>
                        </li>
                        }
                        {props.canSubscribe &&
                        <li>
                            <button title="Zapsat účastníka" className="link" rel="nofollow"
                                    onClick={showSubscribeDialog}>
                                <i className="fas fa-user-plus" />
                                <span className="sr-only">Zapsat účastníka</span>
                            </button>
                        </li>
                        }
                        <li>
                            <Link to={`${link}?vytisknout`} title="Vytisknout" className="link" rel="nofollow">
                                <i className="fas fa-print" />
                                <span className="sr-only">Vytisknout</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={link} title="Trvalý odkaz" className="link"><i
                                className="fas fa-link" />
                                <span className="sr-only">Trvalý odkaz</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </article>
        </div>
    );
});

export const AccountDetailWrapper = React.forwardRef((props, ref) => {
    const {onCloseHandler, children, bgColor} = props;
    return (
        <div className="w-100" style={{display: 'none'}} id="list-detail" ref={ref}>
            <article className={`bg-${bgColor} list-detail`}>
                <div className="row">
                    {children}
                </div>
                <nav className="detail-actions d-none d-md-inline-block">
                    <ul>
                        <li className="">
                            <button title="Zavřít" className="link" onClick={onCloseHandler}>
                                <i className="fas fa-times" />
                                <span className="sr-only">Zavřít</span></button>
                        </li>
                    </ul>
                </nav>
            </article>
        </div>
    );
});