import React from 'react';
import {Document, Font, Image, Link, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import config from 'config';
import teuton_magerregular from 'fonts/teutonmager-webfont.ttf';
import teuton_magerbold from 'fonts/teutonmagerbold-webfont.ttf';
import Course from "entities/Course";

Font.register({family: 'teuton_magerregular', src: teuton_magerregular});
Font.register({family: 'teuton_magerbold', src: teuton_magerbold});

// Create styles
const styles = StyleSheet.create({
    bold: {
        fontWeight: 'bold',
        fontFamily: 'teuton_magerbold',
    },
    small: {
        fontSize: 10
    },
    emptyLine: {
        width: '100%',
        minHeight: 20,
        borderBottom: '1 dashed #666666',
        short: {
            maxWidth: 200
        }
    },
    heading: {
        fontWeight: 'bold',
        fontFamily: 'teuton_magerbold',
        marginBottom: 4,
        fontSize: 15
    },
    paragraph: {
        fontSize: 12,
        marginBottom: 3
    },
    list: {
        fontSize: 12,
        display: 'block',
        item: {
            display: "list-item"
        }
    },
    document: {
        backgroundColor: '#FFFFFF',
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        justifyContent: 'flex-start',
        fontFamily: 'teuton_magerregular',
        padding: 30,
        paddingBottom: 70
    },
    header: {
        flexDirection: 'row',
        alignItems: 'bottom',
        motto: {
            color: '#666666',
            fontSize: 13,
            marginLeft: 13
        }
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: '#666666',
        position: 'absolute',
        bottom: 20,
        left: 30,
        right: 30,
        fontSize: 10,
        contacts: {
            flexDirection: 'column',
            alignItems: 'flex-end'
        }
    },
    section: {
        paddingLeft: 54,
        paddingTop: 16,
        fontSize: 15
    },
    headline: {
        marginBottom:20,
        title: {
            fontSize: 28,
            marginBottom: 6
        },
        subtitle: {
            fontSize: 16
        }
    },
    form: {
        line: {
            flexDirection: 'row',
            marginBottom: 4,
            heading: {
                width: 130
            }
        },
    },
    signatures: {
        fontSize: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        signature: {
            flexDirection: 'row',
            alignItems: 'flex-end'
        },
        line:{
            width:90,
            marginLeft:10
        }
    }
});

const renderParents = (parents) => {
    return parents.map(parent => {
        return (
            <View style={{marginBottom: 5}} key={`parent-${parent.parentId}`}>
                <View style={styles.form.line}>
                    <Text style={[styles.form.line.heading]}>Jméno a příjmení</Text>
                    <Text>{parent.name}</Text>
                </View>
                <View style={styles.form.line}>
                    <Text style={[styles.form.line.heading]}>Telefon</Text>
                    <Text>{parent.phone}</Text>
                </View>
                <View style={styles.form.line}>
                    <Text style={[styles.form.line.heading]}>E-mail</Text>
                    <Text>{parent.email}</Text>
                </View>
            </View>
        );
    });
};

const renderCanLeaveAloneNotice = ({age}, {canLeaveAlone}) => {
    if (age >= 18) {
        return null;
    }
    return (
        <View style={styles.section}>
            <View style={styles.form.line}>
                <Text style={{width: 300, marginRight: 10}}>
                    Účastník bude po skončení zájmové činnosti odcházet:</Text>
                <Text style={styles.bold}>{canLeaveAlone ? 'Samostatně' : 'V doprovodu'}</Text>
            </View>
        </View>
    );
};

const renderAdultPrices = ({priceAdultFirstTerm,priceAdultSecondTerm}) => {
    if(!priceAdultFirstTerm || !priceAdultSecondTerm){
        return null;
    }
    return <View style={styles.form.line}>
        <Text style={[styles.bold, styles.form.line.heading]}>Výše platby pro dospělé</Text>
        <View>
            <View style={styles.form.line}>
                <Text style={[styles.bold, {marginRight: 5}]}>1. pololetí</Text>
                <Text style={{marginRight: 10}}>{priceAdultFirstTerm}</Text>
                <Text style={[styles.bold, {marginRight: 5}]}>2. pololetí</Text>
                <Text style={{marginRight: 10}}>{priceAdultSecondTerm}</Text>
            </View>
        </View>
    </View>
};

const renderPublicNotice = ({publicNotice}) =>{
    if(publicNotice && publicNotice.length !== 0){
        return <Text style={[styles.paragraph]}>{publicNotice}</Text>
    }
    return <Text style={styles.emptyLine} />
};

// Create Document Component
export default ({participant, participation, parents}) => {
    const {course} = participation;
    const courseHelper = new Course(course);
    return (
        <Document title={`Přihláška do kroužku ${course.name}`} author={config.meta.title}>
            <Page style={styles.page} wrap >

                {/* HEADER */}
                <View fixed style={styles.header}>
                    <Image cache src={`${process.env.PUBLIC_URL}/images/logo-ddmjm-pdf.jpg`}
                           style={{width: 40}} />
                    <Text style={styles.header.motto}>Skládáme Váš volný čas</Text>
                </View>

                {/* FOOTER */}
                <View style={styles.footer} fixed >
                    <Image cache src={`${process.env.PUBLIC_URL}/images/logo-praha-pdf.jpg`} />
                    <Text style={[styles.bold, {alignSelf: 'flex-end'}]}>ddmjm.cz</Text>
                    <View style={styles.footer.contacts}>
                        <Text style={styles.bold}>Dům dětí a mládeže Jižní Město</Text>
                        <Text>Šalounova 2024, 149 00 Praha 4, 778 473 546, sal@ddmjm.cz</Text>
                        <Text>Květnového vítězství 2126, 149 00 Praha 4, 778 485 161, kve@ddmjm.cz</Text>
                    </View>
                </View>

                {/* TITLE */}
                <View style={[styles.section, styles.headline, styles.bold]}>
                    <Text style={styles.headline.title}>{`Přihláška do kroužku`.toUpperCase()}</Text>
                    <Text
                        style={styles.headline.subtitle}>{`DDM JM na školní rok ${course.schoolYear}`.toUpperCase()}</Text>
                </View>

                {/* FORM */}
                <View style={styles.section}>
                    <View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]}>Název kroužku</Text>
                        <Text>{course.name}</Text>
                    </View><View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]}>Den a čas</Text>
                        <Text>{courseHelper.renderTimeAsStrings()}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]}>Variabilní symbol</Text>
                        <Text>{participation.variableNumber}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]}>Výše platby</Text>
                        <View>
                            <View style={styles.form.line}>
                                <Text style={[styles.bold, {marginRight: 5}]}>1. pololetí</Text>
                                <Text style={{marginRight: 10}}>{course.priceFirstTerm}</Text>
                                <Text style={[styles.bold, {marginRight: 5}]}>2. pololetí</Text>
                                <Text style={{marginRight: 10}}>{course.priceSecondTerm}</Text>
                            </View>
                        </View>
                    </View>

                    {renderAdultPrices(course)}
                    <View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]} />
                        <View>
                            <Text style={styles.small}>
                                Úplata probíhá ve dvou splátkách - za 1. a 2. pololetí školního roku.
                            </Text>
                            <Text style={styles.small}>
                                Celoroční platba není možná!
                            </Text>
                        </View>

                    </View>
                </View>

                {/*Ucastnik*/}
                <View style={styles.section}>
                    <View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]}>Účastník</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>Jméno a příjmení</Text>
                        <Text>{participant.name} {participant.surname}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>Datum narození</Text>
                        <Text>{participant.dateOfBirth}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>Státní příslušnost</Text>
                        <Text>{participant.nationality}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>Adresa</Text>
                        <Text>{participant.street} {participant.streetNumber}, {participant.city}, {participant.zipCode}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>Škola *</Text>
                        <Text style={!participant.school ? [styles.emptyLine, styles.emptyLine.short] : null}>{participant.school}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>Telefon **</Text>
                        <Text style={!participant.gsm ? [styles.emptyLine, styles.emptyLine.short] : null}>{participant.gsm}</Text>
                    </View>
                    <View style={styles.form.line}>
                        <Text style={[styles.form.line.heading]}>E-mail **</Text>
                        <Text style={!participant.email ? [styles.emptyLine, styles.emptyLine.short] : null}>{participant.email}</Text>
                    </View>
                </View>

                {/*Zakonni zastupci*/}
                <View style={styles.section}>
                    <View style={styles.form.line}>
                        <Text style={[styles.bold, styles.form.line.heading]}>Zákonní zástupci</Text>
                    </View>
                    {renderParents(parents)}
                    <Text style={styles.small}>
                        * Nepovinný údaj / ** Nepovinný údaj pro účastníky mladší 18 let
                    </Text>
                </View>
                {renderCanLeaveAloneNotice(participant, participation)}


                {/*    Podmínky a dalsi texy */}
                <View style={styles.section} break>
                    <Text style={styles.heading}>Přihlášení</Text>
                    <Text style={styles.paragraph}>
                        1. - 2. hodina pravidelného zájmového vzdělávání je pro účastníka v daném zájmovém útvaru
                        zdarma.
                        Nejpozději na 2. hodině pravidelného zájmového vzdělávání musí účastník odevzdat vyplněnou a
                        podepsanou přihlášku.
                        Nejpozději před 3. hodinou pravidelného zájmového vzdělávání musí být za účastníka provedena
                        úplata. <Text style={styles.bold}>
                            V případě neodevzdání přihlášky nebo neprovedení úplaty v řádném termínu dochází k
                            automatickému odhlášení účastníka z pravidelného zájmového vzdělávání!!!
                        </Text>
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Základní podmínky pro provedení platby a daňový doklad</Text>
                    <Text style={styles.paragraph}>
                        - Úplata probíhá ve dvou splátkách - za 1. a 2. pololetí školního roku.
                        Celoroční platba není možná.
                    </Text>
                    <Text style={styles.paragraph}>
                        - Způsob platby - bezhotovostní převod.
                    </Text>
                    <Text style={styles.paragraph}>
                        - U bezhotovostní platby daňový doklad automaticky nevystavujeme! V případě požadavku na
                        vystavení daňového dokladu je nutné si před uskutečněním platby vyžádat vystavení faktury.
                        Zpětně, po provedení platby, již fakturu vystavit nelze!
	    <Text>Kontaktní osoba: Svatava Novisedláková, 778 524 439, novisedlakova@ddmjm.cz</Text>
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Sdělení rodičů vedoucímu kroužku:</Text>
                    <Text style={[styles.small,{marginBottom: 10}]}>
                        (zdravotní potíže, zdravotní omezení, alergie, další důležitá upozornění)
                    </Text>
                    {renderPublicNotice(participant)}
                    <Text style={styles.emptyLine} />
                    <Text style={styles.emptyLine} />
                </View>
                <View style={styles.section}>
                    <Text style={styles.paragraph}>
                        Byl(a) jsem seznámen(a) s Vnitřním řádem DDM JM. (Plné znění k dispozici na <Link
                        src='https://ddmjm.cz'>ddmjm.cz</Link>).
                    </Text>
                </View>
                <View style={[styles.section,styles.signatures]}>
                    <View style={styles.signatures.signature}>
                        <Text>Datum podpisu:</Text>
                        <Text style={[styles.emptyLine,styles.signatures.line]}/>
                    </View>
                    <View style={styles.signatures.signature}>
                        <Text>Podpis zákonného zástupce:</Text>
                        <Text style={[styles.emptyLine, styles.signatures.line]} />
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.heading}>Souhlas se zpracováním osobních údajů</Text>
                    <Text style={styles.paragraph}>
                        Souhlas se zpracováním níže vymezených osobních údajů Vašeho syna/dcery udělujete svým podpisem školské organizaci Domu dětí a mládeže Jižní Město, Šalounova 2024, 149 00 Praha 4,
                        IČ: 45241643. Svůj souhlas poskytujete dobrovolně, z jeho udělení neplynou žádné právní povinnosti.
                    </Text>
                    <Text style={styles.paragraph}>
                        Souhlas udělujete s pořizováním a se zpracováním fotografií, filmových záznamů a zvukových nahrávek zachycujícíh Vašeho syna/dceru a jeho/její činnost a projevy v rámci činností zajišťovaných školským zařízením pro účely jeho propagace.
                    </Text>
                    <Text style={styles.paragraph}>
                        Uvedené osobní údaje budou zpracovávány v elektronické podobě. Na základě těchto údajů nebudou činěna žádná rozhodnutí výlučně automatizovanými prostředky.
                    </Text>
                    <Text style={styles.paragraph}>
                        Souhlas udělujete s účinností ode dne podpisu po dobu, kdy jsou osobní údaje dítěte zařízením zákonně zpracovávány. Svůj souhlas můžete kdykoli odvolat bez jakýchkoli sankcí. Pokud souhlas odvoláte, není tím dotčena zákonnost zpracování osobncíh údajů před tímto odvoláním.
                    </Text>
                </View>

                <View style={[styles.section, styles.signatures]}>
                    <View style={styles.signatures.signature}>
                        <Text>Datum podpisu:</Text>
                        <Text style={[styles.emptyLine, styles.signatures.line]} />
                    </View>
                    <View style={styles.signatures.signature}>
                        <Text>Podpis zákonného zástupce:</Text>
                        <Text style={[styles.emptyLine, styles.signatures.line]} />
                    </View>
                </View>

                <View style={[styles.section, styles.signatures]}>
                    <View style={styles.signatures.signature}>
                        <Text>Datum předání přihlášky:</Text>
                        <Text style={[styles.emptyLine, styles.signatures.line]} />
                    </View>
                    <View style={styles.signatures.signature}>
                        <Text>Datum odevzdání přihlášky zpět:</Text>
                        <Text style={[styles.emptyLine, styles.signatures.line]} />
                    </View>
                </View>
            </Page>
        </Document>
    );
}
