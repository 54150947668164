import ReactGA from 'react-ga';
//Actions creaters

//action to open/hide menu
export const menuOpenAction = show =>{
  return {
      type: 'UI_MENU_STATE_CHANGED',
      payload: show
  }
};

//action to open/hide filter
export const filterOpenAction = show => {
    if(show) {
        ReactGA.event({
            category: 'Filtr',
            action: 'Filtr otevren'
        });
    }
    return {
        type: 'UI_FILTER_STATE_CHANGED',
        payload: show
    }
};

export const titleChangedAction = title => {
    return {
        type: 'UI_TITLE_CHANGED',
        payload: title
    }
};

export const colorChangedAction = color => {
  return {
      type: 'UI_COLOR_CHANGED',
      payload: color
  }
};

export const bgimageChangedAction = src => {
    return {
        type: 'UI_BG_IMAGE_CHANGED',
        payload: src
    }
};

export const uiChangedAction = ({title, color,bgImage}) =>{
    return {
        type: 'UI_TITLE_COLOR_CHANGED',
        payload: {title, color, bgImage}
    }
};

export const closeAlert = () => {
    return {
        type: 'AUTH_NOTIFY_LOGOUT',
        payload: false
    }
};
