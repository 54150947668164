import React from 'react';
import RoutesConfig from 'routes';
import {Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";

import {menuOpenAction} from 'actions/uiStateActions'
import {signout} from 'actions/accountActions'
import logo_praha from 'images/logo-praha.png';
import logo_prazskeddm from 'images/logo-prazske-ddm.png';
import logo_zonerama from 'images/logo-zonerama.png';
import SignInForm from "components/account/SignInForm";
import PasswordResetForm from 'components/account/PasswordResetForm'
import ResendActivationLinkForm from "components/account/ResendActivationLinkForm";

class Menu extends React.Component {

    state = {
        openSubmenu: null,
        openForm: null,
    };

    closeMenu = (path = null) => {
        this.props.menuOpenAction(false);
        if (path && !path.startsWith(this.state.openSubmenu)) {
            this.setState({openSubmenu: null});
        }
        this.setState({openForm: null});
    };

    onSubmenuOpen = (event, path) => {
        event.preventDefault();
        if (this.state.openSubmenu === path) {
            this.setState({openSubmenu: null});
        } else {
            this.setState({openSubmenu: path});
        }
    };

    onSingOut = () => {
        this.props.signout();
        this.setState({openForm: null});
    };


    renderSubmenu(subroutes) {
        if (subroutes && subroutes.length > 0) {
            return (
                <ul className='submenu'>
                    {subroutes.map(({path, title}) => {
                        return (
                            <li className="menu-listitem" key={path}>
                                <NavLink to={path} className="menu-listitem__text"
                                         onClick={() => this.closeMenu(path)}>{title}</NavLink>
                            </li>
                        )
                    })}
                </ul>
            );
        }
    }

    renderMenuItem() {
        return RoutesConfig.map(({path, title, subroutes}) => {
            const classNames = ['menu-listitem'];
            const withSubmenu = subroutes && subroutes.length > 0;
            if (withSubmenu && this.state.openSubmenu === path) {
                classNames.push('with-submenu');
            }

            return (
                <li className={classNames.join(" ")} key={path}>
                    <NavLink to={path} className="menu-listitem__text"
                             isActive={(match) => {
                                 return match && path !== "#";
                             }}
                             onClick={(e) => {
                                 if (withSubmenu) {
                                     this.onSubmenuOpen(e, path);
                                 } else {
                                     this.closeMenu(path);
                                 }
                             }}>{title}</NavLink>
                    {this.renderSubmenu(subroutes)}
                </li>
            );
        })
    }

    render() {
        const navbarIconsConfig = {
            className: "menu-partner", target: "_blank", rel: "noreferrer noopener"
        };

        const {authenticated} = this.props;

        return (
            <React.Fragment>
                <nav id="mm-navigation" className={`menu ${this.props.opened ? 'menu-opened' : ''}`}>
                    <div className="menu-navbars_top">
                        <div className="menu-navbar">
                            {/*<button title="Hledat">*/}
                            {/*    <i className="fas fa-search" /></button>*/}
                            {!authenticated &&
                            <React.Fragment>
                                <Link to='/ucet/registrace' title="Registrace" onClick={() => this.closeMenu('/ucet/registrace')}>
                                    <i className="fas fa-user-plus" /></Link>
                                <button title="Přihlásit se" onClick={() => this.setState({openForm: 'signin'})}>
                                    <i className="fas fa-sign-in-alt" /></button>
                            </React.Fragment>
                            }
                            {authenticated &&
                            <React.Fragment>
                                <button title="Odhlásit se" onClick={this.onSingOut}>
                                    <i className="fas fa-sign-out-alt" /></button>
                                <Link to='/ucet' title="Můj účet" onClick={() => this.closeMenu()}>
                                    <i className="fas fa-user" /></Link>
                            </React.Fragment>
                            }
                            <button title="Zavřít" className="dismiss-menu"
                                    onClick={() => this.closeMenu()}>
                                <i className="fas fa-times" /></button>
                        </div>
                    </div>
                    <div className="menu-panels">
                        {this.state.openForm === 'signin' && !authenticated &&
                        <MenuPanel>
                            <SignInForm openForm={(openForm) => this.setState({openForm})} closeMenu={this.closeMenu} />
                        </MenuPanel>
                        }
                        {this.state.openForm === 'resetpsw' &&
                        <MenuPanel>
                            <PasswordResetForm />
                        </MenuPanel>
                        }
                        {this.state.openForm === 'resendlink' &&
                        <MenuPanel>
                            <ResendActivationLinkForm />
                        </MenuPanel>
                        }
                        <div className="menu-panel">
                            <ul className="menu-listview">
                                {this.renderMenuItem()}
                            </ul>
                        </div>
                    </div>
                    <div className="menu-navbars_bottom">
                        <div className="menu-navbar">
                            <a href="http://www.praha.eu/" {...navbarIconsConfig}>
                                <img src={logo_praha} alt="Logo hl. m. Prahy" /></a>
                            <a href="http://www.volnycaspraha.cz/" {...navbarIconsConfig}>
                                <img src={logo_prazskeddm} alt="Logo Pražských domů dětí a mládeže" /></a>
                            <a href="http://ddmjm.zonerama.com/" {...navbarIconsConfig}>
                                <img src={logo_zonerama} alt="Logo Zonerama" /></a>
                            <a href="http://www.youtube.com/user/DDMJizniMesto" {...navbarIconsConfig}>
                                <i className="fab fa-youtube fa-2x" /></a>
                            <a href="http://www.facebook.com/DDM11" {...navbarIconsConfig}>
                                <i className="fab fa-facebook-square fa-2x" /></a>
                        </div>
                    </div>
                </nav>
                <div className={`menu-overlay ${this.props.opened ? 'visible' : ''}`}
                     onClick={() => this.closeMenu()} />
            </React.Fragment>
        );
    }
}

export const MenuPanel = ({children}) => {
    return (
        <div className='menu-panel form-panel'>
            {children}
        </div>
    );
};

const mapStateToProps = ({account}) => {
    return {
        authenticated: account.authenticated,
    }
};

export default connect(mapStateToProps, {menuOpenAction, signout})(Menu);
