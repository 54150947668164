import ServiceForKids from "entities/ServiceForKids";

class Camp extends ServiceForKids{
    camp = {};

    constructor(camp) {
        super(camp,camp.campId);
        this.camp = camp;

        this.dateFromAsDate = new Date(camp.dateFrom);
        this.dateToAsDate = new Date(camp.dateTo);
    }

    renderDate(){
        return `${this.dateFromAsDate.toLocaleDateString()} - ${this.dateToAsDate.toLocaleDateString()}`;
    }

    getPermalink() {
        return super.getPermalink('tabory');
    }
}

export default Camp;