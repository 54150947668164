export default {
    defaultTitle: window.innerWidth > 570 ? 'Dům dětí a mládeže Jižní Město' : 'DDM Jižní Město',
    defaultColor: '#009EE0',

    meta: {
        title: 'Dům dětí a mládeže Jižní Město'
    },

    notFound: {
        title: 'Stránka nenalezena',
        color: '#009EE0',
    },

    l10n: {
        dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
        monthsNames: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec']
    },
    pagination: {
        courses: 15,
        camps: 15,
        events: 15,
        homepage: 30,
        galleries: 20
    },

    colors:{
        base: '#009EE0',
        color0: "#e74b2b",
        color1: "#e5323d",
        color2: "#54aa27",
        color3: "#FFC30B",
        color4: "#892A84",
        color5: "#003577",
        color6: "#E20079",
        color7: "#9E1039",
        color8: "#53672A",
        color9: "#01b6d1",
        color10: "#FD9215",
    }
}
