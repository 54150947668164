import React from "react";
import Slider from "react-slick/lib";


const CarouselArrow = (props) => {
    const { onClick, type } = props;
    return (
        <button type='button' className={`carousel-icon ${type}`} onClick={onClick}/>
    );
};


const CarouselBody = (props) =>{
    const settings = {
        infinite: false,
        prevArrow: <CarouselArrow type="prev" />,
        nextArrow: <CarouselArrow type="next" />,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        swipeToSlide: true,
        responsive: [
            //breakpoints mean "from lower values up to..." -> it is upper breakpoint
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

  return (
          <Slider {...settings} className={`list-carousel list-items-${props.children.length}`}>
            {props.children}
          </Slider>
  );
};

export default CarouselBody;