//Action creators for Camps

import programsAPI from "apis/programsAPI";
import customHistory from "customHistory";


export const loadPrograms = () => async (dispatch) => {
    dispatch({type: 'LOAD_PROGRAMS_REQUEST'});

    const response = await programsAPI.getPrograms();
    dispatch({
        type: 'PROGRAMS_LOAD',
        payload: response.data
    });
};

export const loadProgram = programId => async (dispatch) => {
    dispatch({type: 'LOAD_PROGRAM_REQUEST'});
    const response = await programsAPI.getProgram(programId);
    if (response.data.length === 0) {
        customHistory.replace("/404");
    }
    dispatch({
        type: 'UI_TITLE_CHANGED',
        payload: response.data.name
    });

    dispatch({
        type: 'PROGRAM_LOAD',
        payload: response.data
    });
};
