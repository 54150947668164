import React from 'react';
import notFoundImage from 'images/404.jpg';
import SignInForm from "components/account/SignInForm";
import ResendActivationLinkForm from "components/account/ResendActivationLinkForm";
import {connect} from "react-redux";
import {uiChangedAction} from "actions/uiStateActions";
import {confirmAccount} from "actions/accountActions";

class ActivationConfirmed extends React.PureComponent {
    pageConfig = {
        title: 'Účet byl aktivován'
    };

    componentDidMount() {
        if (this.props.authenticated) {
            this.props.history.replace('/ucet');
        }

        this.props.uiChangedAction({
            title: this.pageConfig.title,
            color: '#009EE0',
            bgImage: notFoundImage
        });

        this.props.confirmAccount();
    }

    renderSuccess = () => {
        return (
            <React.Fragment>
                <h2 className="title">Účet byl aktivován</h2>
                <p><strong>Děkujeme za ověření Vašeho e-mailu</strong><br />Nyní se můžete přihlásit.</p>
                <div className="form-wrapper">
                <SignInForm />
                </div>
            </React.Fragment>
        );
    };

    renderFailure = () => {
        return (
            <React.Fragment>
                <h2 className="title">Ověření se nepodařilo</h2>
                <p>Zkontrolujte zda jste zkopírovali do adresní řádky celý odkaz</p>
            </React.Fragment>
        );
    };

    renderExpired = () => {
        return (
            <React.Fragment>
                <h2 className="title">Limit pro ověření vypršel</h2>
                <p>Platnost kódu pro ověření Vašeho účtu vypršela. Je třeba si nechat zaslat nový kód.</p>
                <ResendActivationLinkForm />
            </React.Fragment>
        );
    };

    render() {
        const {confirmationResult} = this.props;
        return (
            <section className="not-found">
                <div className="list-body" id="list-body">
                    <div className="" id="list-detail">
                        <article className={`bg-color3 list-detail text-center`}>
                            {'ok' === confirmationResult && this.renderSuccess()}
                            {'no-account' === confirmationResult && this.renderFailure()}
                            {'expired' === confirmationResult && this.renderExpired()}
                        </article>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = ({account}) => {
    return {
        authenticated: account.authenticated,
        confirmationResult: account.confirmationResult
    };
};

export default connect(mapStateToProps, {uiChangedAction, confirmAccount})(ActivationConfirmed);