import React from "react";
import DatePicker from "helpers/forms/DatePicker";

export const renderAuthField = ({input, label, type, meta: {touched, error, warning}}) => {
    return (
        <React.Fragment>
            <label>{label}</label>
            <input {...input} type={type} className={touched && error ? "with-error" : ""} />
            {touched && error &&
            <p className="error complement-color-0">{error}</p>
            }
            {touched && warning &&
            <p className="complement-color-0">{warning}</p>
            }
        </React.Fragment>
    )
};

export const renderField = ({input, label, type, customInput, meta: {touched, error}}) => {
    return (
        <React.Fragment>
            <label>{label}</label>
            <input {...input} {...customInput} type={type} className={touched && error ? "with-error" : ""} />
            {touched && error &&
            <p className="error complement-color-0">{error}</p>
            }
        </React.Fragment>
    )
};

export const renderCheckbox = ({children, input, type, customInput, meta: {touched, error}}) => {
    return (
        <label>
            <input {...input} {...customInput} type={type} className={touched && error ? "with-error" : ""} />
            {children}
            {touched && error &&
            <span className="error complement-color-0">{error}</span>
            }
        </label>
    )
};

export const renderTextarea = ({input, label, customInput, meta: {touched, error}}) => {
    return (
        <React.Fragment>
            <label>{label}</label>
            <textarea {...input} {...customInput} className={touched && error ? "with-error" : ""} />
            {touched && error &&
            <p className="error complement-color-0">{error}</p>
            }
        </React.Fragment>
    )
};

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

export const renderFile = ({
                               label, input: {value: omitValue, onChange, onBlur, ...inputProps},
                               meta: {touched, error, ...omitMeta},
                               ...props
                           }) => {
    return (
        <React.Fragment>
            {label && <label>{label}</label>}
            <input
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                accept=".pdf"
                {...props.input}
                {...props}
            />
            {touched && error &&
            <p className="error complement-color-0">{error}</p>
            }
        </React.Fragment>
    );
};


export const renderHoneypot = ({input}) => {
    return (
        <input {...input} type="text" className="confirm-field" />
    )
};

export const renderDateTimePicker = ({label, input: {onChange, value}}) => {
    return (
        <React.Fragment>
            <label>{label}</label>
            <DatePicker onChange={onChange} value={value} />
        </React.Fragment>
    )

};