import ddmbase from "apis/ddmbase";
export default{

    getCompetitions(){
        return ddmbase.get('/competitions', {}).catch((response) => console.error(response, "API ERROR: /competitions"))
    },

    getCompetition(competitionId) {
        return ddmbase.get(`/events/${competitionId}`).catch((response) => console.error(response, "API ERROR: /competitions/:id"))
    }
}