import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {closeInfoPage} from 'actions/pagesActions';

const mapStateToProps = ({infoPage}) => {
    return {infoPage}
};

export default connect(mapStateToProps,{closeInfoPage})(({infoPage, closeInfoPage}) => {
    if (!infoPage) return null;

    return ReactDOM.createPortal(
        <React.Fragment>
            <div className="modal fade show" role="dialog" onClick={closeInfoPage}>
                <div className="modal-dialog modal-dialog-centered modal-xl info-modal">
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="modal-title">{infoPage.title}</h2>
                            <button title="Zavřít" className="float-right link" onClick={closeInfoPage}>
                                <i className="fas fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="gallery-video"
                                 dangerouslySetInnerHTML={{__html: infoPage.content}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" />
        </React.Fragment>,
        document.querySelector('#modal')
    );
});