//Action creators for Courses

import coursesAPI from "apis/coursesAPI";
import config from "config";
import customHistory from 'customHistory';

export const loadCourses = () => async (dispatch, getState) => {

    dispatch({type: 'LOAD_COURSES_REQUEST'});

    const offset = 0; //getState().coursesStore.loadOffset;
    const limit = config.pagination.courses;
    const response = await coursesAPI.getCourses({offset, limit}, getState().coursesStore.selectedValues);
    dispatch({
        type: 'COURSES_LOAD',
        payload: response.data
    });
};

export const loadMore = () => async (dispatch, getState) => {
    dispatch({type: 'LOAD_MORE_COURSES_REQUEST'});

    const limit = config.pagination.courses;
    const offset = Math.max(getState().coursesStore.loadOffset, limit);
    const response = await coursesAPI.getCourses({offset, limit}, getState().coursesStore.selectedValues);
    dispatch({
        type: 'COURSES_LOAD_MORE',
        payload: response.data
    });
};

export const loadCourse = (courseId) => async (dispatch) => {
    dispatch({type: 'LOAD_COURSE_REQUEST'});

    const response = await coursesAPI.getCourse(courseId);
    if (response.data.length === 0) {
        customHistory.replace("/404");
    }

    dispatch({
        type: 'UI_TITLE_CHANGED',
        payload: response.data.name
    });
    dispatch({
        type: 'COURSE_LOAD',
        payload: response.data
    });
};

//todo tohle je asi špatně. Nebudu moci garantovat, že se akce vykonají ve správném pořadí
export const filterSelectItem = (key, value) => dispatch => {
    dispatch({
        type: 'COURSES_FILTER_SELECT',
        payload: {key, value}
    });
    dispatch(loadCourses());
};

export const filterUnselectItem = (key, value) => dispatch => {
    dispatch({
        type: 'COURSES_FILTER_UNSELECT',
        payload: {key, value}
    });
    dispatch(loadCourses());
};

export const filterClear = () => dispatch => {
    dispatch({
        type: 'COURSES_FILTER_CLEAR',
    });

    dispatch(loadCourses());
};

export const initAges = () => {
    return {
        type: 'COURSES_INIT_AGES',
        payload: coursesAPI.initAges()
    }
};

export const initHours = () => {
    return {
        type: 'COURSES_INIT_HOURS',
        payload: coursesAPI.initHours()
    }
};

export const initDays = () => async dispatch => {
    const days = await coursesAPI.initDays();
    dispatch({
        type: 'COURSES_INIT_DAYS',
        payload: days
    });
};

export const initBuildings = () => async dispatch => {
    const buildings = await coursesAPI.initBuildings();
    dispatch({
        type: 'COURSES_INIT_BUILDINGS',
        payload: buildings
    });
};

export const initCategories = () => async dispatch => {
    const categories = await coursesAPI.initCategories();
    dispatch({
        type: 'COURSES_INIT_CATEGORIES',
        payload: categories
    });
};

export const subscribe = (participantId, courseId, canLeaveAlone, substitute = false) => async dispatch => {
    dispatch({type: 'SUBSCRIBE_COURSE_REQUEST'});
    let result;
    if (substitute) {
        result = await coursesAPI.subscribeSubstitute(participantId, courseId, canLeaveAlone);
    } else {
        result = await coursesAPI.subscribe(participantId, courseId, canLeaveAlone);
    }
    dispatch({
        type: 'COURSES_SUBSCRIBED',
        payload: {participation: result.data, participantId}
    });
    dispatch({type: 'SUBSCRIBE_COURSE_LOADED'});
};

export const unsubscribe = (participationId, courseId) => async dispatch => {
    dispatch({type: 'UNSUBSCRIBE_COURSE_REQUEST'});
    const result = await coursesAPI.unsubscribe(participationId, courseId);
    //todo nějak ošetřit false stav z api - možná zatím jen logovat
    if (result.data) {
        dispatch({
            type: 'COURSES_UNSUBSCRIBED',
            payload: result.data
        });
    }
    dispatch({type: 'UNSUBSCRIBE_COURSE_LOADED'});
};

export const uploadApplicationForm = ({courseParticipationId}, formData) => async dispatch => {
    dispatch({type: 'UPLOAD_FORM_REQUEST'});
    const result = await coursesAPI.uploadApplicationForm(courseParticipationId, formData.file);
    dispatch({type: 'UPLOAD_FORM_LOADED'});
    if (200 === result.status) {
        dispatch({
            type: 'COURSE_UPLOADED',
            payload: courseParticipationId
        });
        return true;
    }
    return false;
};

export const clearSubscriptionState = () => {
    return {
        type: 'COURSES_SUBSCRIPTION_CLEAR'
    }
};