/**
 * Change structure of the post object returned from REST API to simplify working with object data
 * @param post
 * @returns {{path: string, color: {code: string, className: *}, externalLink: *, featuredImage: null, title: *, content: *}}
 */
export const simplifyRESTPost = post => {
    const imageMatches = /\S+\s\((.+)\)/iu.exec(post.color.label);
    const featureMedia = post._embedded['wp:featuredmedia'];

    let featuredImage = null;
    if (featureMedia && featureMedia.length > 0 && featureMedia[0].media_details) {
        featuredImage = {
            thumbnail: featureMedia[0].media_details.sizes.thumbnail ?
                featureMedia[0].media_details.sizes.thumbnail.source_url :
                featureMedia[0].source_url,
            full: featureMedia[0].media_details.sizes.full ?
                featureMedia[0].media_details.sizes.full.source_url :
                featureMedia[0].source_url,
        }
    }

    const pathMatches = /https?:\/\/[\w+.]+(\/.*)/.exec(post.link);

    return {
        ...post,
        title: post.title.rendered,
        content: post.content.rendered,
        externalLink: post.external_link,
        color: {
            className: post.color.value,
            code: imageMatches && imageMatches.length === 1 ? imageMatches[1] : 0
        },
        path: pathMatches[1],
        featuredImage
    }
};

export const simplifyRESTPosts = posts => {
    return posts.map( post => simplifyRESTPost(post));
};

