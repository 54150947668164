import Filter from 'components/lists/Filter';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";

import {filterOpenAction} from "actions/uiStateActions";
import {filterClear, filterSelectItem, filterUnselectItem, initMonths, initYears} from "actions/galleriesActions";

class GalleryFilter extends Filter {

    config = [
        {keyName: 'years', title: 'Rok'},
        {keyName: 'months', title: 'Měsíc'},
    ];

    //todo neslo by to nejak extrahovat do predka?
    constructor(props) {
        super(props);
        //todo pokud by tohle mohlo být jinde, můžu se zbavit classBase componenty
        this.props.initYears(props.galleryType);
        this.props.initMonths(props.galleryType);
    }

}

const mapStateToProps = ({galleries}, {galleryType}) => {
    return {
        allValues: galleries[galleryType].allValues,
        selectedValues: galleries[galleryType].selectedValues
    }
};

/**
 * Dedim z tridy Filter, ktera pouziva funce filterSelectItem a filterUnselectItem, ktere vola pouze s parametry key a value
 * Tento filtr je pouzity pro video i foto galerri a pro rozliseni typu a tedy i typu filtru se pouziva parametr, ktery je treba
 * metodam predat. Tato funkce tedy slouzi jako wrapper (decorator), ktery toto umoznuje
 * @param dispatch
 * @param galleryType
 */
const mapDispatchToProps = (dispatch, {galleryType}) => {
    return bindActionCreators({
        filterOpenAction,
        initMonths, initYears,
        filterSelectItem: (key,value) => filterSelectItem(galleryType,key, value), //tady predavam navic typ
        filterUnselectItem: (key, value) => filterUnselectItem(galleryType,key,value),
        filterClear: () => filterClear(galleryType)
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryFilter);