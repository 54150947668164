import React from 'react';
import ListDetail from "components/lists/ListDetail";
import DetailWrapper from "components/lists/DetailWrapper";
import closeSubpages from "helpers/closeSubpage";
import Helmet from "react-helmet";

class PostDetail extends ListDetail {

    onEventClosed = () => {
        closeSubpages(this.props.post.path);
        this.onClosed(this.props.post.id);
    };


    renderHelmet() {
        const {post, parentPage} = this.props;
        if (post) {
            return (<Helmet>
                {post.excerpt &&
                <meta name="description" content={post.excerpt} /> }
                <meta property='og:title' content={`${post.title}${parentPage ? ` : ${parentPage.title}`: ''}`} />
                {post.featuredImage &&
                <meta property='og:image' content={post.featuredImage.thumbnail} /> }
                <link rel='canonical' href={`${process.env.REACT_APP_BASE_URL}${post.path}`} />
            </Helmet>);
        }
    }

    render() {
        const {
            title, content, path, color
        } = this.props.post;

        let bgColor = 'bg-color';
        if (color.className && color.className.length > 0) {
            bgColor = `bg-${color.className}`;
        }

        const wrapperInfo = {
            ref: this.detailElement,
            link: path,
            onCloseHandler: this.onEventClosed,
            sticky: this.props.sticky,
            bgColor
        };

        return (
            <DetailWrapper {...wrapperInfo}>
                {this.renderHelmet()}
                {this.renderResizeHandler()}
                <div className='col col-md-11 col-lg-10'>
                    <h2 className="title">{title}
                        <button title="Zavřít" className="float-right link d-md-none" onClick={this.onEventClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                    </h2>
                <div dangerouslySetInnerHTML={{__html: content}} />
                </div>
            </DetailWrapper>
        );
    }

}

export default PostDetail;
