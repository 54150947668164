import React from 'react';
import {connect} from "react-redux";

import List from "components/lists/List";

import {uiChangedAction} from "actions/uiStateActions";
import {loadCompetitions} from "actions/competitionsActions";
import EventDetail from "components/events/EventDetail";
import EventItem from "components/events/EventItem";

import competitionsImage from 'images/competitions.jpg';
import config from 'config';

class CompetitionsList extends List {

    pageConfig = {
        title: 'Soutěže',
        color: config.colors.color5,
        bgImage: competitionsImage
    };

    state = {
        selectedItem: null,
        selectedIndex: null,
    };

    componentDidMount() {
        this.props.loadCompetitions();
        this.props.uiChangedAction(this.pageConfig);
    }

    renderCompetitions() {
        return this.props.competitions.map((competition,index) => {
            return <EventItem key={competition.eventId} event={competition} itemIndex={index}
                              pathPrefix='skoly/souteze'
                                selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemSelect} />;
        })
    }

    render() {
        const listLength = this.props.competitions.length;
        return (
            <React.Fragment>
                <section className="list">
                    <div className="list-body" id="list-body">
                        {listLength > 0 && this.renderCompetitions()}
                        {this.state.selectedItem != null &&
                            <EventDetail pathPrefix='skoly/souteze' event={this.state.selectedItem} changePosition={this.positionDetail}
                                        onItemClosed={this.onClosed} />
                        }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({competitionsStore}) => {
    return {
        competitions: competitionsStore.competitions,
    }
};

export default connect(mapStateToProps,{uiChangedAction, loadCompetitions})(CompetitionsList);