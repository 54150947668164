import React from 'react';
import list_placeholder from 'images/list-placeholder.png';
import ListItem from "components/lists/ListItem";

class GalleryItem extends ListItem {

    constructor(props, context) {
        super(props, context);

        this.state = {smallImageUrl: list_placeholder};

        //preload image
        this.preloadImage.src = this.props.gallery.smallImageUrl;
        this.preloadImage.addEventListener('load', this.setLoadedImage);
    }

    render() {
        const {
            title, galleryId, date, description, url, videoGalleryId
        } = this.props.gallery;

        const dateFromAsDate = new Date(date);
        return (
            <article className={`list-item ${this.props.selected ? 'detail-opened':''}`} id={`item-${galleryId}`}>
                <a href={url} target='_blank' rel="noreferrer noopener" title={title} className="link"
                onClick={(event) => this.props.onClick(event,this.props.gallery)}
                >
                    <div className="content bg-color">
                        <header>
                            <span className="supheadline">{dateFromAsDate.toLocaleDateString()}</span>
                            <h3 className="headline">{title}</h3>
                        </header>
                        {!videoGalleryId &&
                            <div className="description" dangerouslySetInnerHTML={{__html: description}} />
                        }
                    </div>
                    <div className="image-wrap bg-color">
                        <img src={this.state.smallImageUrl} className="image" alt={title} />
                    </div>
                </a>
            </article>
        );
    }
}

export default GalleryItem;