
export default(state = 0, {type}) =>{

    const matches = /(.*)_(REQUEST|LOAD|LOAD_MORE|404|LOADED)/.exec(type);
    if (!matches) return state;

    const [, , requestState] = matches;
    const newState = requestState === 'REQUEST' ? ++state : --state;
    //fix to avoid have fetching < 0
    return Math.max(newState,0);
}