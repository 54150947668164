import config from 'config';
import ddmbase, {convertFilterKeyNames,initAges} from "apis/ddmbase";
export default{

    initAges() {
        return initAges(3);
    },

    initMonths() {
        const months = [];
        for (let i = 2; i <= 9; i++) {
            months.push({id: i, value: config.l10n.monthsNames[i]});
        }
        return months;
    },

    initCategories(){
        return [
            {id: 1, value: 'jarní'},
            {id: 2, value: 'velikonoční'},
            {id: 3, value: 'letní'},
            {id: 4, value: 'podzimní'},
            {id: 5, value: 'ostatní'},
        ];
    },

    getCamps(params,filter = null){
        const filledFilter = convertFilterKeyNames(filter);
        return ddmbase.get('/camps', {
            params: {...params, ...filledFilter}
        }).catch((response) => console.error(response, "API ERROR: /camps"))
    },

    getCamp(campId) {
        return ddmbase.get(`/camps/${campId}`, {}).catch((response) => console.error(response, "API ERROR: /camps/:id"))
    }
}