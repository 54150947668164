import React from 'react';
import ListItem from "components/lists/ListItem";
import image from 'images/list-placeholder.png';
import ListDetail from "components/lists/ListDetail";
import {AccountDetailWrapper} from "components/lists/DetailWrapper";
import AccountForm from "components/account/forms/AccountForm";

export class AccountItem extends ListItem {
    render() {
        return (
            <article className={`list-item link bg-color2 ${this.props.selected ? 'detail-opened' : ''}`}
                     id={`account-item-${this.props.itemIndex}`}
                     onClick={(e) => {
                         e.preventDefault();
                         this.onClickHandler(null, this.props.itemIndex)
                     }}>
                <div className="content">
                    <header>
                        <h3 className="headline">Nastavení</h3>
                    </header>
                    <div className="description">
                        Změna hesla
                    </div>
                </div>
                <div className="image-wrap bg-color2">
                    <img src={image} className="image" alt="Můj účet" />
                </div>
            </article>
        );
    }
}

export class AccountDetail extends ListDetail {
    onDetailClosed = () => {
        this.onClosed(this.props.itemIndex);
    };


    render() {
        const wrapperInfo = {
            ref: this.detailElement,
            onCloseHandler: (this.onDetailClosed),
            bgColor: 'color2'
        };

        return (
            <AccountDetailWrapper {...wrapperInfo}>
                {this.renderResizeHandler()}
                <div className='col'>
                    <h2 className="title">Můj účet
                        <button title="Zavřít" className="float-right link d-md-none"
                                onClick={this.onDetailClosed}>
                            <i className="fas fa-times" /><span className="sr-only">Zavřít</span>
                        </button>
                    </h2>
                    <div className="form-wrapper">
                        <AccountForm />
                    </div>
                </div>
            </AccountDetailWrapper>
        );
    }
}