import React from 'react';
import {Link} from "react-router-dom";
import logo_praha from 'images/logo-praha.png';
import logo_prazskeddm from 'images/logo-prazske-ddm.png';
import logo_zonerama from 'images/logo-zonerama.png';
import RoutesConfig from 'routes';
import ReactGA from "react-ga";
import customHistory from 'customHistory';

class Footer extends React.Component{

    state = {
        selectedMenu: null
    };

    componentDidMount() {
        customHistory.listen(() => this.setState({selectedMenu: null}));
    }

    openSubmenu = (event,index) => {
        event.preventDefault();
        this.setState({selectedMenu: index});
    };

    render(){
        const iconsConfig = {
            className: "partner", target: "_blank", rel: "noreferrer noopener"
        };

        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <h3>Dům dětí a mládeže Jižní Město</h3>
                            <address className="address">
                                Šalounova 2024/3<br />
                                149 00 Praha 4<br />
                                <a href="mailto:sal@ddmjm.cz">sal@ddmjm.cz</a><br />
                                <a href="tel:+420778473546">778 473 546</a>
                            </address>
                            <address className="address">
                                Květnového vítězství 2126/36<br />
                                149 00 Praha 4<br />
                                <a href="mailto:kve@ddmjm.cz">kve@ddmjm.cz</a><br />
                                <a href="tel:+420778473546">778 485 161</a>
                            </address>
                            <div className="address">
                                IČO: 45241643<br />
                                Bankovní spojení: 9630041/0100<br />
                                Datová schránka: zri2yuz
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div
                                className="partners d-flex justify-content-center justify-content-lg-between align-items-center flex-wrap">
                                <a href="http://www.praha.eu/" {...iconsConfig}>
                                    <img src={logo_praha} alt="Logo hl. m. Prahy" /></a>
                                <a href="http://www.volnycaspraha.cz/" {...iconsConfig}>
                                    <img src={logo_prazskeddm}
                                         alt="Logo Pražských domů dětí a mládeže" /></a>
                                <a href="http://ddmjm.zonerama.com/" {...iconsConfig}>
                                    <img src={logo_zonerama} alt="Logo Zonerama" /></a>
                                <a href="http://www.youtube.com/user/DDMJizniMesto" {...iconsConfig}>
                                    <i className="fab fa-youtube fa-3x" /></a>
                                <a href="http://www.facebook.com/DDM11" {...iconsConfig}>
                                    <i className="fab fa-facebook-square fa-3x" /></a>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-2 nav-footer">
                            <h3>MENU</h3>
                            <nav id="navigation">
                                <ul>
                                    {RoutesConfig.map((item,index) => <FooterLink key={`footer-menu-${index}`}
                                                                                  index={index}
                                                                                  {...item}
                                                                                  openSubmenu={this.state.selectedMenu === index}
                                                                                  onClick={() => this.setState({selectedMenu: null})}
                                                                                  onSubmenuClick={(event) => this.openSubmenu(event,index)}
                                    />)}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


const FooterLink = ({path,title, subroutes, onClick, onSubmenuClick, openSubmenu}) => {
    const children = subroutes ? subroutes.map((item) => <FooterLink key={item.path} {...item} />) : [];

    const footerOnClick = (e) => {
        if(onClick) {
            onClick(e);
        }
        ReactGA.event({
            category: 'Menu v paticce',
            action: 'Click',
            label: title
        });
    };

    return (
        <li key={path}>
            {children.length === 0 &&
                <Link to={path} onClick={footerOnClick} className="link">{title}</Link>
            }
            {children.length > 0 &&
                <React.Fragment>
                    <button onClick={onSubmenuClick} className="link">{title}</button>
                    <ul className={`submenu ${openSubmenu ? 'open':''}`}>{children}</ul>
                </React.Fragment>
            }
        </li>
    );
};

export default Footer;
