
export default (prevState = {}, {type, payload}) => {
    switch (type) {
        case 'UI_MENU_STATE_CHANGED':
            return {...prevState, menuOpen: payload};

        case 'UI_FILTER_STATE_CHANGED':
            return {...prevState, filterOpen: payload};

        case 'UI_TITLE_CHANGED':
            return {...prevState, title: payload};

        case 'UI_COLOR_CHANGED':
            return {...prevState, color: payload};

        case 'UI_BG_IMAGE_CHANGED':
            return {...prevState, bgImage: payload};

        case 'UI_TITLE_COLOR_CHANGED':
            return {...prevState, ...payload};

        case 'AUTH_NOTIFY_LOGOUT':
            return {...prevState, sessionExpired: payload};

        default:
            return prevState;
    }
}