import React from 'react';
import List from "components/lists/List";
import {connect} from "react-redux";
import {isEmpty} from 'lodash';

import {uiChangedAction} from "actions/uiStateActions";
import {fetchBlog} from 'actions/postsActions';
import PostItem from "components/posts/PostItem";
import PostDetail from "components/posts/PostDetail";
import closeSubpages from "helpers/closeSubpage";
import customHistory from 'customHistory';
import eventsImage from "images/events.jpg";
import config from 'config';

class BlogList extends List {

    state = {
        selectedItem: null,
        selectedIndex: null
    };

    pageConfig = {
        title: 'Ze života DDM JM',
        color: config.colors.color9,
        bgImage: eventsImage
    };

    onItemClick = (selectedItem, selectedIndex, itemId) => {
        this.onItemSelect(selectedItem, selectedIndex, itemId);
        if(this.state.selectedIndex === selectedIndex){
            closeSubpages(selectedItem.path);
        }
    };

    componentDidMount() {
        this.props.uiChangedAction(this.pageConfig);
        this.props.fetchBlog(100);
    }

    componentDidUpdate({match: prevMatch}) {
        if(this.props.location.search === '?vytisknout' && this.state.selectedItem != null){
            customHistory.replace(this.props.location.pathname);
            window.print();
        }

        const {selectedItem, selectedIndex} = this.props;
        if (!isEmpty(selectedItem) && this.state.selectedItem == null) {
            this.onItemSelect(selectedItem,selectedIndex);
        }
    }

    renderPosts() {
        return this.props.blogPosts.map((page, index) => {
            return <PostItem key={page.id} post={page} itemIndex={index}
                               selected={index === this.state.selectedIndex}
                               onItemSelect={this.onItemClick} />;
        })
    }

    render() {
        return (
            <React.Fragment>
                <section className={`list page-list ${this.state.selectedItem != null ? 'page-open' : ''}`}>
                    <div className="list-body" id="list-body">
                        {this.props.blogPosts.length > 0 && this.renderPosts()}
                        {this.state.selectedItem != null &&
                        <PostDetail post={{...this.state.selectedItem,path: `/ze-zivota-ddm/${this.state.selectedItem.slug}`}}
                                    changePosition={this.positionDetail}
                                      onItemClosed={this.onClosed} />
                        }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state,ownProps) => {
    const {homepage: {blog}} = state;
    const postDetailSlug = ownProps.match.params.slug;
    let selectedItem, selectedIndex = null;
    if(postDetailSlug){
        selectedItem = blog.find(post => post.slug === postDetailSlug);
        selectedIndex = blog.findIndex(post => post.slug === postDetailSlug);
    }

    return {
        blogPosts: blog,
        isFetching: state.isFetching !== 0,
        selectedItem,
        selectedIndex: selectedIndex >= 0 ? selectedIndex : null
    }
};

export default connect(mapStateToProps, {uiChangedAction, fetchBlog})(BlogList);
