import wpapi from 'apis/wordressAPI';
import {simplifyRESTPost, simplifyRESTPosts} from 'helpers/simplifyRESTPost';
import customHistory from 'customHistory';
import {flattenRoutesConfig} from 'routes';

export const fetchPage = slug => async dispatch => {
    dispatch({type: 'LOAD_PAGE_REQUEST'});

    const {pathname} = customHistory.location;
    const page = flattenRoutesConfig.find(({path}) => {
        const compareWith = pathname.endsWith('/') ? pathname.slice(0,-1) : pathname;
        return path === compareWith;
    });

    if (page && page.index) {
        slug = page.path.split("/").pop();
    }

    const response = await wpapi.fetchPages({
        slug: slug,
        _embed: true
    });

    if (200 === response.status && response.data.length > 0) {
        const page = response.data[0];
        dispatch({
            type: 'PAGE_LOAD',
            payload: simplifyRESTPost(page)
        });

        dispatch(fetchSubpages(page.id));

    } else if (404 === response.status || response.data.length === 0) {
        dispatch({
            type: 'PAGE_404',
        });
        customHistory.replace('/404');
    } else {
        //todo
    }
};

export const fetchSubpages = parentId => async dispatch => {
    dispatch({type: 'LOAD_PAGES_REQUEST'});

    const response = await wpapi.fetchPages({
        parent: parentId,
        _embed: true
    });

    dispatch({
        type: 'SUBPAGES_LOAD',
        payload: simplifyRESTPosts(response.data)
    });
};

export const fetchInfoPage = id => async dispatch => {
    dispatch({type: 'LOAD_PAGE_REQUEST'});

    const response = await wpapi.fetchPage(id, {
        _embed: true
    });

    if (200 === response.status) {
        dispatch({
            type: 'INFO_PAGE_LOAD',
            payload: simplifyRESTPost(response.data)
        });

    }
};

export const closeInfoPage = () => {
    return {
        type: 'INFO_PAGE_CLOSE'
    }
};