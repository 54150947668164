import React from 'react';
import List, {ListHeader} from "components/lists/List";
import {connect} from "react-redux";

import {uiChangedAction} from "actions/uiStateActions";

import CampDetail from "components/camps/CampDetail";
import {loadCamp} from "actions/campsActions";
import {Link, Redirect} from "react-router-dom";
import customHistory from "customHistory";
import Camp from "entities/Camp";
import Helmet from "react-helmet";
import campsImage from "images/camps.jpg";
import config from 'config';

class CampsDetailPage extends List {

    pageConfig = {
        title: 'Tábory',
        color: config.colors.color1,
        bgImage: campsImage
    };

    componentDidMount() {
        this.props.loadCamp(this.props.match.params.id);
        this.props.uiChangedAction(this.pageConfig);
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.camp && this.props.history.location.search === '?vytisknout') {
            customHistory.replace(this.props.location.pathname);
            window.print();
        }
    }

    renderHelmet(camp) {
        return (<Helmet>
            <meta name="description"
                  content={`Tábor ${this.props.camp.name} pro děti ve věku ${camp.renderAge()} ve dnech ${camp.renderDate()}, ${this.props.camp.place}`} />
            <meta property='og:title' content={this.props.camp.name} />
            <meta property='og:image' content={this.props.camp.smallImageUrl} />
            <link rel='canonical' href={`${process.env.REACT_APP_BASE_URL}${camp.getPermalink()}`} />
        </Helmet>);
    }

    render() {
        if (!this.props.camp) {
            return null;
        }

        const camp = new Camp(this.props.camp);
        const permalink = camp.getPermalink();
        if (customHistory.location.pathname !== permalink) {
            return (<Redirect to={permalink} />);
        }

        return (
            <React.Fragment>
                {this.renderHelmet(camp)}
                <section className="list">
                    <ListHeader withoutFilter />
                    <div className="list-body" id="list-body">
                        {this.props.camp != null &&
                        <CampDetail camp={this.props.camp} sticky />
                        }
                    </div>
                    <footer className="list-footer">
                        <nav className="list-actions">
                            <ul>
                                <li>
                                    <Link to='/tabory' className='button'>Zobrazit všechny tábory</Link>
                                </li>
                            </ul>
                        </nav>
                    </footer>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({campsStore}) => {
    return {
        camp: campsStore.camp,
    }
};

export default connect(mapStateToProps, {uiChangedAction, loadCamp})(CampsDetailPage);