import React from 'react';
import { connect } from "react-redux";

import {filterOpenAction} from "actions/uiStateActions";
import scrollToY from "helpers/scrollToYPosition";

class List extends React.Component {

    //items have fixed width same on all viewports
    listItemWidth = 216 + 8; //8 = padding

    //default config values
    pageConfig = {
        title: 'TODO',
        color: '#FFF',
        bgImage: null
    };

    positionDetail = (element) => {
        //calculate how many items per line are in row for current screen width
        const itemsPerLine = Math.floor(document.getElementById('list-body').clientWidth / this.listItemWidth);
        const items = document.getElementById('list-body').getElementsByClassName('list-item');
        //calculate position of the detail element supposing each line contains itemsPerLine items
        const calculatedPosition = Math.ceil((this.state.selectedIndex + 1) / itemsPerLine) * itemsPerLine;
        const realPosition = Math.min(items.length,calculatedPosition);
        //place on the position
        items[realPosition - 1].insertAdjacentElement('afterend', element);
        //show
        element.style['display'] = 'block';
    };

    onClosed = () => {
        //remove active state from list item
        this.setState({
            selectedItem: null,
            selectedIndex: null
        })
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.isFetching && this.props.isFetching) {
            this.setState({selectedIndex: null, selectedItem: null});
        }
    }


    //list item was clicked
    onItemSelect = (selectedItem, selectedIndex, itemId) => {
        //already opened
        if(selectedIndex === this.state.selectedIndex){
            return this.onClosed(itemId);
        }

        this.setState({selectedItem, selectedIndex});

        //scroll to opened detail
        let yposition = document.getElementById("list-body").childNodes.length === 0 ?
            document.getElementById("list-body").offsetTop :
            document.getElementById("list-body").childNodes[selectedIndex].offsetTop;

        const detailElement = document.getElementById("list-detail");
        if (detailElement != null && detailElement.offsetTop < yposition) {
            yposition -= detailElement.clientHeight;
        }
        yposition += 140; //correction
        scrollToY(yposition, 300);
    };

    render() {
        return null;
    }
}

const ListHeader = connect(null, {filterOpenAction})((props) => {
    return (
        <header className="list-header">
            <nav className="list-actions">
                <ul className="list-navigation">
                    {props.children}
                    {props.withoutFilter !== true &&
                    <li>
                        <button className={`button ${props.filterActive ? 'active' : ''}`}
                                onClick={() => props.filterOpenAction(true)}>Filtr
                        </button>
                    </li>
                    }
                </ul>
            </nav>
        </header>
    );
});

const ListFooter = ({canLoadMore, onClickHandler, itemsPerPage}) => {
  return (
      <footer className="list-footer">
          <nav className="list-actions">
              <ul>
                  {canLoadMore &&
                  <li>
                      <button className="button" onClick={onClickHandler}>
                          Zobrazit dalších {itemsPerPage}
                      </button>
                  </li>
                  }
              </ul>
          </nav>
      </footer>
  );
};

const EmptyList = connect(null, {filterOpenAction})((props) => {
    return (
    <div className="info-message">
        <p>{props.children}</p>
        <nav className="list-actions">
        <ul>
            <li><button className='button' onClick={() => props.filterOpenAction(true)}>Upravit filtr</button></li>
            <li><button className='button' onClick={props.filterClear}>Vymazat filtr</button></li>
        </ul>
        </nav>
    </div>
    );
});

export {ListHeader, ListFooter, EmptyList};
export default List;