//Action creators for Camps

import campsAPI from "apis/campsAPI";
import config from "config";
import customHistory from "customHistory";


export const loadCamps = () => async (dispatch, getState) => {

    dispatch({type: 'LOAD_CAMPS_REQUEST'});

    const offset = 0;
    const limit = config.pagination.camps;
    const response = await campsAPI.getCamps({offset, limit}, getState().campsStore.selectedValues);
    dispatch({
        type: 'CAMPS_LOAD',
        payload: response.data
    });
};

export const loadMore = () => async (dispatch, getState) => {

    dispatch({type: 'LOAD_MORE_CAMPS_REQUEST'});

    const limit = config.pagination.camps;
    const offset = Math.max(getState().campsStore.loadOffset, limit);
    const response = await campsAPI.getCamps({offset, limit}, getState().campsStore.selectedValues);
    dispatch({
        type: 'CAMPS_LOAD_MORE',
        payload: response.data
    });
};

export const loadCamp = (campId) => async dispatch => {
    dispatch({type: 'LOAD_CAMP_REQUEST'});

    const response = await campsAPI.getCamp(campId);
    if (response.data.length === 0) {
        customHistory.replace("/404");
    }
    dispatch({
        type: 'UI_TITLE_CHANGED',
        payload: response.data.name
    });
    dispatch({
        type: 'CAMP_LOAD',
        payload: response.data
    });
};

//todo tohle je asi špatně. Nebudu moci garantovat, že se akce vykonají ve správném pořadí
export const filterSelectItem = (key, value) => dispatch => {
    dispatch({
        type: 'CAMPS_FILTER_SELECT',
        payload: {key, value}
    });
    dispatch(loadCamps());
};

export const filterUnselectItem = (key, value) => dispatch => {
    dispatch({
        type: 'CAMPS_FILTER_UNSELECT',
        payload: {key, value}
    });
    dispatch(loadCamps());
};

export const filterClear = () => dispatch => {
    dispatch({
        type: 'CAMPS_FILTER_CLEAR',
    });

    dispatch(loadCamps());
};

export const initAges = () => {
    return {
        type: 'CAMPS_INIT_AGES',
        payload: campsAPI.initAges()
    }
};

export const initMonths = () => {
    return {
        type: 'CAMPS_INIT_MONTHS',
        payload: campsAPI.initMonths()
    }
};

export const initCategories = () => {
    return {
        type: 'CAMPS_INIT_CATEGORIES',
        payload: campsAPI.initCategories()
    }
};