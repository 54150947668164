import ddmbaseAPI, {convertFilterKeyNames, initAges, uploadApplicationForm} from "./ddmbase";

export default {

    initAges: () => {
        return initAges(1);
    },

    initHours: () => {
        const hours = [];
        for (let i = 8; i <= 22; i++) {
            hours.push({id: i, value: i});
        }
        return hours;
    },

    initDays: () => {
        return ddmbaseAPI.get('/courses/days')
            .catch((response) => console.error(response, "API ERROR: /courses/days"))
    },

    initCategories: () => {
        return ddmbaseAPI.get('/courses/categories')
            .catch((response) => console.error(response, "API ERROR: /courses/categorie"))
    },

    initBuildings: () => {
        return ddmbaseAPI.get('/courses/buildings')
            .catch((response) => console.error(response, "API ERROR: /courses/buildings"))
    },

    getCourses(params, filter = null){
        const filledFilter = convertFilterKeyNames(filter);
        return ddmbaseAPI.get('/courses',{
            params: {...params,...filledFilter}
        }).catch((response) => console.error(response, "API ERROR: /courses"));
    },

    getCourse(courseId) {
        return ddmbaseAPI.get(`/courses/${courseId}`).catch((response) => console.error(response, "API ERROR: /courses/:id"));
    },

    subscribe(participantId,courseId, canLeaveAlone){
        return ddmbaseAPI.get(`/courses/${courseId}/subscribe/${participantId}`, {params: {canLeaveAlone}})
            .catch((response) => console.error(response, "API ERROR: /courses/:id/subscribe/:id"));
    },
    subscribeSubstitute(participantId, courseId, canLeaveAlone) {
        return ddmbaseAPI.get(`/courses/${courseId}/subscribe-substitute/${participantId}`, {params: {canLeaveAlone}})
            .catch((response) => console.error(response, "API ERROR: /courses/:id/subscribe-substitute/:id"));
    },
    unsubscribe(participationId, courseId) {
        return ddmbaseAPI.get(`/courses/${courseId}/unsubscribe/${participationId}`)
            .catch((response) => console.error(response, "API ERROR: /courses/:id/unsubscribe/:id"));
    },
    uploadApplicationForm(participationId, file){
        return uploadApplicationForm('course',participationId, file);
    }

}