/*eslint no-useless-escape: off */

export const validateRequired = value => value ? undefined : "Vyplňte prosím";
export const validateRequiredIfAdult = (value, allValues) => isAdult(allValues.dateOfBirth) && !value ? "Vyplňte prosím" : undefined;

//todo proč nešlo?: validateRequired = message => value => value ? undefined : message;
export const validateRequiredEmail = value => value ? undefined : "Ještě je třeba vyplnit e-mail";
export const validateRequiredPassword = value => value ? undefined : "Nezapomeňte na heslo";

export const validateEmail = value => {
  return !value || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}))$/giu.test(value) ? undefined : "E-mail nemá správný formát";
};

export const validateZipCode = value => {
  return value && value.toString().length === 5 && /^[0-9]{5}$/g.test(value) ? undefined : "PSČ zadejte jako číslo bez mezer";
};

export const validateMinLength = (message,minLength) => value => !value || value.length >= minLength ? undefined : message;
export const validatePasswordLength = value => validateMinLength("Heslo musí mít alespoň 8 znaků",8)(value);

export const validateSamePassword = (value, allValues) => {
  return allValues.password === allValues.passwordConfirm ? undefined : 'Hesla nejsou stejná';
};

  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

export const validatePasswordStrength = value => {
  return !value || mediumRegex.test(value) ? undefined : "Heslo je příliš slabé. Zadejte minimálně 8 znaků s alespoň jedním číslem a velkým písmenem.";
};

export const warnPasswordStrength = value => {
  //when password doesn't match any criteria, returns undefined because validatePasswordStrength method notify user about it
  return mediumRegex.test(value) ? (strongRegex.test(value) ? 'Heslo je dostatečně bezpečné' : 'Heslo není přiliš silné. Doporučujeme použít kombinaci velkých a malých znaků, čísel a speciálních znaků jako !#$;-_. o délce alespoň 10 znaků') : undefined;
};

export const validateFileSize = value => value.size > 4*1024*1024 ? "Nahrajte prosím soubor menší než 4 MB" : undefined;

const isAdult = dateAsString => {
  if(!dateAsString) return false;
  //format DD/MM/YYYY
  const dateParts = dateAsString.split("/");

  //if date is not fully filled -> assume is not adult
  if(dateParts.length < 3) return false;

  const date = new Date();
  date.setDate(dateParts[0]);
  date.setMonth(dateParts[1]-1); //months are 0-indexed
  date.setFullYear(dateParts[2]);
  return ((new Date().getTime() - date.getTime()) / 1000 / 60 / 60 / 24 / 365) >= 18;
};