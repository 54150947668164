import ServiceForKids from "entities/ServiceForKids";

class Program extends ServiceForKids{


    constructor(program) {
        super(program, program.programId);
    }

    getPermalink() {
        return super.getPermalink('skoly/programy');
    }
}

export default Program;