import React from 'react';
import {Link} from 'react-router-dom';
import list_placeholder from 'images/list-placeholder.png';
import Course from "entities/Course";
import ListItem from "components/lists/ListItem";

class CourseItem extends ListItem {


    constructor(props, context) {
        super(props, context);

        this.state = {smallImageUrl: list_placeholder};

        //preload image
        this.preloadImage.src = this.props.course.smallImageUrl;
        this.preloadImage.addEventListener('load', this.setLoadedImage);
    }

    render() {
        const {
            name, courseId, lectureDuration, priceFirstTerm, priceSecondTerm, price
        } = this.props.course;
        const course = new Course(this.props.course);

        return (
            <article className={`list-item ${this.props.selected ? 'detail-opened':''}`} id={`item-${courseId}`}>
                <Link to={course.getPermalink()} title={name} className="link" onClick={(e) => {
                    e.preventDefault();
                    this.onClickHandler(courseId, this.props.course)
                }}>
                    <div className="content bg-color">
                        <header>
                            <span className="supheadline">{course.renderTime()}</span>
                            <h3 className="headline">{name}</h3>
                        </header>
                        <div className="description">
                            <span title="Věková skupina" className="tooltip">{course.renderAge()}</span><br />
                            <span title={`Cena za první pololeté / druhé pololetí${price ? ' / při platbě na rok':''}`}
                                  className="tooltip">
                                {`${priceFirstTerm} / ${priceSecondTerm}${price ? '/ ' + price : ''}`}
                            </span><br />
                            <span title="Trvání lekce" className="tooltip">{lectureDuration}</span><br />
                            <span title="Místo konání" className="tooltip">{course.renderBuildings()}</span>
                        </div>
                    </div>
                    <div className="image-wrap bg-color">
                        <img src={this.state.smallImageUrl} className="image" alt={name} />
                    </div>
                </Link>
            </article>
        );
    }
}

export default CourseItem;