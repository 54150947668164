const INITIAL_STATE = {
    photos: {
        galleries: [],
        selectedValues: {},
        allValues: {},
        loadOffset: 0
    },
    videos: {
        galleries: [],
        selectedValues: {},
        allValues: {},
        loadOffset: 0
    },
};

export default (state = INITIAL_STATE, {type,payload}) => {

    //check if type contains photos or videos related action
    const matches = type.match(/(PHOTOS|VIDEOS)_[\w_]+/);
    //skip if not (and return state)
    if(matches === null || matches.length === 0){
        return state;
    }

    const galleryType = matches[1].toLowerCase(); //for some reasons at 0 position is full matching string (matches[0] == type)

    let {selectedValues} = state[galleryType];
    let newState = state[galleryType];

    switch(type){
        case 'PHOTOS_GALLERY_LOAD':
        case 'VIDEOS_GALLERY_LOAD':
            newState.loadOffset = payload.length;
            newState.galleries = payload;
            break;
        case 'PHOTOS_GALLERY_LOAD_MORE':
        case 'VIDEOS_GALLERY_LOAD_MORE':
            newState.loadOffset = newState.galleries.length + payload.length;
            newState.galleries = [...newState.galleries,...payload];
            break;
        case 'PHOTOS_GALLERY_FILTER_SELECT':
        case 'VIDEOS_GALLERY_FILTER_SELECT':
            selectedValues[payload.key] = [...state[galleryType].selectedValues[payload.key] || [], payload.value];
            newState.loadOffset = 0;
            newState.selectedValues = {...state[galleryType].selectedValues,...selectedValues}
            break;
        case 'PHOTOS_GALLERY_FILTER_UNSELECT':
        case 'VIDEOS_GALLERY_FILTER_UNSELECT':
            const index = state[galleryType].selectedValues[payload.key].indexOf(payload.value);
            selectedValues[payload.key] = [
                ...state[galleryType].selectedValues[payload.key].slice(0, index),
                ...state[galleryType].selectedValues[payload.key].slice(index + 1)
            ];
            newState.loadOffset = 0;
            newState.selectedValues = {...state[galleryType].selectedValues, ...selectedValues}

            break;
        case 'PHOTOS_GALLERY_FILTER_CLEAR':
        case 'VIDEOS_GALLERY_FILTER_CLEAR':
            newState = {
                ...newState,
                loadOffset: 0,
                selectedValues: {}
            };
            break;
        case 'PHOTOS_GALLERY_INIT_YEARS':
        case 'VIDEOS_GALLERY_INIT_YEARS':
            newState.allValues = {
                ...state[galleryType].allValues, years: payload
            };
            break;
        case 'PHOTOS_GALLERY_INIT_MONTHS':
        case 'VIDEOS_GALLERY_INIT_MONTHS':
            newState.allValues = {
                ...state[galleryType].allValues, months: payload
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        [galleryType]: {...newState}
    };
}