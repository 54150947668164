const INITIAL_STATE = {
    courses: [],
    course: null,
    selectedValues: {},
    noMoreResults: false,
    allValues: {},
    loadOffset: 0
};

export default (coursesStore = INITIAL_STATE, {type, payload}) => {
    let {selectedValues} = coursesStore;

    switch(type){
        case 'COURSES_LOAD':
            return {...coursesStore, courses: payload};

        case 'COURSE_LOAD':
            return {...coursesStore, course: payload};

        case 'COURSES_LOAD_MORE':
            return {
                ...coursesStore,
                loadOffset: coursesStore.courses.length + payload.length,
                noMoreResults: payload.length === 0,
                courses: [...coursesStore.courses, ...payload]
            };

        case 'COURSES_FILTER_SELECT':
            selectedValues[payload.key] = [...coursesStore.selectedValues[payload.key] || [], payload.value];
            return {
                ...coursesStore,
                loadOffset: 0,
                selectedValues: {...coursesStore.selectedValues, ...selectedValues}
            };

        case 'COURSES_FILTER_UNSELECT':
            const index = coursesStore.selectedValues[payload.key].indexOf(payload.value);
            selectedValues[payload.key] = [
                ...coursesStore.selectedValues[payload.key].slice(0, index),
                ...coursesStore.selectedValues[payload.key].slice(index + 1)
            ];
            return {
                ...coursesStore,
                loadOffset: 0,
                selectedValues: {...coursesStore.selectedValues, ...selectedValues}
            };

        case 'COURSES_FILTER_CLEAR':
            return {
                ...coursesStore,
                loadOffset: 0,
                selectedValues: {}
            };

        case 'COURSES_INIT_AGES':
            return {
                ...coursesStore,
                allValues: {...coursesStore.allValues, ages: payload},
            };
        case 'COURSES_INIT_HOURS':
            return {
                ...coursesStore,
                allValues: {...coursesStore.allValues, hours: payload},
            };
        case 'COURSES_INIT_DAYS':
            return {
                ...coursesStore,
                allValues: {...coursesStore.allValues, days: payload.data},
            };
        case 'COURSES_INIT_BUILDINGS':
            return {
                ...coursesStore,
                allValues: {...coursesStore.allValues, buildings: payload.data},
            };
        case 'COURSES_INIT_CATEGORIES':
            //rename category object fields
            const categories = payload.data.map(({courseCategoryId, name}) => ({id: courseCategoryId, value: name}));
            return {
                ...coursesStore,
                allValues: {...coursesStore.allValues, categories},
            };
        default:
            return coursesStore;
    }
}