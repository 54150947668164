import React from 'react';
import {connect} from "react-redux";

import {uiChangedAction} from "actions/uiStateActions";
import notFoundImage from 'images/404.jpg';
import SignUpForm from "components/account/SignUpForm";

const mapStateToProps = ({account}) => {
    return {
        authenticated: account.authenticated
    };
};

export default connect(mapStateToProps, {uiChangedAction})((props) => {

    const pageConfig = {
        title: 'Registrace'
    };

    props.uiChangedAction({
        title: pageConfig.title,
        color: '#009EE0',
        bgImage: notFoundImage
    });

    if (props.authenticated) {
        props.history.replace('/ucet');
    }

    return (
        <section className="not-found">
            <div className="list-body" id="list-body">
                <div className="" id="list-detail">
                    <article className={`bg-color11 list-detail text-center`}>
                        <h2 className="title">{pageConfig.title}</h2>
                        <SignUpForm />
                    </article>
                </div>
            </div>
        </section>
    );
})