/**
 * Mapping former page IDS to redirect urls in formats /stranka/:id or /stranka/:idParent/detail/:id to new defined structure
 */
export const RedirectFormerURLs = {
    11: '/zahrada',
    29: '/zahrada/provozni-doba',
    38: '/zahrada/areal-zahrady-ddm-jm-salounova',
    39: '/zahrada/skatepark',
    40: '/zahrada/viceucelove-hriste',

    12: '/ostatni',
    36: '/ostatni/zamestnani-hl-prac-pomer',
    14: '/ostatni/zamestnani-vedl-prac-pomer',
    13: '/ostatni/pronajmy',
    17: '/ostatni/pronajmy',
    18: '/ostatni/pronajmy',
    19: '/ostatni/pronajmy',

    20: '/kontakt/ddm-jm-salounova',
    21: '/kontakt/ddm-jm-kvetnoveho-vitezstvi',
    22: '/kontakt/fakturacni-udaje',
    23: '/kontakt/pokladna',

    24: '/o-nas/zakladni-informace',
    25: '/o-nas/dokumentace',
    26: '/o-nas/mluvi-o-nas',

};

const RoutesConfig = {

    production: [
        {path: '/krouzky', title: 'Kroužky'},
        {path: '/tabory', title: 'Tábory'},
        {path: '/akce', title: 'Akce'},
        {path: '/zahrada', title: 'Zahrada',
            subroutes: [
                {path: '/zahrada/provozni-doba', title: 'Provozní doba'},
                {path: '/zahrada/areal-zahrady-ddm-jm-salounova', title: 'Zahrada DDM JM, Šalounova'},
                {path: '/zahrada/skatepark', title: 'Skatepark'},
                {path: '/zahrada/viceucelove-hriste', title: 'Víceúčelové hřiště'}
            ]
        },
        {
            path: '#', title: 'Pro školy',
            subroutes: [
                {path: '/skoly/souteze', title: 'Soutěže'},
                {path: '/skoly/programy', title: 'Programy'}
            ]
        },
        {path: '/ostatni', title: 'Ostatní',
            subroutes: [
                {path: '/nabidka', title: 'Z nabídky'},
                {path: '/ze-zivota-ddm', title: 'Ze života DDM JM'},
                {path: '/ostatni/zamestnani', title: 'Zaměstnání', index: true},
                {path: '/ostatni/pronajmy', title: 'Pronájmy', index: true},
                {path: '/ostatni/projekty', title: 'Projekty', index: true},
            ]
        },
        {path: '/foto', title: 'Foto'},
        {path: '/video', title: 'Video'},
        {path: '/o-nas', title: 'O nás',
            subroutes: [
                {path: '/o-nas/zakladni-informace', title: 'Základní informace'},
                {path: '/o-nas/dokumentace', title: 'Dokumentace'},
                {path: '/o-nas/mluvi-o-nas', title: 'Mluví o nás'},
            ]
        },
        {
            path: '/kontakt', title: 'Kontakt',
            subroutes: [
                {path: '/kontakt/ddm-jm-salounova', title: 'DDM JM Šalounova'},
                {path: '/kontakt/ddm-jm-kvetnoveho-vitezstvi', title: 'DDM JM Květnového vítězství'},
                {path: '/kontakt/fakturacni-udaje', title: 'Fakturační údaje'},
                {path: '/kontakt/pakladna', title: 'Pokladna'},
            ]
        },
    ],

    development: [
        {path: '/krouzky', title: 'Kroužky'},
        {path: '/tabory', title: 'Tábory'},
        {path: '/akce', title: 'Akce'},
        {path: '/zahrada', title: 'Zahrada'},
        {path: '#', title: 'Pro školy',
            subroutes: [
                {path: '/skoly/souteze', title: 'Soutěže'},
                {path: '/skoly/programy', title: 'Programy'}
            ]
        },
        {path: '/ostatni', title: 'Ostatní',
            subroutes: [
                {path: '/nabidka', title: 'Z nabídky'},
                {path: '/ze-zivota-ddm', title: 'Ze života DDM JM'},
                {path: '/ostatni/zamestnani', title: 'Zaměstnání', index:true},
                {path: '/ostatni/projekty', title: 'Projekty', index: true}
            ]
        },
        {path: '/foto', title: 'Foto'},
        {path: '/video', title: 'Video'},
        {path: '/o-nas', title: 'O nás'},
        {
            path: '/kontakt', title: 'Kontakt',
            subroutes: [
                {path: '/kontakt/ddm-jm-salounova', title: 'DDM JM Šalounova'},
                {path: '/kontakt/gutenberg', title: 'Gutenberg'},
            ]
        },
    ]
};

export const flattenRoutesConfig = RoutesConfig[process.env.NODE_ENV].reduce((collected, item) => {
    collected.push(item);
    if (item.subroutes) {
        collected.push(...item.subroutes);
    }
    return collected;
}, []);

export default RoutesConfig[process.env.NODE_ENV];
