const INITIAL_STATE = {
    user: null,
    authenticated: false,
    settings: null,
    authError: null,
    passwordReseted: false,
    activationLinkResent: false,
    confirmationResult: null,
    setPasswordResult: null,
    signupResult: null,
    accountEditResult: null,
    participantEditResult: null,
    parentEditResult: null,
    newParticipation: null
};

//keep user persistent in localStorage
export default (store = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case 'AUTH_SIGNIN':
            if (payload !== null) {
                return {...store, user: payload, authenticated: true};
            }
            //todo tohle by nemělo nastávat, takže to chceme logovat
            return store;
        case 'AUTH_ACCOUNT_EDIT':
            return {...store, user: payload, accountEditResult: true};
        case 'AUTH_PARTICIPANT_EDIT':
            return {...store, user: payload, participantEditResult: true};
            case 'AUTH_PARENT_EDIT':
            return {...store, user: payload, parentEditResult: true};
        case 'AUTH_ERROR':
            //something went wrong - reset account store by setting default values
            return {...store, authenticated: false, user: null, authError: payload};
        case 'CLEAR_AUTH_ERRORS':
            return {...store, authError: null};
        case 'AUTH_SIGNOUT':
            return {...INITIAL_STATE, settings: store.settings};
        case 'AUTH_PASSWORD_RESET':
            return {...store, authError: null, passwordReseted: true};
        case 'AUTH_RESEND_ACTIVATION':
            return {...store, authError: null, activationLinkResent: true};
        case 'AUTH_CONFIRMED':
            return {...store, confirmationResult: payload};
        case 'AUTH_PASSWORD_SET':
            return {...store, setPasswordResult: payload};
        case 'AUTH_SIGNUP':
            return {...store, signupResult: payload};

        case 'COURSES_SUBSCRIBED':
            //add newly created participation to stored user
            store.user.participants.forEach((participant) => {
                if (participant.participantId === payload.participantId) {
                    participant.courseParticipations.push(payload.participation);
                }
            });
            return {
                ...store, newParticipation: payload.participation
            };
        case 'COURSES_UNSUBSCRIBED':
            return {...store, user: payload};
        case 'COURSES_SUBSCRIPTION_CLEAR':
            return {...store, newParticipation: null};

        case 'COURSE_UPLOADED':
            // store.user
            store.user.participants.forEach( item => {
               item.courseParticipations.forEach( cp => {
                   if(cp.courseParticipationId === payload) {
                       cp.applicationReturned = new Date().getTime();
                   }
               })
            });
            return {...store};
        case 'LOAD_SETTINGS':
            return {...store, settings: payload};
        default:
            return store;
    }
}