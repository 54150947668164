import Filter from 'components/lists/Filter';
import {connect} from "react-redux";

import {filterOpenAction} from "actions/uiStateActions";
import {initAges,initMonths, initOpen, filterSelectItem, filterUnselectItem, filterClear} from "actions/eventsActions";
import filterByCategory from "helpers/filterByCategory";

class EventFilter extends Filter {

    config = [
        {keyName: 'ages', title: 'Věk'},
        {keyName: 'months', title: 'Měsíc'},
        {keyName: 'open', title: 'Určeno'}
    ];

    //todo neslo by to nejak extrahovat do predka?
    constructor(props) {
        super(props);
        //todo pokud by tohle mohlo být jinde, můžu se zbavit classBase componenty
        this.props.initAges();
        this.props.initMonths();
        this.props.initOpen();
    }

    componentDidUpdate() {
        filterByCategory('akce', 'months', this.props.allValues, this.props.selectedValues, this.props.filterSelectItem);
    }

}

const mapStateToProps = ({eventsStore}) => {
    return {
        allValues: eventsStore.allValues,
        selectedValues: eventsStore.selectedValues
    }
};

export default connect(mapStateToProps,
    {filterOpenAction, initAges, initMonths, initOpen, filterSelectItem, filterUnselectItem,
        filterClear})(EventFilter);